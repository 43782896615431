import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { InputText, SecondaryButton, DatePicker, SingleSelect } from '../../../../../Core/Controls';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { ContractSetArrived, ContractsStepper_DropDown } from '../../../../../Core/Services/InternalUser/InternalDashService';

import moment from 'moment';

const ContractSetAwaitingFactoryInspection = (props) => {
    const [state, setState] = useState({
        errors: {},
        contractReturnDate: moment().format('YYYY-MM-DD'),
        contractDeliveryCharge: null,
        transportCompanyID: null
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const { showToastSuccess, showToastError } = useToast();

    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await ContractsStepper_DropDown();
                let selectedTransportCompany = null;

                if (props.contractCollectionTransportCompanyID) {
                    selectedTransportCompany = res.data.companies.find((q) => q.id === props.contractCollectionTransportCompanyID);
                }

                setState((st) => ({
                    ...st,
                    companies: res.data.companies || [],
                    transportCompanyID: selectedTransportCompany
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [props.contractDeliveryTransportCompanyID]);

    const onFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const validations = () => {
        const { contractReturnDate, transportCompanyID } = state;
        let formIsValid = true;
        let errors = {};
        if (!contractReturnDate) {
            errors.contractReturnDate = 'Date is required';
            formIsValid = false;
        }
        if (!transportCompanyID?.id) {
            errors.transportCompanyID = 'Company is required';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const SubmitHandler = async () => {
        console.log(state);
        if (validations()) {
            setBtnLoader(true);
            const totalVehicles = props.checkedVehicleList.length;
            let failedResponses = 0;

            try {
                for (let index = 0; index < totalVehicles; index++) {
                    try {
                        const response = await ContractSetArrived({
                            contractVehicleID: props.checkedVehicleList[index],
                            contractReturnDate: state.contractReturnDate,
                            transportCompanyID: state.transportCompanyID?.id
                        });

                        // Display success or failure for each vehicle
                        showToastSuccess(`Vehicle ${index + 1} of ${totalVehicles}: ${response.success ? 'Success' : 'Failed'}`);

                        if (!response.success) {
                            failedResponses += 1;
                        }
                    } catch (error) {
                        // Handle error for each individual vehicle
                        showToastError(`An error occurred for vehicle ${index + 1}: ${error.message}`);
                        failedResponses += 1;
                    }
                }

                // Final success or failure message after all vehicles
                if (failedResponses === 0) {
                    showToastSuccess('Vehicle delivery details saved successfully.');
                    props.onClose(true);
                } else {
                    showToastError(`${failedResponses} vehicle(s) failed.`);
                }
            } catch (error) {
                // Catch any unforeseen errors
                showToastError(`An unexpected error occurred: ${error.message}`);
            } finally {
                // Ensure the button loader is stopped
                setBtnLoader(false);
            }
        }
    };

    // const SubmitHandler = async () => {
    //     console.log(state);
    //     if (validations()) {
    //         setBtnLoader(true);
    //         let promises = props.checkedVehicleList.map((q) => {
    //             return ContractSetArrived({
    //                 contractVehicleID: q,
    //                 contractReturnDate: state.contractReturnDate,
    //                 transportCompanyID: state.transportCompanyID?.id
    //             });
    //         });

    //         try {
    //             let responses = await Promise.all(promises);

    //             // Check if all responses are successful
    //             let allSuccessful = responses.every((response) => response.success);

    //             if (allSuccessful) {
    //                 showToastSuccess('Vehicle delivery details saved successfully.');
    //                 props.onClose(true);
    //             } else {
    //                 // Find out which responses failed
    //                 let failedResponses = responses.filter((response) => !response.success);
    //                 showToastError(`Error: ${failedResponses.length} items failed.`);
    //             }
    //         } catch (error) {
    //             // Handle any other errors that may occur
    //             showToastError(`An error occurred: ${error.message}`);
    //         }
    //         setBtnLoader(false);
    //     }
    // };

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => ({ ...st, [nm]: val }));
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel shrink>Arrived Date</InputLabel>
                <DatePicker name="contractReturnDate" placeholder="Arrived Date" value={state.contractReturnDate || ''} onChange={onFieldChange} />
                <FormHelperText error>{state.errors.contractReturnDate}</FormHelperText>
            </Grid>
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel shrink>Transport Company</InputLabel>
                <SingleSelect options={state.companies} value={state.transportCompanyID} onChange={singleSelectChange('transportCompanyID')} />
                <FormHelperText error>{state.errors.transportCompanyID}</FormHelperText>
            </Grid>

            <Grid item xs={12}>
                <SecondaryButton fullWidth isBtnLoader={btnLoader} className="submit_btn" onClick={SubmitHandler}>
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default ContractSetAwaitingFactoryInspection;
