import React, { useState, useMemo, useEffect } from 'react';
import { EditButton, BreadCrumbs, formatters, SingleSelect } from '../../../Core/Controls';
import AddUpdatePart from './PartAddUpdate';
import { useToast } from '../../../Core/Hooks/useToast';
import DialogComp from '../../../Core//Dialog/DialogComp';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import { getWareHouse_DropDowns, postParts } from '../../../Core/Services/Admin/partService';
import { CustomIconButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
import GrainIcon from '@material-ui/icons/Grain';
import GetWarehouseParts from './getWarehouseParts';
import MailIcon from '@material-ui/icons/Mail';
import OrderParts from './orderParts';
import { Button, ButtonGroup, FormHelperText, Grid } from '@material-ui/core';
import { useLoginInfo } from '../../../Core/Hooks/useLoginInfo';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Parts', active: true }
];

const basecolumns = [
    { key: 'action', name: 'Action', width: '7%' },
    { key: 'partNumber', name: 'Part Number', width: '10%' },
    {
        key: 'description',
        name: 'Description',
        width: '20%'
    },
    {
        key: 'qty',
        name: 'Van Stock',
        width: '6%'
    },
    {
        key: 'sqty',
        name: 'Service Area Stock',
        width: '12%'
    },
    {
        key: 'sellingPrice',
        name: 'Retail ',
        width: '7%',
        formatter: formatters.NumberWithPound
    },
    {
        key: 'standardCost',
        name: 'Cost ',
        width: '6%',
        formatter: formatters.NumberWithPound
    },
    {
        key: 'partType',
        name: 'Part Type',
        width: '10%'
    },
    {
        key: 'minimumStockLevel',
        name: 'Minimum Level',
        width: '12%'
    },
    {
        key: 'targetStockLevel',
        name: 'Target Level',
        width: '12%'
    }
    // {
    //     key: 'totalStockQuantity',
    //     name: 'Total Stock Quantity',
    //     width: '9%'
    // },

    // {
    //     key: 'sellingPrice',
    //     name: 'Selling Price',
    //     width: '7%'
    // },

    // {
    //     key: 'sellingPrice',
    //     name: 'Retail Price',
    //     width: '7%'
    // },
    // {
    //     key: 'unitOfPricePer',
    //     name: 'Unit Of PricePer',
    //     width: '9%'
    // },
    // {
    //     key: 'standardCost',
    //     name: 'Standard Cost',
    //     width: '6%'
    // },

    // {
    //     key: 'isActive',
    //     name: 'Active?',
    //     width: '5%'
    // }
];
const Parts = () => {
    const { userRoles } = useLoginInfo();
    const isEngineer = userRoles.includes('engineer');
    const isCustomer = userRoles.includes('customer');
    const [state, setState] = useState({
        open: false,
        partID: null,
        showWarehouseStock: false,
        data: null,
        showOrderPartMailModal: null,
        inStock: false,
        wareHouseList: []
    });
    const [tempOptions, setTempOptions] = useState({
        wareHouseID: null,
        contractSummerServiceAreaID: null
    });

    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        let res = await getWareHouse_DropDowns();
        if (res.success) {
            setState((state) => ({
                ...state,
                wareHouseList: res.data
            }));
        }
    }, []);

    const pullApiData = async () => {
        setState((state) => ({
            ...state,
            isReload: new Date()
        }));
    };

    const renderEdit = (ev, row) => {
        return (
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                <EditButton
                    toolTipTitle="Edit Part"
                    onClick={() => {
                        setState((st) => ({
                            ...st,
                            open: true,
                            title: `Part Details - ${row.partNumber}`,
                            partID: row.partID,
                            isAdd: false
                        }));
                    }}
                />
                <CustomIconButton
                    icon={GrainIcon}
                    className="modal-btn-update btn_space"
                    toolTipTitle="Warehouse parts"
                    onClick={() => {
                        setState((st) => ({
                            ...st,
                            showWarehouseStock: true,
                            partID: row.partID
                        }));
                    }}
                />
                {/* <CustomIconButton
                    icon={MailIcon}
                    className="modal-btn-update btn_space"
                    toolTipTitle="Order Part"
                    onClick={() => {
                        setState((st) => ({
                            ...st,
                            showOrderPartMailModal: true,
                            data: row
                        }));
                    }}
                /> */}
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        let consumableCol = tempCols.find((e) => e.key === 'consumable');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        return tempCols;
    }, []);

    const saveData = async (outData) => {
        let response = await postParts({
            partID: outData.partID || null,
            partNumber: outData?.partNumber,
            description: outData?.description,
            minimumUnitOfStock: outData?.minimumUnitOfStock,
            totalStockQuantity: outData?.totalStockQuantity,
            sellingPrice: outData?.sellingPrice,
            unitOfPricePer: outData?.unitOfPricePer,
            standardCost: outData?.standardCost,
            isActive: outData?.isActive
        });
        if (response) {
            response.success ? showToastSuccess(response.message) : showToastError(response.message || 'Something went wrong');
        } else {
            showToastError('Something went wrong');
        }
        pullApiData();
    };
    const addBtnClicked = () => {
        setState((st) => ({
            ...st,
            open: true,
            // title: 'Add Part',
            isAdd: true,
            partID: null
        }));
    };
    const handleClose = (res) => {
        setState((st) => ({
            ...st,
            open: res ? true : false,
            showWarehouseStock: false,
            showOrderPartMailModal: false
        }));
        res && pullApiData();
    };

    const urlState = `AdminParts/Parts_List?inStock=${state.inStock}&Warehouse=${tempOptions.wareHouseID?.id || ''}`;

    const handleFilter = (res) => {
        setState((st) => ({
            ...st,
            inStock: res
        }));
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setTempOptions((st) => ({
            ...st,
            [nm]: val
        }));
    };

    return (
        <div className="screen">
            <Grid container justify="space-between">
                <Grid item>
                    <BreadCrumbs crumbs={crumbs} className="edit-Customer-btn" />
                </Grid>

                <Grid item xs={12} sm={7} md={7} lg={5} xl={4}>
                    <Grid container spacing={2} justify="flex-end" alignItems="center">
                        {!isEngineer && (
                            <>
                                <Grid item>
                                    <FormHelperText>Warehouse</FormHelperText>
                                </Grid>
                                <Grid item xs>
                                    <SingleSelect
                                        name="wareHouseID"
                                        options={state.wareHouseList}
                                        value={tempOptions.wareHouseID}
                                        onChange={singleSelectChange('wareHouseID')}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid component="label" item alignItems="center">
                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                <Button
                                    style={{ fontSize: 12 }}
                                    variant={state.inStock === false ? 'contained' : 'outlined'}
                                    onClick={() => handleFilter(false)}
                                >
                                    All
                                </Button>
                                <Button style={{ fontSize: 12 }} variant={state.inStock === true ? 'contained' : 'outlined'} onClick={() => handleFilter(true)}>
                                    Stock
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <GijgoGrid
                columns={columns}
                getUrl={urlState}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                downloadBtnName={isEngineer || isCustomer ? null : 'Parts xlsx'}
                // btnTitle="Add Part"
                // onBtnClick={() => addBtnClicked(null)}
            />
            {state.open ? (
                <DialogComp open={state.open} onClose={() => handleClose(false)} title={state.title} maxWidth="md" fullWidth fullScreen overflow="auto">
                    <AddUpdatePart partID={state.partID} onClose={handleClose} saveData={saveData} />
                </DialogComp>
            ) : null}
            {state.showWarehouseStock ? (
                <DialogComp open={state.showWarehouseStock} onClose={() => handleClose(false)} title={'Available Parts'} maxWidth="lg">
                    <GetWarehouseParts selectedWarehouse={tempOptions.wareHouseID} inStock={state.inStock} partID={state.partID} onClose={handleClose} />
                </DialogComp>
            ) : null}
            {state.showOrderPartMailModal ? (
                <DialogComp open={state.showOrderPartMailModal} onClose={() => handleClose(false)} title={'Parts Request'} maxWidth="sm">
                    <OrderParts data={state.data} onClose={handleClose} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default Parts;
