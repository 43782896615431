import React, { useEffect, useState, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import {
    InputLabel,
    FormHelperText,
    TableBody,
    TableRow,
    Paper,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    withStyles,
    CircularProgress,
    Checkbox
} from '@material-ui/core';
import { SecondaryButton, DeleteButton, formatters, MandatoryField, SingleSelect, SelectBox, CustomChip } from '../../../../../../Core/Controls';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import '../../../../../commonStyle.scss';
import DataGrid from '../../../../../../Core/Controls/Grid';
import {
    getJobItemById,
    getJobItemsDropDown,
    getVehicleJobParts_GetDetails,
    PostInternalVehicleJobsItem
} from '../../../../../../Core/Services/InternalUser/JOB/jobItemsService';
import SearchIcon from '@material-ui/icons/Search';
import { CustomIconButton, EditButton } from '../../../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import PastJobs from './../../JOBItems/getPastJobBy';
import DialogComp from '../../../../../../Core/Dialog/DialogComp';
import ConfirmationModal from '../../../../../../Core/Modal/ConfirmationModal';
import { AppStorage } from '../../../../../../Core/Basic/storage-service';
import _, { toNumber } from 'lodash';
import MailIcon from '@material-ui/icons/Mail';
import OrderParts from '../../../../../Admin/Parts/orderParts';
import { MatchingPartList } from './JobItemStandardParts';
import AddIcon from '@material-ui/icons/Add';
import { JobItemPurchaseParts } from './jobItemPurchesparts';
import { JobStatusHandler } from './jobStatushandle';
import InputTextWithMemo from '../../../../../../Core/Controls/Inputs';
import { Edit } from '@material-ui/icons';
import moment from 'moment';
import { useLoginInfo } from '../../../../../../Core/Hooks/useLoginInfo';
import GetResizedImage from '../../../../../../Core/Controls/ImageResizer';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#f5f5f5',
        padding: '5px 8px'
    },
    body: {
        padding: '5px 8px',
        fontSize: 14
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const base_columns = (isEngineer) => {
    return [
        {
            key: 'quantity',
            name: 'Quantity',
            width: '10%'
        },
        {
            key: 'partNumber',
            name: 'Part',
            width: '48%'
        },
        {
            key: 'statusDesc',
            name: 'Part Status',
            width: '20%'
        },
        {
            key: 'warehouseDesc',
            name: 'Warehouse',
            width: '10%'
        },

        {
            key: 'getCustTotal',
            name: isEngineer ? 'Selling Price' : 'Cost',
            align: 'right',
            width: '10%'
        },
        // {
        //     key: 'getTotal',
        //     name: 'Non Chargeable',
        //     align: 'right',
        //     width: '10%'
        // },
        {
            key: '',
            name: '',
            width: 20
        }
    ];
};
const getTwoFixed = (val) => {
    return val ? val?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0;
};

function AddJobItemModal(props) {
    const { userRoles } = useLoginInfo();
    const isEngineer = userRoles.includes('engineer');
    const isCustomer = userRoles.includes('customer');
    const [state, setState] = useState({
        setBackup: [],
        jobItemID: null,
        labourDescription: '',
        jobItemStatusID: null,
        JobItemCostTypeID: null,
        jobItemStatus: [],
        labourHours: null,
        labourRate: '',
        labourDiscount: 0,
        workCategory: '',
        costCategory: '',
        partNumber: '',
        partQuantity: null,
        partUnitPrice: null,
        vehicleJobPartCustomerUnitPrice: null,
        partDescription: '',
        partsDiscount: '',
        partsTotal: '',
        workList: [],
        costCategories: [],
        rows: [],
        errors: {},
        readOnly: false,
        showPastJob: false,
        costCategoryValue: null,
        jobItemStatusIDValue: null,
        jobItemChargeCustomer: false,
        jobItemCostTypeIDValue: null,
        workCategoryValue: null,
        showCancelConfirmModal: false,
        jobItemCustomerLabourTotal: 0,
        jobItemCustomerPartsPrice: 0,
        loading: false,
        focus: false,
        warehouse: [],
        partStatus: [],
        vehicleJobPartStatus: '',
        warehouseCode: props.masterWarehouseCode || '',
        warehouseDesc: '',
        partStatusDesc: '',
        engineer: [],
        jobEngineerUserID: null,
        isWarehouseDisabled: false,
        labourRateTotal: 0,
        jobItemEngineerUserId: props.enggID,
        formLine: false,
        recharge: false,
        showStandardPart: false,
        showPurchase: false,
        showLoader: true,
        isChargeableDisabled: true,
        vehicleJobPartPaymentType: '',
        batteryTestPrintoutImage: '',
        vehicleJobPartDatePurchased: '',
        isStatusDisabled: false,
        selectedFileExt: '',
        isJobOffHire: false,
        isValidationBypass: false,
        tempPartStatus: []
    });

    let isAvoidValidation = ['AP', 'ES', 'EA', 'JP', 'WP', 'TA', 'AA'].includes(props.JobStatus);

    const { showToastSuccess, showToastError } = useToast();

    let checkhaveAccess = AppStorage.getAllowAcces();

    const [btnLoader, setBtnLoader] = useState(false);

    const handleRowSelect = (params) => {
        let newPartToAdd = {
            partNumber: params.pn,
            quantity: params.quantity,
            unitPrice: params.cp,
            customerUnitPrice: state.recharge ? params.sp : 0,
            part: params.pd,
            sellingPrice: params.sp,
            standardCost: params.cp,
            isDeleted: false,
            status: params?.vehicleJobPartStatus || '',
            vehicleJobPartType: 'S',
            warehouseCode: params?.vehicleJobPartStatus === 'Z' ? '' : params.warehouseCode || props.masterWarehouseCode,
            warehouseDesc: params?.vehicleJobPartStatus === 'Z' ? '' : params.warehouseDescription || '',
            statusDesc: params.PartStatusDescription || '',
            partsTotal: getTwoFixed((+params.sp * +params.quantity * (100 - toNumber(params?.discount || 0) || 0)) / 100),
            vehicleJobPartCostPrice: params.cp,
            partDepotID: params.partDepotID,
            isUpDate: true
            // gridpartStatus: []
        };

        setState((st) => ({
            ...st,
            rows: state.rows.filter((item) => item.id !== state.partId)
        }));

        setState((st) => {
            newPartToAdd.id = st.rows.length === 0 ? 1 : Math.max(...st.rows.map((c) => c.id)) + 1;
            return {
                ...st,
                rows: [...st.rows, newPartToAdd]
            };
        });

        // closeParts();
    };

    const handleDeletePart = (params, index) => {
        let tempArray = [...state.rows];
        tempArray[index].isDeleted = true;
        setState((st) => {
            const nst = {
                ...st,
                rows: tempArray
            };
            return nst;
        });
    };

    let checkStatus = props.checkStatus;

    useEffect(() => {
        setState((st) => ({
            ...st,
            isStatusDisabled:
                state.jobItemStatusID === 'D'
                    ? false
                    : state.readOnly && state.jobItemStatusID === 'ON'
                    ? true
                    : // : false
                    props.isCopied && state.jobItemStatusID === 'C'
                    ? true
                    : props.isCopied
                    ? false
                    : state.readOnly
                    ? true
                    : false
        }));
    }, [state.jobItemStatusID, state.readOnly, props.isCopied]);

    useEffect(() => {
        let FilterList = [...state.tempPartStatus];
        if (state.jobItemStatusID === 'ON') {
            let tempArray = [...state.rows].map((k) => ({
                ...k,
                statusOFDesc: ''
            }));

            const workCategoryValue = state.workList.find(({ id }) => id === '12,81');
            setState((st) => ({
                ...st,
                labourHours: 0,
                workCategoryValue: workCategoryValue,
                workCategory: '12,81',
                jobItemCostTypeIDValue: null,
                jobItemCostTypeID: null,
                jobItemEngineerUserId: null,
                isOnHireReadonly: true,
                isValidationBypass: true,
                jobItemVMRSCategoryID: 12,
                jobItemVMRSSubCategoryID: 81,
                statusOFDesc: '',
                rows: tempArray,
                gridpartStatus: FilterList
            }));
        } else if (state.jobItemStatusID === 'OF') {
            let tempArray = [...state.rows].map((k) => ({
                ...k,
                tempstatus: 'Z',
                statusDesc: 'Offhire Costing',
                statusOFDesc: 'Offhire Costing',
                warehouseCode: null,
                warehouseDesc: null,
                isWarehouseDropdown: false
            }));

            setState((st) => ({
                ...st,
                isOnHireReadonly: false,
                isValidationBypass: false,
                rows: tempArray,
                gridpartStatus: [
                    {
                        id: 'Z',
                        name: 'Offhire Costing'
                    }
                ]
            }));
        } else {
            let tempArray = [...state.rows].map((k) => ({
                ...k,
                statusOFDesc: ''
            }));
            setState((st) => ({
                ...st,
                isOnHireReadonly: false,
                isValidationBypass: false,
                statusOFDesc: '',
                rows: tempArray,
                gridpartStatus: FilterList
            }));
        }
    }, [state.jobItemStatusID, state.isOnHire, state.workList, state.tempPartStatus]);

    const handlePartStatus = () => {
        if (validations()) {
            setState((st) => ({
                ...st,
                showPartsStatus: true
            }));
        }
    };

    const CalculateNonChargeableTotal = (data) => {
        const { quantity, standardCost } = data;
        let nonChargeablePrice = standardCost || 0;
        const nonChargeable = nonChargeablePrice * quantity; // nonChargable Total
        return <div style={{ textAlign: 'right', width: '100%' }}>£{getTwoFixed(nonChargeable)}</div>;
    };

    const CalculateCutomerTotal = (data) => {
        const { quantity, sellingPrice } = data;
        let CustomerPrice = sellingPrice || 0;
        let customerTotal = CustomerPrice * quantity;
        return <div style={{ textAlign: 'right', width: '100%' }}>£{getTwoFixed(customerTotal)}</div>;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                let val = props.labourRate * (1 + props.markup / 100);
                setState((st) => ({
                    ...st,
                    islabourRateSet: props.islabourRateSet,
                    labourRate: props.labourRate,
                    markup: props.markup,
                    jobItemCustomerLabHourRate: props.markup ? getTwoFixed(val) : props.labourRate
                }));

                // Fetch dropdown data
                let res1 = await getJobItemsDropDown(props.jobItemJobID, props.jobItemID);
                if (res1.success) {
                    const category = res1.data.vmrsCategory.map((item) => ({
                        id: item.catid + ',' + item.subcatid,
                        name: item.subcatname,
                        ...item
                    }));

                    setState((st) => ({
                        ...st,
                        workList: category,
                        costCategories: res1.data.costCategories,
                        jobItemStatus: res1.data.jobItemStatus,
                        costTypeList: res1.data.costType,
                        warehouse: res1.data?.warehouse || [],
                        partStatus: res1.data?.partStatus?.filter((k) => k.id !== 'P' && k.id !== 'T' && k.id !== 'M') || [],
                        gridpartStatus: res1.data?.partStatus || [],
                        tempPartStatus: res1.data?.partStatus || [],
                        engineer: res1.data?.engineer || [],
                        depots: res1.data?.depots || [],
                        showLoader: false
                    }));
                }
                // Fetch job item by ID if provided
                if (props.jobItemID) {
                    let res = await getJobItemById(props.jobItemID);
                    if (res.success) {
                        let partList = res.data.vehicleJobPartsDetails.map((item, index) => ({
                            ...item,
                            id: index + 1,
                            isDeleted: false
                        }));

                        const info = res.data.vehicleJobitemdetails[0];
                        let newSt = {
                            jobItemID: info.jobItemID,
                            labourDescription: info.jobItemDescription,
                            jobItemJobID: info.jobItemJobID,
                            jobItemStatusID: info.jobItemStatusID,
                            // isHideSubmitForCopied: props.isCopied ? true : false,
                            isHideSubmitForCopied: props.isCopied ? (info.jobItemStatusID !== 'C' && props.isCopied ? true : false) : false,
                            isOnHire: info.jobItemStatusID === 'ON',
                            jobItemCostTypeID: info.jobItemCostTypeID,
                            workCategory: info.jobItemVMRSCategoryID + ',' + info.jobItemVMRSSubCategoryID,
                            jobItemVMRSCategoryID: info.jobItemVMRSCategoryID,
                            jobItemVMRSSubCategoryID: info.jobItemVMRSSubCategoryID,
                            costCategory: info.jobItemCostCategoryID,
                            rows: partList.map((k) => ({
                                ...k,
                                isDisabled: (k.status === 'I' || k.status === 'P') && k.vehicleJobPartID,
                                vehicleJobPartCostPrice: k.standardCost,

                                // unitPrice: k.sellingPrice,
                                isWarehouseDropdown: k.warehouseDesc ? false : info.jobItemStatusID !== 'OF' || (!k.warehouseCode && k.status !== 'P'),
                                isQuentityDisabled: !((k.status == '' || k.status == 'M' || k.status == 'Z') && k.vehicleJobPartID),
                                isStatusUpdable: k.vehicleJobPartID && !k.status

                                // k.vehicleJobPartID ? true : false

                                // customerUnitPrice.info.recharge?
                            })),
                            readOnly: info.jobItemStatusID === 'D' ? true : info.allowUpdate === 'Y' ? false : true,
                            allowUpdate: info.allowUpdate,
                            labourHours: info.jobItemLabourHours !== null ? info.jobItemLabourHours.toString() : null,
                            labourRate: info.jobItemLabHourRate,
                            jobItemCustomerLabHourRate: info.jobItemCustomerLabHourRate,
                            jobItemCustomerLabourTotal: getTwoFixed(info.jobItemCustomerLabourCost),
                            labourDiscount: info.jobItemLabourDiscount,
                            jobItemEngineerUserId: info.jobItemEngineerUserId,
                            recharge: info.recharge || false,
                            isJobOffHire: info.jobItemStatusID === 'OF' || info.jobItemStatusID === 'AO',
                            // jobItemChargeCustomer: info.recharge || false,
                            showLoader: false
                        };

                        setState((state) => ({
                            ...state,
                            ...newSt
                        }));
                    } else {
                        console.error('Failed to fetch job item details by ID');
                    }
                }
            } catch (error) {
                console.error('An error occurred:', error);
            }
        };

        fetchData();
    }, [props.labourRate, props.markup, props.islabourRateSet, props.jobItemID]); // Add dependencies if necessary

    useEffect(() => {
        let newSt = {};

        if (state.workCategory && state.workList.length > 0) {
            const workCategoryValue = state.workList.find(({ id }) => id === state.workCategory);
            if (workCategoryValue) newSt.workCategoryValue = workCategoryValue;
        }

        // if (state.costCategory && state.costCategories.length > 0) {
        //     const costCategoryValue = state.costCategories.find(({ id }) => id === state.costCategory);
        //     if (costCategoryValue) newSt.costCategoryValue = costCategoryValue;
        // }

        // if (state.jobItemStatusID && state.jobItemStatus.length > 0) {
        //     const jobItemStatusIDValue = state.jobItemStatus.find(({ id }) => id === state.jobItemStatusID);
        //     if (jobItemStatusIDValue) newSt.jobItemStatusIDValue = jobItemStatusIDValue;
        // }

        // if (state.jobItemCostTypeID && state.costTypeList.length > 0) {
        //     const jobItemCostTypeIDValue = state.costTypeList.find(({ id }) => id === state.jobItemCostTypeID);
        //     if (jobItemCostTypeIDValue) {
        //         newSt.jobItemCostTypeIDValue = jobItemCostTypeIDValue;
        //     }
        // }

        // if (state.jobItemEngineerUserId && state.engineer.length > 0) {
        //     const jobEngineerUserID = state.engineer.find(({ id }) => id === state.jobItemEngineerUserId);
        //     if (jobEngineerUserID) newSt.jobEngineerUserID = jobEngineerUserID;
        // }

        // Only update state if there are changes
        if (Object.keys(newSt).length > 0) {
            setState((st) => ({
                ...st,
                ...newSt
            }));
        }
    }, [
        state.workCategory,
        state.workList,
        // state.costCategory,
        // state.costCategories,
        // state.jobItemStatusID,
        state.jobItemStatus,
        state.costTypeList,
        state.jobItemCostTypeID,
        state.jobItemEngineerUserId,
        state.engineer
    ]);

    //calculate labour total and recharge
    useEffect(() => {
        let costObj = state.costTypeList?.find((k) => k.id === state.jobItemCostTypeID);
        setState((st) => ({
            ...st,

            recharge: costObj?.recharge,
            labourRateTotal: costObj?.recharge
                ? state.workCategoryValue?.labourRate
                    ? (state.workCategoryValue.labourRate || 0) * (state.workCategoryValue?.fixedCost ? 1 : state.labourHours || 0)
                    : 0
                : state.workCategoryValue?.labourInternalRate
                ? (state.workCategoryValue.labourInternalRate || 0) * (state.labourHours || 0)
                : 0
        }));
        console.log(costObj, 'costObj');
    }, [state.workCategoryValue, state.jobItemCostTypeID, state.labourHours]);

    //Table iNput handler
    const handleGridEvents = (data, index) => (e) => {
        const { name, value } = e.target;
        try {
            let updateState = [...state.rows];
            if (name === 'status') {
                let statusDesc = state.gridpartStatus.find((k) => k.id === value)?.name || '';
                updateState[index].statusDesc = statusDesc;
            } else if (name === 'tempStatus') {
                let statusDesc = state.gridpartStatus.find((k) => k.id === value)?.name || '';
                updateState[index].statusDesc = statusDesc;
            } else if (name === 'tempStatus' && value == 'Z') {
                updateState[index].warehouseCode = '';
            }
            if (name === 'warehouseCode') {
                updateState[index].warehouseDesc = state.warehouse.find((k) => k.id === value).name;
            }
            updateState[index][name] = value;
            // updateState[index].status = value;
            updateState[index].isUpDate = true;

            setState((st) => ({
                ...st,
                rows: updateState
            }));
        } catch (error) {
            console.error('An error occurred while updating part status:', error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        let newSt = {};
        newSt[name] = value;
        newSt.setBackup = name;
        if (type == 'checkbox') {
            if (name === 'jobItemChargeCustomer') {
                newSt.jobItemChargeCustomer = checked;
            }
        }
        if (name === 'vehicleJobPartStatus') {
            newSt.partStatusDesc = state.partStatus.find((m) => m.id === value)?.name;
            newSt.isWarehouseDisabled = value === 'B' ? true : value === 'P' ? true : false;
            newSt.warehouseCode = '';
        }
        if (name === 'warehouseCode') {
            newSt.warehouseDesc = state.warehouse.find((m) => m.id === value)?.name;
        }
        if (name === 'labourRate') {
            let val = value * (1 + state.markup / 100);
            newSt.jobItemCustomerLabHourRate = getTwoFixed(val);
            const custLabourTotal = (val * state.labourHours * (100 - state.labourDiscount || 0)) / 100;
            newSt.jobItemCustomerLabourTotal = getTwoFixed(custLabourTotal);
        }
        if (name === 'partUnitPrice') {
            let customerUnitPrice = value * (1 + state.markup / 100);
            newSt.vehicleJobPartCustomerUnitPrice = getTwoFixed(customerUnitPrice);
            const calCustTotal = (customerUnitPrice * state.partQuantity * (100 - state.partsDiscount || 0)) / 100;
            let customerPartsPriceTotal = getTwoFixed(calCustTotal);
            newSt.jobItemCustomerPartsPrice = customerPartsPriceTotal;
        }
        if (name === 'vehicleJobPartCustomerUnitPrice') {
            const calCustTotal = (value * state.partQuantity * (100 - state.partsDiscount || 0)) / 100;
            let customerPartsPriceTotal = getTwoFixed(calCustTotal);
            newSt.jobItemCustomerPartsPrice = customerPartsPriceTotal;
        }
        if (name === 'jobItemCustomerLabHourRate') {
            const custLabourTotal = (value * state.labourHours * (100 - state.labourDiscount || 0)) / 100;
            newSt.jobItemCustomerLabourTotal = getTwoFixed(custLabourTotal);
        }
        if (name === 'labourDiscount') {
            const custLabourTotal = (state.jobItemCustomerLabHourRate * state.labourHours * (100 - value || 0)) / 100;
            newSt.jobItemCustomerLabourTotal = getTwoFixed(custLabourTotal);
        }
        if (name === 'labourHours') {
            const custLabourTotal = (state.jobItemCustomerLabHourRate * value * (100 - state.labourDiscount || 0)) / 100;
            newSt.jobItemCustomerLabourTotal = getTwoFixed(custLabourTotal);
        }
        if (name === 'partsDiscount') {
            const calCustTotal = (state.vehicleJobPartCustomerUnitPrice * state.partQuantity * (100 - value || 0)) / 100;
            let customerPartsPriceTotal = getTwoFixed(calCustTotal);
            newSt.jobItemCustomerPartsPrice = customerPartsPriceTotal;
        }
        if (name === 'partQuantity') {
            const calCustTotal = (state.vehicleJobPartCustomerUnitPrice * value * (100 - state.partsDiscount || 0)) / 100;
            let customerPartsPriceTotal = getTwoFixed(calCustTotal);
            newSt.jobItemCustomerPartsPrice = customerPartsPriceTotal;
        }
        setState((state) => ({
            ...state,
            ...newSt
        }));
    };

    const WorkCategoryChange = (nm) => (e, val) => {
        if (nm === 'workCategoryValue') {
            const cat = val?.id?.split(',');
            if (cat?.length) {
                setState((state) => ({
                    ...state,
                    jobItemVMRSCategoryID: cat[0],
                    jobItemVMRSSubCategoryID: cat[1],
                    [nm]: val
                }));
            }
        } else if (nm === 'jobItemCostTypeIDValue') {
            setState((state) => ({
                ...state,
                // jobItemChargeCustomer: val?.recharge || false,
                // isChargeableDisabled: !val.allowUpdate ? true : false,
                [nm]: val
            }));
        } else {
            setState((state) => ({
                ...state,
                [nm]: val
            }));
        }
    };

    const validations = () => {
        const { labourDescription, workCategoryValue, labourRate, labourHours, jobItemCostTypeIDValue, jobEngineerUserID } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let tempRows = [...state.rows];
        if (!props.isCopied) {
            let errors = {};
            if (!emptyStrigPattern.test(labourDescription)) {
                errors.labourDescription = 'Description is required';
                formIsValid = false;
            }
            if (!isAvoidValidation && (!isEngineer || !isCustomer)) {
                if (!workCategoryValue) {
                    errors.workCategory = 'Work Category is required';
                    formIsValid = false;
                }
                if (!emptyStrigPattern.test(labourRate)) {
                    errors.labourRate = 'Rate is required';
                    formIsValid = false;
                }
                if (!state.jobItemStatusID) {
                    errors.jobItemStatusID = 'Status is required';
                    formIsValid = false;
                }
                if (!state.isValidationBypass && !state.jobItemEngineerUserId) {
                    errors.jobEngineerUserID = 'Engineer is required';
                    formIsValid = false;
                }
                if (!state.isValidationBypass && !state.jobItemCostTypeID) {
                    errors.jobItemCostTypeIDValue = 'Cost Type is required';
                    formIsValid = false;
                }

                if (labourHours === null) {
                    errors.labourHours = 'Hours is required';
                    formIsValid = false;
                }

                let checkConditionforsubmit =
                    tempRows.find((k) => {
                        let status = k.tempstatus ? k.tempstatus : k.status;
                        return status === 'Z';
                    }) || null;
                if (checkConditionforsubmit && (state.jobItemStatusID === 'C' || state.jobItemStatusID === 'A')) {
                    formIsValid = false;
                    showToastError(`Part status can't be offhire costing for ${state.jobItemStatusID === 'C' ? 'Work Complete' : 'Approved'}`);
                }

                let updatedRows = state.rows.map((r) => {
                    const tempstatus = r.tempstatus || r.status;
                    const warehouseCode = r.warehouseCode ? r.warehouseCode.trim() : '';
                    if (tempstatus !== 'Z' && warehouseCode === '' && r.vehicleJobPartType !== 'P') {
                        formIsValid = false;
                        errors.partsStatus = 'Warehouse is required';
                        return { ...r, errorwarehouse: 'Warehouse is required', isUpDate: true };
                    }
                    return { ...r, errorwarehouse: '' };
                });
                setState((state) => ({ ...state, rows: updatedRows }));
            }
            setState((state) => ({ ...state, errors: errors }));
        }

        return formIsValid;
    };
    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => ({ ...st, [nm]: val }));
    };

    const showParts = (res) => {
        setState((st) => ({
            ...st,
            [res]: true,
            retailPrice: ''
        }));
    };

    const showPartsEdit = (rowData) => {
        setState((st) => ({
            ...st,
            showPurchase: true,
            selectedPurchaedPart: rowData
        }));
    };

    const handleFilter = (val) => {
        setState((st) => ({
            ...st,
            vehicleJobPartPaymentType: val
        }));
    };

    let handleImage = (event) => {
        const file = event.target.files[0];
        if (file.type.includes('image')) {
            const reader = new FileReader();

            // Set up FileReader to display the image
            reader.onloadend = () => {
                setState((state) => ({
                    ...state,
                    vehicleJobPartImage: reader.result,
                    selectedFileExt: '.png'
                }));
            };

            reader.readAsDataURL(file);
        } else if (file.type.includes('pdf')) {
            const reader = new FileReader();

            reader.onload = () => {
                const base64String = reader.result.split(',')[1]; // Extract the Base64 portion
                setState((state) => ({
                    ...state,
                    vehicleJobPartImage: `data:application/pdf;base64,${base64String}`,
                    selectedFileExt: '.pdf'
                }));
            };

            reader.onerror = (error) => {
                console.error('Error reading the PDF file:', error);
                alert('Failed to process the PDF file.');
            };

            reader.readAsDataURL(file); // Reads the file and encodes it to Base64
        } else {
            alert('Wrong file selected');
        }
    };

    const handleStockMail = (res) => {
        setState((st) => ({
            ...st,
            sendEmail: res
        }));
    };

    const submitSendForApproval = async (val) => {
        if (validations()) {
            setBtnLoader(true);

            const PartsVM = state.rows.map((row) => ({
                vehicleJobPartNumber: row.partNumber,
                vehicleJobPartID: row.vehicleJobPartID || null,
                vehicleJobPartType: row.vehicleJobPartType || '',
                vehicleJobPartDescription: row.part,
                vehicleJobPartQuantity: row.quantity,
                vehicleJobPartDiscount: row.discount || 0,
                vehicleJobPartUnitPrice: row.unitPrice,
                vehicleJobPartCostPrice: row.standardCost || '',
                vehicleJobCustomerUnitPrice: row.customerUnitPrice,
                vehicleJobPartSellingPrice: row.sellingPrice,
                vehicleJobPartStatus: row.tempstatus || row.status || '',
                warehouseCode: row.warehouseCode,
                vehicleJobPartSupplier: row.vehicleJobPartSupplier || '',
                vehicleJobPartDatePurchased: row.vehicleJobPartDatePurchased || '',
                vehicleJobPartPaymentType: row.vehicleJobPartPaymentType || '',
                vehicleJobPartWIPNumber: row.vehicleJobPartWIPNumber || '',
                vehicleJobPartImage: row.vehicleJobPartImage || '',
                isDeleted: row.isDeleted || false
            }));

            const data = {
                jobItemDescription: state.labourDescription,
                jobItemLabourHours: state.labourHours,
                jobItemLabHourRate: state.labourRate,
                jobItemCustomerLabHourRate: state.jobItemCustomerLabHourRate,
                jobItemLabourDiscount: state.labourDiscount || 0,
                jobItemID: state.jobItemID,
                jobItemVMRSCategoryID: state.jobItemVMRSCategoryID,
                jobItemVMRSSubCategoryID: state.jobItemVMRSSubCategoryID,
                jobItemJobID: props.jobItemJobID,
                jobItemCostCategoryID: state.costCategoryValue?.id,
                vehicleJobPartsVM: PartsVM,
                jobItemStatusID: state.jobItemStatusID,
                // jobItemCostTypeID: state.jobItemCostTypeIDValue?.id || '',
                jobItemCostTypeID: state.jobItemCostTypeID || '',
                sendForApproval: 0,
                jobItemEngineerUserId: state?.jobItemEngineerUserId,
                // jobItemEngineerUserId: state?.jobEngineerUserID?.id || '',
                depotID: state.depotID,
                sendEmail: state.sendEmail
                // jobItemChargeCustomer: state.jobItemChargeCustomer
            };

            // console.log(data, 'ressssssssssssss');

            let res = await PostInternalVehicleJobsItem(data);
            if (res.success) {
                showToastSuccess('Job item saved successfully');
                setBtnLoader(false);
                props.onClose(true);
            } else {
                showToastError(res.message);
                setBtnLoader(false);
            }
            setBtnLoader(false);
        }
    };

    const closeTheDialog = () => {
        setState((st) => ({ ...st, showPastJob: false }));
    };

    const closePurchaseParts = () => {
        setState((st) => ({
            ...st,
            showPurchase: false,
            selectedPurchaedPart: {},
            partNumber: '',
            partQuantity: '',
            partsDiscount: '',
            partUnitPrice: '',
            vehicleJobPartCustomerUnitPrice: '',
            partDescription: '',
            partsTotal: '',
            isUpdate: false,
            partId: undefined,
            jobItemCustomerPartsPrice: 0,
            vehicleJobPartStatus: '',
            warehouseCode: '',
            warehouseDesc: '',
            partStatusDesc: '',
            formLine: true,
            showPurchase: false,
            vehicleJobPartCostPrice: '',
            vehicleJobPartPaymentType: '',
            vehicleJobPartWIPNumber: '',
            vehicleJobPartDatePurchased: '',
            vehicleJobPartSupplier: '',
            vehicleJobPartImage: '',
            retailPrice: ''
        }));
    };

    const handleClose = () => {
        props.onClose(false);
    };

    const handleOpen = () => {
        setState((st) => ({ ...st, showCancelConfirmModal: !st.showCancelConfirmModal }));
    };
    const closeParts = () => {
        setState((st) => ({ ...st, showStandardPart: false, showPurchase: false, showPartsStatus: false }));
    };

    let setbtn = state.setBackup?.length > 0 ? handleOpen : handleClose;

    const pullPurchasedPart = async (id, selectedPurchaedPart) => {
        if (id && !selectedPurchaedPart.isLocallyUpdated) {
            let res = await getVehicleJobParts_GetDetails(id);
            setState((st) => ({
                ...st,
                partNumber: res.data.partDetails[0].vehicleJobPartNumber,
                partDescription: res.data.partDetails[0].vehicleJobPartDescription,
                vehicleJobPartPaymentType: res.data.partDetails[0].vehicleJobPartPaymentType,
                partQuantity: res.data.partDetails[0].vehicleJobPartQuantity,
                vehicleJobPartWIPNumber: res.data.partDetails[0].vehicleJobPartWIPNumber,
                partUnitPrice: res.data.partDetails[0].vehicleJobPartCostPrice,
                retailPrice: res.data.partDetails[0].vehicleJobPartSellingPrice,
                vehicleJobPartDatePurchased: res.data.partDetails[0].vehicleJobPartDatePurchased
                    ? moment(res.data.partDetails[0].vehicleJobPartDatePurchased).format('YYYY-MM-DD')
                    : '',
                vehicleJobPartSupplier: res.data.partDetails[0].vehicleJobPartSupplier,
                vehicleJobPartImage: res.data.partDetails[0].vehicleJobPartImage,
                status: res.data.partDetails[0].vehicleJobPartStatus,
                isDeleted: false
            }));
        }
        if (selectedPurchaedPart.isLocallyUpdated && selectedPurchaedPart.isLocallyUpdated) {
            console.log(selectedPurchaedPart, 'selectedPurchaedPart');
            setState((st) => ({
                ...st,
                partNumber: selectedPurchaedPart.partNumber,
                partQuantity: selectedPurchaedPart.quantity,
                partsDiscount: selectedPurchaedPart.discount,
                // partUnitPrice: selectedPurchaedPart.unitPrice,
                partUnitPrice: selectedPurchaedPart.unitPrice,
                //  retailPrice: selectedPurchaedPart.customerUnitPrice,
                retailPrice: selectedPurchaedPart.sellingPrice,
                // partUnitPrice: selectedPurchaedPart.standardCost,
                partDescription: selectedPurchaedPart.part,
                // partUnitPrice: selectedPurchaedPart.vehicleJobPartCostPrice,
                vehicleJobPartPaymentType: selectedPurchaedPart.vehicleJobPartPaymentType,
                vehicleJobPartWIPNumber: selectedPurchaedPart.vehicleJobPartWIPNumber,
                vehicleJobPartDatePurchased: selectedPurchaedPart.vehicleJobPartDatePurchased,
                vehicleJobPartSupplier: selectedPurchaedPart.vehicleJobPartSupplier,
                vehicleJobPartImage: selectedPurchaedPart.vehicleJobPartImage,
                isDeleted: false
            }));
        }
    };

    const clearImage = () => {
        setState((st) => ({ ...st, vehicleJobPartImage: '', selectedFileExt: '' }));
    };

    const addPartToLocally = (VehicleJobPartID, localId) => {
        let updatedRows = [...state.rows];

        const partData = {
            vehicleJobPartID: VehicleJobPartID || null,
            partNumber: state.partNumber,
            quantity: state.partQuantity,
            discount: state.partsDiscount,
            unitPrice: state.partUnitPrice,
            customerUnitPrice: state.retailPrice,
            sellingPrice: +state.retailPrice,
            standardCost: +state.partUnitPrice,
            part: state.partDescription,
            vehicleJobPartCostPrice: state.partUnitPrice,
            vehicleJobPartPaymentType: state.vehicleJobPartPaymentType,
            vehicleJobPartWIPNumber: state.vehicleJobPartWIPNumber,
            vehicleJobPartDatePurchased: state.vehicleJobPartDatePurchased,
            vehicleJobPartSupplier: state.vehicleJobPartSupplier,
            vehicleJobPartImage: state.vehicleJobPartImage,
            isDeleted: false,
            isLocallyUpdated: true
        };

        if (VehicleJobPartID) {
            const objIndex = updatedRows.findIndex((q) => q.vehicleJobPartID == VehicleJobPartID);
            if (objIndex > -1) {
                updatedRows[objIndex] = { ...updatedRows[objIndex], ...partData };
            }
        } else if (localId) {
            const objIndex = updatedRows.findIndex((q) => q.id == localId);
            if (objIndex > -1) {
                updatedRows[objIndex] = { ...updatedRows[objIndex], ...partData };
            }
        } else {
            const newPart = {
                ...partData,
                status: state.partId ? state.vehicleJobPartStatus : 'P',
                vehicleJobPartType: 'P',
                warehouseCode: state.warehouseCode || '',
                warehouseDesc: state.warehouseDesc || '',
                statusDesc: state.partId ? state.partStatusDesc : 'Purchased',
                id: updatedRows.length === 0 ? 1 : Math.max(...updatedRows.map((c) => c.id)) + 1
            };

            updatedRows = updatedRows.filter((item) => item.id !== state.partId);
            updatedRows.push(newPart);
        }

        setState((prevState) => ({
            ...prevState,
            rows: updatedRows,
            selectedPurchaedPart: {},
            partNumber: '',
            partQuantity: '',
            partsDiscount: '',
            partUnitPrice: '',
            vehicleJobPartCustomerUnitPrice: '',
            partDescription: '',
            partsTotal: '',
            isUpdate: false,
            partId: undefined,
            jobItemCustomerPartsPrice: 0,
            vehicleJobPartStatus: '',
            warehouseCode: '',
            warehouseDesc: '',
            partStatusDesc: '',
            formLine: true,
            showPurchase: false,
            vehicleJobPartCostPrice: '',
            vehicleJobPartPaymentType: '',
            vehicleJobPartWIPNumber: '',
            vehicleJobPartDatePurchased: '',
            vehicleJobPartSupplier: '',
            vehicleJobPartImage: ''
        }));
    };

    const patchSelectedRow = (data) => {
        setState((st) => ({
            ...st,
            partNumber: data.pn,
            partDescription: data.pd,
            partUnitPrice: data.cp,
            retailPrice: data.sp,
            ///
            customerUnitPrice: '',
            standardCost: '',
            isDeleted: false,
            status: 'P',
            warehouseCode: '',
            warehouseDesc: '',
            statusDesc: 'Purchased',
            vehicleJobPartCostPrice: ''
        }));
    };

    return state.showLoader ? (
        <Grid container alignItems="center" justify="center" style={{ height: 300 }}>
            <Grid item>
                <CircularProgress />
            </Grid>
        </Grid>
    ) : (
        <div className="controller-screen mt_10">
            {state.showPastJob && (
                <DialogComp title={`Past Jobs: ${props.vehicleRegNumber} ${state?.workCategoryValue?.name}`} maxWidth="lg" onClose={closeTheDialog} fullWidth>
                    <PastJobs
                        onClose={closeTheDialog}
                        jobId={props.jobItemJobID}
                        vehicleID={props.vehicleID}
                        VMRSCategoryID={state.jobItemVMRSCategoryID}
                        VMRSSubCategoryID={state.jobItemVMRSSubCategoryID}
                        vehicleRegNumber={props.vehicleRegNumber}
                    />
                </DialogComp>
            )}
            <Grid container spacing={1} justifyContent="space-between">
                <Grid item xs={12} sm={10} md={10} lg={11} xl={11} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Labour Description"
                        fullWidth
                        name="labourDescription"
                        placeholder="Description"
                        onChange={handleInputChange}
                        value={state.labourDescription}
                        errors={state.errors?.labourDescription}
                        isDisabled={state.readOnly || state.isOnHireReadonly}
                        autoFocus={true}
                    />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={1} xl={1} className={isAvoidValidation ? '' : 'mandatory-fields'}>
                    <MandatoryField
                        inputLabel="Hours"
                        type="number"
                        isDisabled={state.readOnly || state.isOnHireReadonly}
                        placeholder="Hours"
                        value={state.labourHours}
                        onChange={handleInputChange}
                        errors={state.errors?.labourHours}
                        name="labourHours"
                        isAvoidValidation={isAvoidValidation}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={2} xl={2} className={isAvoidValidation ? '' : 'mandatory-fields'}>
                    <SelectBox
                        inputLabel="Engineer"
                        name="jobItemEngineerUserId"
                        value={state.jobItemEngineerUserId}
                        List={state.engineer}
                        onChange={handleInputChange}
                        isDisabled={props.isCustomer || state.readOnly || state.isJobOffHire || state.isOnHireReadonly}
                        isRequired={true}
                    />
                    {/* <SingleSelect
                        inputLabel="Engineer"
                        options={state.engineer}
                        value={state.jobEngineerUserID}
                        isRequired={true}
                        onChange={singleSelectChange('jobEngineerUserID')}
                        isDisabled={props.isCustomer || state.readOnly || state.isJobOffHire}
                    /> */}
                    <FormHelperText error>{state.errors?.jobEngineerUserID ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item container xs={12} sm={6} md={6} lg={5} xl={5} className={isAvoidValidation ? '' : 'mandatory-fields'}>
                    <Grid item xs={11}>
                        <SingleSelect
                            inputLabel="Work Category"
                            isRequired={true}
                            style={{ marginTop: -8 }}
                            options={state.workList}
                            value={state.workCategoryValue}
                            onChange={WorkCategoryChange('workCategoryValue')}
                            isDisabled={props.isCustomer || state.readOnly || state.isOnHireReadonly}
                        />
                    </Grid>

                    {state.workCategory && (
                        <Grid item xs={1}>
                            <InputLabel>{'\u{2800}'}</InputLabel>
                            <CustomIconButton
                                iconFontSize={25}
                                toolTipTitle="Get past jobs for the vehicle against this work category"
                                onClick={() => {
                                    setState((st) => ({ ...st, showPastJob: true }));
                                }}
                                icon={SearchIcon}
                            />
                        </Grid>
                    )}
                    <FormHelperText error>{state.errors.workCategory}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2} xl={2} className={isAvoidValidation ? '' : 'mandatory-fields'}>
                    <SelectBox
                        inputLabel=" Cost Type"
                        isRequired={true}
                        List={state.costTypeList || []}
                        onChange={handleInputChange}
                        name="jobItemCostTypeID"
                        value={state.jobItemCostTypeID || ''}
                        isDisabled={props.isCustomer || state.readOnly || state.isOnHireReadonly}
                    />
                    {/* <SingleSelect
                        inputLabel=" Cost Type"
                        isRequired={true}
                        style={{ marginTop: -8 }}
                        options={state.costTypeList || []}
                        value={state.jobItemCostTypeIDValue}
                        isDisabled={props.isCustomer || state.readOnly || state.isOnHireReadonly}
                        onChange={WorkCategoryChange('jobItemCostTypeIDValue')}
                    /> */}
                    <FormHelperText error>{state.errors.jobItemCostTypeIDValue}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2} xl={2} className={isAvoidValidation ? '' : 'mandatory-fields'}>
                    <SelectBox
                        List={state.jobItemStatus}
                        onChange={handleInputChange}
                        name="jobItemStatusID"
                        value={state.jobItemStatusID}
                        inputLabel="Status"
                        isDisabled={props.isCustomer || state.isStatusDisabled}
                        isRequired={true}
                        insertEmpty={state.jobItemStatusID ? false : true}
                    />
                    <FormHelperText error>{state.errors.jobItemStatusID}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={1} xl={1} className="input-style_right">
                    <MandatoryField inputLabel="Total" isDisabled={true} value={formatters.CurrencyNumberWithPound(state.labourRateTotal) || 0} />
                </Grid>
            </Grid>{' '}
            <FormHelperText error>{state.errors.partsStatus}</FormHelperText>
            <Grid container spacing={2} justify="space-between">
                <Grid item xs={12} sm>
                    <Grid container spacing={2}>
                        {(!state.readOnly || state.isHideSubmitForCopied) && (
                            <Grid item xs={12} sm={6} lg={2}>
                                <SecondaryButton
                                    isDisabled={state.readOnly}
                                    isBtnLoader={btnLoader}
                                    disabled={btnLoader}
                                    // onClick={submitSendForApproval}
                                    onClick={state.rows.some((item) => item?.isUpDate) ? handlePartStatus : submitSendForApproval}
                                >
                                    Save
                                </SecondaryButton>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6} lg={2}>
                            <SecondaryButton onClick={setbtn}>Cancel</SecondaryButton>
                        </Grid>
                    </Grid>
                </Grid>

                {checkStatus ? (
                    state.readOnly || state.isOnHireReadonly ? null : (
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <CustomChip
                                        label={'Add Standard Part'}
                                        icon={AddIcon}
                                        toolTipTitle={`Add Standard Part`}
                                        iconFontSize={16}
                                        className="btn-m"
                                        onClick={() => showParts('showStandardPart')}
                                    />
                                </Grid>
                                <Grid item>
                                    <CustomChip
                                        label={'Add Purchased Part'}
                                        icon={AddIcon}
                                        toolTipTitle={`Add Purchased Part`}
                                        iconFontSize={16}
                                        className="btn-m"
                                        onClick={() => showParts('showPurchase')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                ) : null}
            </Grid>
            {state.showCancelConfirmModal ? (
                <DialogComp title={''} onClose={handleOpen} maxWidth="lg">
                    <ConfirmationModal message={'Are you sure do you want to close job Item Screen'} handleCancel={handleOpen} handleSubmit={props.onClose} />
                </DialogComp>
            ) : null}
            {state.showOrderPartMailModal ? (
                <DialogComp
                    open={state.showOrderPartMailModal}
                    onClose={() =>
                        setState((st) => ({
                            ...st,
                            showOrderPartMailModal: false,
                            selectedOrderPartDetail: {}
                        }))
                    }
                    title={'Parts Request'}
                    maxWidth="sm"
                >
                    <OrderParts data={state.selectedOrderPartDetail || {}} onClose={props.onClose} />
                </DialogComp>
            ) : null}
            {state.showStandardPart ? (
                <DialogComp title={'Add Standard Part'} onClose={closeParts} maxWidth="lg" fullWidth onlyTab>
                    <MatchingPartList
                        ServiceAreaID={props.ServiceAreaID}
                        handleRowSelect={handleRowSelect}
                        state={state}
                        masterWarehouseCode={props.masterWarehouseCode}
                    />
                </DialogComp>
            ) : null}
            {state.showPurchase ? (
                <DialogComp
                    title={`${state.selectedPurchaedPart?.isLocallyUpdated || state.selectedPurchaedPart?.vehicleJobPartID ? `Update` : 'Add'} Purchased Part`}
                    onClose={closePurchaseParts}
                    maxWidth="lg"
                    fullWidth
                    fullScreen
                    overflow="auto"
                >
                    <JobItemPurchaseParts
                        state={state}
                        selectedPurchaedPart={state.selectedPurchaedPart || {}}
                        handleInputChange={handleInputChange}
                        handleFilter={handleFilter}
                        handleImage={handleImage}
                        addPartToLocally={addPartToLocally}
                        patchSelectedRow={patchSelectedRow}
                        pullPurchasedPart={pullPurchasedPart}
                        clearImage={clearImage}
                    />
                </DialogComp>
            ) : null}
            {state.showPartsStatus ? (
                <DialogComp title={'Confirmation'} onClose={closeParts} maxWidth="md" fullWidth overflow="auto">
                    <JobStatusHandler
                        isBtnLoader={btnLoader}
                        submitSendForApproval={submitSendForApproval}
                        list={state.rows}
                        onClose={closeParts}
                        PartObject={state.partObj || {}}
                        depotList={state.depots}
                        state={state}
                        setState={setState}
                        handleStockMail={handleStockMail}
                    />
                </DialogComp>
            ) : null}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TableContainer style={{ maxHeight: 300 }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {base_columns(isEngineer).map((column) => (
                                        <StyledTableCell key={column.key} style={{ width: column.width }} align={column.align}>
                                            {column.name}
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.rows.map((row, rowIndex) => {
                                    let list = [];
                                    if (row.status) {
                                        if (row.status === 'S') {
                                            list = [...state.gridpartStatus].filter((k) => k.id === 'I' || k.id === row.status || k.id === 'T');
                                        } else if (row.status === 'Z') {
                                            list = [...state.gridpartStatus].filter((k) => k.id === 'I' || k.id === row.status || k.id === 'E' || k.id === 'S');
                                        } else {
                                            list = [...state.gridpartStatus].filter((k) => k.id === 'I' || k.id === row.status);
                                        }
                                    } else {
                                        list = [...state.gridpartStatus].filter((k) => k.id !== 'T');
                                    }
                                    if (!row.isDeleted) {
                                        return (
                                            <StyledTableRow key={rowIndex}>
                                                <StyledTableCell align="center">
                                                    {/* {row.status === 'P' ? (
                                                        <InputTextWithMemo value={row.quantity} disabled />
                                                    ) : ( */}
                                                    <InputTextWithMemo
                                                        type="number"
                                                        value={row.quantity}
                                                        onChange={handleGridEvents(row, rowIndex)}
                                                        name="quantity"
                                                        style={{ padding: 0 }}
                                                        disabled={row.isQuentityDisabled || state.readOnly}
                                                        // isDisabled={state.readOnly}
                                                    />
                                                    {/* )} */}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <div>
                                                        <div style={{ fontSize: 14, fontWeight: 500 }}>{row.partNumber}</div>
                                                        <div style={{ fontSize: 12, fontStyle: 'italic' }}>{row.part}</div>
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {row.status === 'P' || row.status === 'M' ? (
                                                        <InputTextWithMemo value={row.status === 'P' ? 'Purchased' : 'Travel'} disabled />
                                                    ) : row.statusOFDesc ? (
                                                        <InputTextWithMemo value={row.statusOFDesc} disabled />
                                                    ) : (
                                                        <SelectBox
                                                            List={list || []}
                                                            value={row.tempstatus || row.status}
                                                            onChange={handleGridEvents(row, rowIndex)}
                                                            name="tempstatus"
                                                            style={{ padding: 0 }}
                                                            disabled={row.isDisabled}
                                                            isDisabled={state.readOnly}
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {!row.isWarehouseDropdown ? (
                                                        <div>{row.warehouseDesc}</div>
                                                    ) : (
                                                        <SelectBox
                                                            List={state.warehouse || []}
                                                            value={row.warehouseCode}
                                                            onChange={handleGridEvents(row, rowIndex)}
                                                            name="warehouseCode"
                                                            isDisabled={state.readOnly}
                                                        />
                                                    )}
                                                    <FormHelperText error>{row.errorwarehouse}</FormHelperText>
                                                </StyledTableCell>
                                                {isEngineer || state.recharge ? (
                                                    <StyledTableCell>
                                                        <b>{CalculateCutomerTotal(row)}</b>
                                                    </StyledTableCell>
                                                ) : (
                                                    <StyledTableCell>
                                                        <b>{CalculateNonChargeableTotal(row)}</b>
                                                    </StyledTableCell>
                                                )}
                                                <StyledTableCell>
                                                    {state.readOnly ? null : ( // || state.isOnHireReadonly
                                                        <>
                                                            {checkhaveAccess?.CanDeleteJobLines && !row.isQuentityDisabled && (
                                                                <DeleteButton onClick={() => handleDeletePart(row, rowIndex)} toolTipTitle="Delete Job Item" />
                                                            )}
                                                            {row.status == 'P' && <EditButton onClick={() => showPartsEdit(row)} toolTipTitle="Edit Part" />}
                                                        </>
                                                    )}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    );
}

export default AddJobItemModal;
