import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';

export const getContracts = async (id) => {
    let res = await getWithAuth(`Contracts/${id}`);
    return res;
};
export const getVehicleContract = async (id) => {
    let res = await getWithAuth(`Contracts/VehicleContracts_GetDetails?Id=${id}`);
    return res;
};
export const VehicleContracts_GetNotes = async (id) => {
    let res = await getWithAuth(`VehicleContract/VehicleContracts_GetNotes?Id=${id}`);
    return res;
};

export const getContractsDropDown = async () => {
    let res = await getWithAuth(`Contracts/screenDropdowns`);
    return res;
};

export const postContracts = async (data) => {
    let res = await postWithAuth(`Contracts`, data);
    return res;
};
export const postVehicleContract = async (data) => {
    let res = await postWithAuth(`VehicleContract`, data);
    return res;
};

export const postVehicleContractReviewStatus = async (id) => {
    let res = await postWithAuth(`VehicleContract/VehicleContract_SetReviewed?VehicleContractID=${id}`);
    return res;
};
