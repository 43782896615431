import React, { useCallback, useEffect, useMemo } from 'react';
import { BreadCrumbs, DatePicker, DateTimePicker, EditButton, formatters, SingleSelect } from '../../../../Core/Controls';
import { Card, Grid, InputLabel, Typography, CircularProgress, Modal, ButtonGroup, Button } from '@material-ui/core';
import { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, ComposedChart } from 'recharts';
import { PieChart, Pie, Sector, LabelList } from 'recharts';
import './jobReport.scss';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import { useState } from 'react';
import moment from 'moment';
import { getjobByType } from '../../../../Core/Services/InternalUser/jobReportingService';
import { getVehicleScreenDropdowns, JobReporting_ScreenDropDown } from '../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { GetJobReportingExcelFile } from '../../../../Core/Services/InternalUser/excelDownload';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { getJobEnggById } from '../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import DateFieldsModal from './../Vehicles/more/dateFields';
import { useWindowSize } from '../../../../Core/Hooks/useWindowSize';

const Crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Job Reporting', active: true }
];

function generateRandomColorArray(numColors) {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
        // Generate random RGB values for each color
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        colors.push(`rgb(${r}, ${g}, ${b})`);
    }
    return colors;
}

const COLORS = [
    '#0074D9',
    '#FF4136',
    '#2ECC40',
    '#FF851B',
    '#7FDBFF',
    '#B10DC9',
    '#FFDC00',
    '#001f3f',
    '#39CCCC',
    '#01FF70',
    '#85144b',
    '#F012BE',
    '#3D9970',
    '#111111',
    '#AAAAAA'
];

const barColors = {
    0: '#0074D9',
    1: '#FF4136',
    2: '#2ECC40',
    3: '#FF851B',
    4: '#7FDBFF',
    5: '#B10DC9',
    6: '#FFDC00',
    7: '#001f3f',
    8: '#39CCCC',
    9: '#01FF70',
    10: '#85144b',
    11: '#F012BE',
    12: '#3D9970',
    13: '#111111',
    14: '#AAAAAA'
};

const DateList = [
    { id: 'S', Name: 'Sign Off' },
    { id: 'I', Name: 'Invoiced' },
    { id: 'R', Name: 'Reviewed' },
    { id: 'B', Name: 'Booking Date' },
    { id: 'A', Name: 'All' }
];

const columns = [
    { key: 'reg', name: 'Reg Number', width: 100 },
    { key: 'description', name: 'Job Description', width: 230 },
    { key: 'customer', name: 'Customer Name', width: 250 },
    { key: 'depot', name: 'Depot Name', width: 150 },
    { key: 'area', name: 'Service Area', width: 100 },
    { key: 'engineer', name: 'Engineer', width: 120 },
    { key: 'bookedDate', name: 'Date', width: 100, formatter: formatters.Date_DDMMYYYY },
    { key: 'orderNo', name: 'Job Ref', width: 150 },
    { key: 'jobType', name: 'Job Priority', width: 120 },
    { key: 'make', name: 'Make', width: 120 },
    { key: 'model', name: 'Model', width: 120 },
    { key: 'type', name: 'Vehicle Type', width: 120 },
    { key: 'catetory', name: 'Vehicle Category', width: 120 },
    { key: 'ownershipType', name: 'Ownership Type', width: 120 },
    { key: 'regDate', name: 'Vehicle Age', width: 120 },
    { key: 'costType', name: 'Cost Type', width: 140 },
    { key: 'part', name: 'Part', width: 100, formatter: formatters.NumberWithPound, align: 'right' },
    { key: 'labour', name: 'Labour', width: 100, formatter: formatters.NumberWithPound, align: 'right' },
    { key: 'total', name: 'Total', width: 100, formatter: formatters.NumberWithPound, align: 'right' }
];

let startDate = moment().startOf('month').format('YYYY-MM-DD');
let endDate = moment().format('YYYY-MM-DD');

let options = [
    { id: 1, name: 'Hours' },
    { id: 2, name: 'amount' }
];

const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;

    return (
        <g>
            <text x={x + width / 2} y={y - radius} fill="#000000" textAnchor="middle" dominantBaseline="middle">
                {value ? <>£{value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}
            </text>
        </g>
    );
};

const JobReportScreen = (props) => {
    const isCustomer = useLoginInfo().userRoles.includes('customer');
    const isEngineer = useLoginInfo().userRoles.includes('engineer');
    const history = useHistory();
    const [state, setState] = useState({
        startDate: startDate,
        endDate: endDate,
        deoptList: [],
        jobTypeList: [],
        monthYearList: [],
        vmrsDescriptionList: [],
        url: `vehicleJob/GetCustomerSpend?startDate=${startDate}&endDate=${endDate}`,
        checkCondition: false,
        showDetailsPopup: false,
        filterTechnician: [],
        makes: [],
        models: [],
        vehicleTypes: [],
        vehicleCategories: [],
        vehicleOwnershipTypes: [],
        dateFilters: [],
        costTyes: [],
        graphData: 1
    });

    const [value, setValue] = useState({
        name: '',
        value: '',
        heading: ''
    });

    const [chartData, setChartData] = useState({
        vmrsList: [],
        deoptList: []
    });

    useEffect(() => {
        setChartData((st) => ({ ...st, vmrsList: state.vmrsDescriptionList, deoptList: state.deoptList }));
    }, [state.vmrsDescriptionList, state.deoptList]);

    const handleDetailpopUp = (params, heading) => {
        setState((st) => ({ ...st, showDetailsPopup: !st.showDetailsPopup }));
        setValue((st) => ({ ...st, name: params.name || '', value: params.Total || '', heading: heading || '' }));
    };

    const inputChange = useCallback((e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    }, []);

    const [height, width] = useWindowSize();

    const [tempOptions, setTempOptions] = useState({
        customerIDValue: null,
        makeIDValue: null,
        modelIDValue: null,
        vehicleTypeIDValue: null,
        vehicleCategoryIDValue: null,
        ownershipTypeIDValue: null,
        dateFilterIDValue: null,
        vehicleAgeIDValue: null,
        costTypeIDValue: null
    });

    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.jobID}/${val?.vehicleID}`,
            search: isEngineer ? '?tab_testing_tabs=1' : '',
            state: 'jobs'
        });
    };

    let getURl = () => {
        return `vehicleJob/GetCustomerSpend?startDate=${state.startDate}&endDate=${state.endDate}&custId=${
            tempOptions.customerIDValue?.id || ''
        }&ServiceAgentID=${tempOptions.serviceAreaIDValue?.id || ''}&TechnicianUserID=${tempOptions.engineerIDValue?.id || ''}
        &DepotID=${tempOptions.deoptIDValue?.id || ''}
        &make=${tempOptions.makeIDValue?.id || ''}
        &model=${tempOptions.modelIDValue?.id || ''}
        &vehicleType=${tempOptions.vehicleTypeIDValue?.id || ''}
        &vehicleCategory=${tempOptions.vehicleCategoryIDValue?.id || ''}
        &ownershipType=${tempOptions.ownershipTypeIDValue?.id || ''}
        &dateFilter=${tempOptions.dateFilterIDValue?.id || ''}
        &vehicleAge=${tempOptions.vehicleAgeIDValue?.id || ''}
        &costTypeCode=${tempOptions.costTypeIDValue?.id || ''}
        `;
    };

    const singleSelectChange = (nm) => (ev, val) => {
        if (nm === 'serviceAreaIDValue' && val?.id) PullEngineerList(val?.id);
        if (val) {
            setTempOptions((st) => {
                let newSt = { ...st, [nm]: val };
                if (nm === 'customerIDValue') {
                    newSt.depotsIDValue = null;
                }
                return newSt;
            });
        } else {
            setTempOptions((st) => ({ ...st, [nm]: null }));
        }
    };

    let PullEngineerList = async (serviceAreaID) => {
        if (serviceAreaID) {
            let res = await getJobEnggById(serviceAreaID);
            if (res.success) {
                setState((d) => ({ ...d, filterTechnician: res?.data?.list }));
            }
        }
    };

    useEffect(async () => {
        let isCustSelected;
        let res = await JobReporting_ScreenDropDown();
        setState((st) => ({
            ...st,
            customerList: res.data.customers || [],
            depotsList: res.data.depots || [],
            technician: res.data.technician || [],
            serviceAgents: res.data.serviceAgents || [],
            makes: res.data.makes || [],
            models: res.data.models || [],
            vehicleTypes: res.data.vehicleTypes || [],
            vehicleCategories: res.data.vehicleCategories || [],
            vehicleOwnershipTypes: res.data.vehicleOwnershipTypes || [],
            costTyes: res.data.costType || [],
            dateFilters: [
                {
                    id: 'S',
                    name: 'Signed Off'
                },
                {
                    id: 'I',
                    name: 'Invoiced'
                },
                {
                    id: 'R',
                    name: 'Reviewed'
                },
                {
                    id: 'B',
                    name: 'Booking Date '
                },
                {
                    id: 'A',
                    name: 'All'
                }
            ],
            vehicleAgeList: Array.from({ length: 21 }, (_, index) => ({
                id: index.toString(),
                name: index.toString()
            }))
        }));

        if (props.customerID && props.fromTab) {
            isCustSelected = res.data.customers.find((p) => props.customerID == p.id);
        } else if (isCustomer) {
            isCustSelected = res.data.customers[0];
        }
        setTempOptions((st) => ({ ...st, customerIDValue: isCustSelected }));
    }, []);

    useEffect(async () => {
        let res = await getjobByType(
            state.startDate,
            state.endDate,
            tempOptions.customerIDValue?.id || '',
            tempOptions.serviceAreaIDValue?.id || '',
            tempOptions.engineerIDValue?.id || '',
            tempOptions.deoptIDValue?.id || '',
            tempOptions.makeIDValue?.id || '',
            tempOptions.modelIDValue?.id || '',
            tempOptions.vehicleTypeIDValue?.id || '',
            tempOptions.vehicleCategoryIDValue?.id || '',
            tempOptions.ownershipTypeIDValue?.id || '',
            tempOptions.dateFilterIDValue?.id || '',
            tempOptions.vehicleAgeIDValue?.id || '',
            tempOptions.costTypeIDValue?.id || ''
        );
        if (res.success) {
            let deoptList = [...res?.data?.depot].map((m, i) => ({
                name: m.depot,
                Total: m.jobTotal,
                HourTotal: m.labourHour
            }));
            let vmrsDescriptionList = [...res?.data?.vmrsDescription].map((m, i) => ({
                name: m.vmrsDescription,
                Total: m.jobTotal,
                HourTotal: m.labourHours
            }));

            let jobTypeList = [...res?.data?.area]?.map((m) => ({
                name: m.area,
                Total: m.jobTotal,
                HourTotal: m.labourHour
            }));

            let monthYearList = [...res?.data?.monthYear].map((m) => ({
                name: m.monthYear,
                Total: m.jobTotal,
                HourTotal: m.labourHour
            }));
            setState((st) => ({
                ...st,
                deoptList: deoptList,
                jobTypeList: jobTypeList,
                monthYearList: monthYearList,
                vmrsDescriptionList: vmrsDescriptionList,
                checkCondition: jobTypeList.length > 0 ? true : false,
                url: getURl()
            }));
        }
        getURl();
    }, [
        tempOptions.customerIDValue,
        state.startDate,
        state.endDate,
        tempOptions.serviceAreaIDValue,
        tempOptions.engineerIDValue,
        tempOptions.deoptIDValue,
        tempOptions.makeIDValue?.id,
        tempOptions.modelIDValue?.id,
        tempOptions.vehicleTypeIDValue?.id,
        tempOptions.vehicleCategoryIDValue?.id,
        tempOptions.ownershipTypeIDValue?.id,
        tempOptions.dateFilterIDValue?.id,
        tempOptions.vehicleAgeIDValue?.id,
        tempOptions.costTypeIDValue?.id
    ]);

    const convertData = (res) => {
        setState((st) => ({ ...st, VorList: res.data.list }));
        return {
            records: res.data.list,
            total: res.data.total
        };
    };
    const handleGraph = (res) => {
        setState((st) => ({ ...st, graphData: res }));
    };

    const editActionBtn = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div
                onClick={() => {
                    handleEditClick(row);
                }}
                style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}
            >
                {row.reg}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const updatedColumns = useMemo(() => {
        const tempCols = [...columns];
        let actionCol = tempCols.find((element) => element.key === 'reg');
        if (actionCol) {
            actionCol.formatter = editActionBtn;
        }
        return tempCols;
    }, []);

    return (
        <div className={`${props.fromTab ? '' : 'screen'}`}>
            {props.fromTab ? '' : <BreadCrumbs crumbs={Crumbs} />}

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel required shrink>
                        Service Area
                    </InputLabel>
                    <SingleSelect
                        options={state.serviceAgents}
                        value={tempOptions.serviceAreaIDValue}
                        onChange={singleSelectChange('serviceAreaIDValue')}
                        disabled={props.customerID}
                    />
                    {/* <FormHelperText error>{state.errors.customerID}</FormHelperText> */}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel required shrink>
                        Engineer
                    </InputLabel>
                    <SingleSelect
                        options={state.filterTechnician}
                        value={tempOptions.engineerIDValue}
                        onChange={singleSelectChange('engineerIDValue')}
                        disabled={props.customerID}
                    />
                    {/* <FormHelperText error>{state.errors.customerID}</FormHelperText> */}
                </Grid>

                {!isCustomer && (
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <InputLabel required shrink>
                            Customer
                        </InputLabel>
                        <SingleSelect
                            options={state.customerList}
                            value={tempOptions.customerIDValue}
                            onChange={singleSelectChange('customerIDValue')}
                            disabled={props.customerID}
                        />
                        {/* <FormHelperText error>{state.errors.customerID}</FormHelperText> */}
                    </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <InputLabel required shrink>
                        Depot
                    </InputLabel>
                    <SingleSelect
                        options={state.depotsList}
                        value={tempOptions.deoptIDValue}
                        onChange={singleSelectChange('deoptIDValue')}
                        disabled={props.customerID}
                    />
                    {/* <FormHelperText error>{state.errors.customerID}</FormHelperText> */}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel shrink>Make</InputLabel>
                    <SingleSelect
                        options={state.makes}
                        value={tempOptions.makeIDValue}
                        onChange={singleSelectChange('makeIDValue')}
                        disabled={props.customerID}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel shrink>Modal</InputLabel>
                    <SingleSelect
                        options={state.models}
                        value={tempOptions.modelIDValue}
                        onChange={singleSelectChange('modelIDValue')}
                        disabled={props.customerID}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel shrink>Vehicle Type</InputLabel>
                    <SingleSelect
                        options={state.vehicleTypes}
                        value={tempOptions.vehicleTypeIDValue}
                        onChange={singleSelectChange('vehicleTypeIDValue')}
                        disabled={props.customerID}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel shrink>Vehicle Category</InputLabel>
                    <SingleSelect
                        options={state.vehicleCategories}
                        value={tempOptions.vehicleCategoryIDValue}
                        onChange={singleSelectChange('vehicleCategoryIDValue')}
                        disabled={props.customerID}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel shrink>Ownership Type</InputLabel>
                    <SingleSelect
                        options={state.vehicleOwnershipTypes}
                        value={tempOptions.ownershipTypeIDValue}
                        onChange={singleSelectChange('ownershipTypeIDValue')}
                        disabled={props.customerID}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel shrink>Cost Type</InputLabel>
                    <SingleSelect
                        options={state.costTyes}
                        value={tempOptions.costTypeIDValue}
                        onChange={singleSelectChange('costTypeIDValue')}
                        disabled={props.customerID}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel shrink>Date Filter</InputLabel>
                    <SingleSelect
                        options={state.dateFilters}
                        value={tempOptions.dateFilterIDValue}
                        onChange={singleSelectChange('dateFilterIDValue')}
                        disabled={props.customerID}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel shrink>Vehicle Age</InputLabel>
                    <SingleSelect
                        options={state.vehicleAgeList}
                        value={tempOptions.vehicleAgeIDValue}
                        onChange={singleSelectChange('vehicleAgeIDValue')}
                        disabled={props.customerID}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel shrink>Start Date</InputLabel>
                    <DatePicker value={state.startDate} onChange={inputChange} name="startDate" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel shrink>End Date</InputLabel>
                    <DatePicker value={state.endDate} onChange={inputChange} name="endDate" />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel shrink>Date</InputLabel>
                    <SingleSelect
                        options={DateList}
                        value={tempOptions.deoptIDValue}
                        onChange={singleSelectChange('deoptIDValue')}
                        disabled={props.customerID}
                    />
                </Grid> */}

                {/* {tempOptions.customerIDValue ? ( */}
                <>
                    <Grid item xs={12} md={4} sm={6} lg={3}></Grid>
                    {isCustomer && <Grid item xs={12} md={4} sm={6} lg={3}></Grid>}
                    {!state.checkCondition ? (
                        <Grid item xs={12}>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <h4>No Records Found</h4>
                            </div>
                        </Grid>
                    ) : (
                        <Grid item container spacing={2}>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                                <div style={{ position: 'relative' }}>
                                    <Typography variant="button" style={{ fontWeight: 600 }} color="secondary">
                                        {' '}
                                        Month Year &nbsp;
                                        <ButtonGroup size="small" aria-label="small outlined button group" color="primary" style={{ height: '20px' }}>
                                            {options.map((k) => (
                                                <Button
                                                    style={{ textTransform: 'capitalize' }}
                                                    variant={state.graphData === k.id ? 'contained' : 'outlined'}
                                                    onClick={() => handleGraph(k.id)}
                                                >
                                                    {k.name}
                                                </Button>
                                            ))}
                                        </ButtonGroup>
                                    </Typography>
                                    <Grid container spacing={2} className="pieChart" style={{ marginTop: 0 }}>
                                        <Grid item xs={6}>
                                            <div style={{ height: 230 }}>
                                                {state.monthYearList.length > 0 ? (
                                                    <ResponsiveContainer>
                                                        <PieChart>
                                                            <Pie
                                                                data={state.monthYearList}
                                                                cx="50%"
                                                                cy="50%"
                                                                paddingAngle={3}
                                                                outerRadius={70}
                                                                innerRadius={40}
                                                                // paddingAngle={5}
                                                                // fill="#8884d8"
                                                                dataKey={state.graphData === 1 ? 'HourTotal' : 'Total'}
                                                                // label
                                                            >
                                                                <Tooltip />
                                                                {state.monthYearList.map((entry, index) => (
                                                                    <>
                                                                        <Cell
                                                                            key={`cell-${index}`}
                                                                            fill={COLORS[index % COLORS.length]}
                                                                            style={{ cursor: 'pointer' }}
                                                                            // onClick={() => {
                                                                            //     handleDetailpopUp(entry, 'Month Year');
                                                                            // }}
                                                                        />
                                                                    </>
                                                                ))}
                                                            </Pie>
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                ) : (
                                                    <Grid item container justifyContent="center" alignContent="center">
                                                        <div style={{ width: '100%', textAlign: 'center' }}>
                                                            <h4>No Records Found</h4>
                                                        </div>
                                                    </Grid>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {' '}
                                            <div style={{ position: 'absolute', top: 44, maxHeight: '190px', overflow: 'auto' }} className="custom-scroll">
                                                {state.monthYearList.map((m, i) => {
                                                    let color = COLORS[i];
                                                    let newSt = state.graphData === 1 ? m.HourTotal : m.Total;
                                                    return (
                                                        <div style={{ padding: 0 }}>
                                                            <div style={{ fontSize: 13, display: 'flex', justifyContent: 'flex-start' }}>
                                                                {' '}
                                                                <div style={{ background: color, height: 10, width: 10, marginTop: 4 }}></div>
                                                                <div style={{ fontWeight: 700 }}>&nbsp;{m.name}</div> &nbsp;
                                                                <div style={{ fontSize: 12, marginTop: 2 }}>
                                                                    (
                                                                    <b>
                                                                        {state.graphData === 1
                                                                            ? formatters.ToFixedDigit(newSt)
                                                                            : formatters.CurrencyNumberWithPound(newSt)}
                                                                        {/* {formatters.curr} */}
                                                                        {/* &nbsp;(
                                                                    {m.Total ? (
                                                                        <b>£{m.Total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')})</b>
                                                                    ) : (
                                                                        <>£0.00</>
                                                                    )} */}
                                                                    </b>
                                                                    )
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={9}>
                                <Typography variant="button" style={{ fontWeight: 600 }} color="secondary">
                                    VMRS Description
                                </Typography>
                                <div className="barchart">
                                    {chartData.vmrsList.length > 0 ? (
                                        <ResponsiveContainer>
                                            <BarChart
                                                data={chartData.vmrsList}
                                                margin={{
                                                    top: 20,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis
                                                    dataKey="name"
                                                    textAnchor="end"
                                                    sclaeToFit="true"
                                                    verticalAnchor="start"
                                                    allowDataOverflow={false}
                                                    interval={0}
                                                    // dy={-5}
                                                    angle={width >= 600 ? '0' : '-20'}
                                                    style={{
                                                        fontSize: width >= 600 ? 10 : 6,
                                                        fontWeight: 700
                                                    }}
                                                />
                                                <YAxis style={{ fontSize: 12 }} />
                                                <Tooltip
                                                    contentStyle={{ background: 'rgba(0,0,0,0.8)', color: 'white', fontSize: 12, fontWeight: 700 }}
                                                    position={{ x: 30, y: 20 }}
                                                />
                                                {/* <Legend /> */}

                                                <Bar dataKey={state.graphData === 1 ? 'HourTotal' : 'Total'} fill={'White'} barSize={40} height={10}>
                                                    {/* <LabelList dataKey={state.graphData === 1 ? 'HourTotal' : 'Total'} content={renderCustomizedLabel} /> */}
                                                    {chartData.vmrsList.map((entry, index) => (
                                                        <Cell
                                                            key={`cell-${index}`}
                                                            fill={barColors[index]}
                                                            // onClick={() => {
                                                            //     handleDetailpopUp(entry, 'VMRS');
                                                            // }}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    ))}
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    ) : (
                                        <Grid item container justifyContent="space-evenly" alignContent="center">
                                            <div style={{ width: '100%', textAlign: 'center' }}>
                                                <h4>No Records Found</h4>
                                            </div>
                                        </Grid>
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                                <div style={{ position: 'relative' }}>
                                    <Typography variant="button" style={{ fontWeight: 600 }} color="secondary">
                                        Area
                                    </Typography>
                                    <Grid container spacing={2} className="pieChart" style={{ marginTop: 0 }}>
                                        <Grid item xs={6}>
                                            <div style={{ height: 230 }}>
                                                {state.jobTypeList.length > 0 ? (
                                                    <ResponsiveContainer>
                                                        <PieChart>
                                                            <Pie
                                                                data={state.jobTypeList}
                                                                cx="50%"
                                                                cy="50%"
                                                                paddingAngle={3}
                                                                outerRadius={70}
                                                                innerRadius={40}
                                                                fill="#8884d8"
                                                                // dataKey="Total"
                                                                dataKey={state.graphData === 1 ? 'HourTotal' : 'Total'}
                                                                // label
                                                            >
                                                                {state.jobTypeList.map((entry, index) => (
                                                                    <>
                                                                        <Cell
                                                                            key={`cell-${index}`}
                                                                            fill={COLORS[index % COLORS.length]}
                                                                            style={{ cursor: 'pointer' }}
                                                                            // onClick={() => {
                                                                            //     handleDetailpopUp(entry, ' Job Type');
                                                                            // }}
                                                                        />
                                                                    </>
                                                                ))}
                                                            </Pie>
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                ) : (
                                                    <Grid item container justifyContent="space-evenly" alignContent="center">
                                                        <div style={{ width: '100%', textAlign: 'center' }}>
                                                            <h4>No Records Found</h4>
                                                        </div>
                                                    </Grid>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div
                                                style={{ position: 'absolute', right: 2, top: 44, maxHeight: '190px', overflow: 'auto' }}
                                                className="custom-scroll"
                                            >
                                                {state.jobTypeList.map((m, i) => {
                                                    let color = COLORS[i];
                                                    let newSt = state.graphData === 1 ? m.HourTotal : m.Total;
                                                    return (
                                                        <div style={{ padding: 0 }}>
                                                            <div style={{ fontSize: 13, display: 'flex', justifyContent: 'flex-start' }}>
                                                                {' '}
                                                                <div style={{ background: color, height: 10, width: 10, marginTop: 4 }}></div>
                                                                <div style={{ fontWeight: 700 }}>&nbsp;{m.name}</div>
                                                                <div style={{ fontSize: 11, marginTop: 2 }}>
                                                                    &nbsp; ({' '}
                                                                    {state.graphData === 1
                                                                        ? formatters.ToFixedDigit(newSt)
                                                                        : formatters.CurrencyNumberWithPound(newSt)}
                                                                    )
                                                                    {/* {m.Total ? (
                                                                        <b>£{m.Total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')})</b>
                                                                    ) : (
                                                                        <>£0.00</>
                                                                    )}
                                                                    ) */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={9}>
                                <div>
                                    <Typography variant="button" className="barHeading" style={{ fontWeight: 600 }} color="secondary">
                                        Depot
                                    </Typography>
                                    <div className="barchart">
                                        {chartData.deoptList.length > 0 ? (
                                            <ResponsiveContainer>
                                                <BarChart
                                                    data={chartData.deoptList}
                                                    margin={{
                                                        top: 20,
                                                        right: 30,
                                                        left: 20,
                                                        bottom: 10
                                                    }}
                                                >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis
                                                        dataKey="name"
                                                        textAnchor="end"
                                                        sclaeToFit="true"
                                                        verticalAnchor="start"
                                                        allowDataOverflow={false}
                                                        interval={0}
                                                        // dy={-5}
                                                        angle={width >= 600 ? '0' : '-20'}
                                                        style={{
                                                            fontSize: width >= 600 ? 10 : 6,
                                                            fontWeight: 700
                                                        }}
                                                    />
                                                    <YAxis style={{ fontSize: 12 }} />
                                                    <Tooltip
                                                        contentStyle={{ background: 'rgba(0,0,0,0.8)', color: 'white', fontSize: 12, fontWeight: 700 }}
                                                        // position={{ x: 30, y: 20 }}
                                                    />
                                                    {/* <Legend /> */}

                                                    <Bar dataKey={state.graphData === 1 ? 'HourTotal' : 'Total'} fill={'White'} barSize={40} height={10}>
                                                        {/* <LabelList dataKey={state.graphData === 1 ? 'HourTotal' : 'Total'} content={renderCustomizedLabel} /> */}
                                                        {chartData.deoptList.map((entry, index) => (
                                                            <Cell
                                                                key={`cell-${index}`}
                                                                fill={barColors[index]}
                                                                // onClick={() => {
                                                                //     handleDetailpopUp(entry, ' Depots');
                                                                // }}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        ))}
                                                    </Bar>
                                                </BarChart>
                                            </ResponsiveContainer>
                                        ) : (
                                            <Grid item container justifyContent="space-evenly" alignContent="center">
                                                <div style={{ width: '100%', textAlign: 'center' }}>
                                                    <h4>No Records Found</h4>
                                                </div>
                                            </Grid>
                                        )}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <GijgoGrid
                                    dataConverter={(res) => convertData(res)}
                                    columns={updatedColumns}
                                    getUrl={state.url}
                                    // isReload={state.isReload}
                                    showDownload={true}
                                    downloadBtnName="Download xlsx"
                                    downloadCustomUrl={() =>
                                        GetJobReportingExcelFile({
                                            fromDate: state.startDate,
                                            toDate: state.endDate,
                                            customerID: tempOptions.customerIDValue?.id || '',
                                            fileExt: 'xlsx'
                                        })
                                    }
                                />
                            </Grid>
                        </Grid>
                    )}
                </>
                {/* ) : (
                    <Grid item xs={12}>
                        {!isCustomer ? (
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <h4>Select customer to view report data</h4>
                            </div>
                        ) : (
                            <div style={{ width: '100%' }}>
                                <div
                                    style={{
                                        height: 'calc(100vh - 150px)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <CircularProgress />
                                </div>
                            </div>
                        )}
                    </Grid>
                )} */}
            </Grid>
            {state.showDetailsPopup ? (
                <DialogComp maxWidth="sm" title={`${value.heading} Details`} onClose={handleDetailpopUp}>
                    <div style={{ padding: 20 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <b style={{ width: '50%', textAlign: 'left' }}>Name</b> :<b style={{ width: '50%', textAlign: 'Right' }}>{value.name}</b>
                            </Grid>
                            <Grid item xs={12}>
                                <b style={{ width: '50%', textAlign: 'left' }}>Total </b>:<b style={{ width: '50%', textAlign: 'Right' }}>{value.value}</b>
                            </Grid>
                        </Grid>
                    </div>
                </DialogComp>
            ) : null}
        </div>
    );
};

export default JobReportScreen;
