import { useContext } from 'react';
import { EditInapectionContaxt } from './editInspectionProviderImports';
import { Grid, Hidden, Paper, TableBody, TableContainer, TableHead, TableRow, Typography, Table, FormHelperText, colors } from '@material-ui/core';
import { CustomChip, UploadWithBadge } from '../../../../../../Core/Controls';
import EditIcon from '@material-ui/icons/Edit';
import { CloseIcon } from '@material-ui/icons/Close';
import { Collapse } from '@material-ui/core/Collapse';

export const EditInspection = ({ classes, StyledTableCell, StyledTableRow, CheckButtons }) => {
    const { state, imageHandler, actionTakenHandle, flags } = useContext(EditInapectionContaxt);
    return (
        <>
            {state.vehicleDailyChecks.map((m, i) => (
                <Grid item xs={12} md={12} lg={12} key={i}>
                    <TableContainer component={Paper}>
                        <div
                            style={{
                                border: '1px solid rgba(0,0,0,0.2)'
                            }}
                        >
                            <h3 className={classes.title}>{m.name}</h3>
                        </div>
                        <Table aria-label="simple table" className={classes.paper}>
                            <TableHead>
                                <Hidden only={['md', 'sm', 'xs']}>
                                    <TableRow>
                                        <StyledTableCell width="45%">Check</StyledTableCell>

                                        {flags.displayCustomerDeclaration === 2 && <StyledTableCell width="10%">{flags.startEventLabel}</StyledTableCell>}
                                        <StyledTableCell align="center" width="10%">
                                            {flags.displayCustomerDeclaration === 1
                                                ? flags.startEventLabel
                                                : flags.displayCustomerDeclaration === 2
                                                ? flags.endEventLabel
                                                : 'Result'}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="5%">
                                            Upload
                                        </StyledTableCell>
                                        <StyledTableCell align="left" width={flags.displayStartEvent ? '30%' : '40%'}>
                                            Defect Found
                                        </StyledTableCell>
                                    </TableRow>
                                </Hidden>
                                <Hidden only={['lg', 'xl']}>
                                    <TableRow>
                                        <StyledTableCell width="50%">Check</StyledTableCell>
                                    </TableRow>
                                </Hidden>
                            </TableHead>
                            <TableBody>
                                {m.map((p, i) => (
                                    <>
                                        <Hidden only={['md', 'sm', 'xs']}>
                                            <StyledTableRow
                                                style={{
                                                    backgroundColor:
                                                        p.inspectionSheetCheckResultOptionCode == 'D'
                                                            ? '#f58a88'
                                                            : p.inspectionSheetCheckResultOptionCode == 'M'
                                                            ? '#FFD65C'
                                                            : ''
                                                }}
                                            >
                                                <StyledTableCell component="th">
                                                    {' '}
                                                    <div style={{ fontWeight: 600, color: 'black' }}>{p.inspectionSheetCheckDescription}</div>
                                                </StyledTableCell>
                                                {/* //onhIRE  */}
                                                {flags.displayCustomerDeclaration === 2 && (
                                                    <>
                                                        {!p.startFreeText ? (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {state.buttonList.map((b) => {
                                                                    const Comp = CheckButtons[b.inspectionSheetCheckOptionCode] || ' Button';
                                                                    const checks = p.startResultCode;

                                                                    if (checks === b.inspectionSheetCheckOptionCode) {
                                                                        return (
                                                                            <StyledTableCell align="center">
                                                                                <Comp
                                                                                    state={p.startResultCode === b.inspectionSheetCheckOptionCode}
                                                                                    disabled={true}
                                                                                ></Comp>
                                                                            </StyledTableCell>
                                                                        );
                                                                    }
                                                                })}
                                                                &nbsp;
                                                                <UploadWithBadge
                                                                    onClick={() => imageHandler(p.startInspectionSheetCheckResultID)}
                                                                    count={p.startPhotosUploaded || p.basicCount}
                                                                    state={p.startPhotosUploaded > 0 || p.basicCount > 0}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <StyledTableCell
                                                                align="center"
                                                                colspan={
                                                                    p.inspectionSheetCheckCanHidePhotoUpload
                                                                        ? 2
                                                                        : p.inspectionSheetCheckCanHidePhotoUpload && p.inspectionSheetCheckCanHidePhotoUpload
                                                                        ? 3
                                                                        : 1
                                                                }
                                                            >
                                                                <FormHelperText
                                                                    shrink
                                                                    style={{
                                                                        fontWeight: 600,
                                                                        color: 'black',
                                                                        lineHeight: 1,
                                                                        fontSize: 10,
                                                                        textAlign: 'center'
                                                                    }}
                                                                >
                                                                    {p.startFreeText}
                                                                </FormHelperText>
                                                            </StyledTableCell>
                                                        )}
                                                    </>
                                                )}

                                                {!p.inspectionSheetCheckFreeTextField ? (
                                                    state.buttonList.map((b) => {
                                                        const Comp = CheckButtons[b.inspectionSheetCheckOptionCode] || ' Button';
                                                        const checks = p.inspectionSheetCheckResultOptionCode;

                                                        if (checks === b.inspectionSheetCheckOptionCode) {
                                                            return (
                                                                <StyledTableCell align="center">
                                                                    <Comp
                                                                        state={p.inspectionSheetCheckResultOptionCode === b.inspectionSheetCheckOptionCode}
                                                                        disabled={true}
                                                                    ></Comp>
                                                                </StyledTableCell>
                                                            );
                                                        }
                                                    })
                                                ) : (
                                                    <StyledTableCell
                                                        align="center"
                                                        colspan={
                                                            p.inspectionSheetCheckCanHidePhotoUpload
                                                                ? 2
                                                                : p.inspectionSheetCheckCanHidePhotoUpload && p.inspectionSheetCheckCanHidePhotoUpload
                                                                ? 3
                                                                : 1
                                                        }
                                                    >
                                                        <FormHelperText
                                                            shrink
                                                            style={{ fontWeight: 600, color: 'black', lineHeight: 1, fontSize: 10, textAlign: 'center' }}
                                                        >
                                                            {p.inspectionSheetCheckResultEnteredText || 'N/A'}
                                                        </FormHelperText>
                                                    </StyledTableCell>
                                                )}
                                                {!p.inspectionSheetCheckCanHidePhotoUpload && (
                                                    <StyledTableCell align="center">
                                                        {' '}
                                                        <UploadWithBadge
                                                            onClick={() => imageHandler(p.inspectionSheetCheckResultID)}
                                                            count={p.photosUploaded || p.basicCount}
                                                            state={p.photosUploaded > 0 || p.basicCount > 0}
                                                        />
                                                    </StyledTableCell>
                                                )}

                                                <StyledTableCell align="center">
                                                    {/* {p.inspectionSheetCheckResultOptionCode !== 'S' && p.inspectionSheetCheckResultOptionCode !== 'N' && ( */}
                                                    {!p.inspectionSheetCheckCanHideDefectDescription && (
                                                        <Grid container justify="space-between" alignItems="center" spacing={1}>
                                                            <Grid item>
                                                                {' '}
                                                                <div style={{ fontWeight: 600, color: 'black', fontSize: 12 }}>
                                                                    {p.inspectionSheetCheckResultNotes ? p.inspectionSheetCheckResultNotes : 'N/A'}{' '}
                                                                </div>
                                                            </Grid>
                                                            {p.defectStatusID && (
                                                                <Grid item>
                                                                    <CustomChip
                                                                        icon={EditIcon}
                                                                        iconFontSize={16}
                                                                        label={p.defectStatusID !== 1 ? `${p.resultStatus} by ${p.rectifiedBy}` : 'Action'}
                                                                        onClick={() => actionTakenHandle(p)}
                                                                        className="btn-m"
                                                                        style={{
                                                                            fontWeight: 'bold',
                                                                            backgroundColor: p.defectStatusID && p.rectifiedBy ? `${p.backgroundColour}` : ''
                                                                            // color: 'white'
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    )}
                                                    {/* )} */}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </Hidden>
                                        <Hidden only={['lg', 'xl']}>
                                            <StyledTableRow
                                                style={{
                                                    backgroundColor:
                                                        p.inspectionSheetCheckResultOptionCode == 'D'
                                                            ? '#f58a88'
                                                            : p.inspectionSheetCheckResultOptionCode == 'M'
                                                            ? '#FFD65C'
                                                            : ''
                                                }}
                                            >
                                                <StyledTableCell component="th">
                                                    <Grid container spacing={1}>
                                                        <Grid item md={10} sm={flags.displayCustomerDeclaration === 2 ? 12 : 9} xs={12}>
                                                            {' '}
                                                            {p.inspectionSheetCheckDescription}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            container
                                                            spacing={2}
                                                            md={2}
                                                            sm={flags.displayCustomerDeclaration === 2 ? 12 : 3}
                                                            xs={12}
                                                            alignItems="center"
                                                        >
                                                            <>
                                                                {flags.displayCustomerDeclaration === 2 && (
                                                                    <Grid item xs={12} sm={6} container spacing={2}>
                                                                        <Grid item xs={6} sm={12}>
                                                                            {flags.startEventLabel}
                                                                        </Grid>
                                                                        {!p.startFreeText ? (
                                                                            state.buttonList.map((b) => {
                                                                                const Comp = CheckButtons[b.inspectionSheetCheckOptionCode] || ' Button';
                                                                                const checks = p.startResultCode;

                                                                                if (checks === b.inspectionSheetCheckOptionCode) {
                                                                                    return (
                                                                                        <Grid item align="center">
                                                                                            <Comp
                                                                                                state={p.startResultCode === b.inspectionSheetCheckOptionCode}
                                                                                                disabled={true}
                                                                                            ></Comp>
                                                                                        </Grid>
                                                                                    );
                                                                                }
                                                                            })
                                                                        ) : (
                                                                            <Grid item>
                                                                                <Typography variant="caption" display="block" gutterBottom>
                                                                                    {p.startFreeText}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}
                                                                        <Grid item align="center">
                                                                            {!p.inspectionSheetCheckCanHidePhotoUpload && (
                                                                                <UploadWithBadge
                                                                                    onClick={() => imageHandler(p.inspectionSheetCheckResultID)}
                                                                                    count={p.startPhotosUploaded || p.basicCount}
                                                                                    state={p.startPhotosUploaded > 0 || p.basicCount > 0}
                                                                                />
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                                <Grid item xs={12} sm={flags.displayCustomerDeclaration === 2 ? 6 : 12} container spacing={2}>
                                                                    {flags.displayCustomerDeclaration === 2 && (
                                                                        <Grid item xs={6} sm={12}>
                                                                            {flags.endEventLabel}
                                                                        </Grid>
                                                                    )}
                                                                    {!p.inspectionSheetCheckFreeTextField ? (
                                                                        state.buttonList.map((b) => {
                                                                            const Comp = CheckButtons[b.inspectionSheetCheckOptionCode] || ' Button';
                                                                            const checks = p.inspectionSheetCheckResultOptionCode;

                                                                            if (checks === b.inspectionSheetCheckOptionCode) {
                                                                                return (
                                                                                    <Grid item align="center">
                                                                                        <Comp
                                                                                            state={
                                                                                                p.inspectionSheetCheckResultOptionCode ===
                                                                                                b.inspectionSheetCheckOptionCode
                                                                                            }
                                                                                            disabled={true}
                                                                                        ></Comp>
                                                                                    </Grid>
                                                                                );
                                                                            }
                                                                        })
                                                                    ) : (
                                                                        <Grid item>
                                                                            <Typography variant="caption" display="block" gutterBottom>
                                                                                {p.inspectionSheetCheckResultEnteredText || 'N/A'}
                                                                            </Typography>
                                                                        </Grid>
                                                                    )}
                                                                    <Grid item align="center">
                                                                        {!p.inspectionSheetCheckCanHidePhotoUpload && (
                                                                            <UploadWithBadge
                                                                                onClick={() => imageHandler(p.inspectionSheetCheckResultID)}
                                                                                count={p.photosUploaded || p.basicCount}
                                                                                state={p.photosUploaded > 0 || p.basicCount > 0}
                                                                            />
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        </Grid>
                                                        <Grid item container xs={12}>
                                                            {!p.inspectionSheetCheckCanHideDefectDescription && (
                                                                <Grid item>
                                                                    {/* <InputLabel shrink>Defect</InputLabel> */}
                                                                    <Typography variant="caption" display="block" gutterBottom>
                                                                        {' '}
                                                                        Defect Found:{' '}
                                                                        {p.inspectionSheetCheckResultNotes ? p.inspectionSheetCheckResultNotes : 'N/A'}{' '}
                                                                        {p.defectStatusID && (
                                                                            <CustomChip
                                                                                icon={EditIcon}
                                                                                iconFontSize={16}
                                                                                label={
                                                                                    p.defectStatusID !== 1 ? `${p.resultStatus} by ${p.rectifiedBy}` : 'Action'
                                                                                }
                                                                                onClick={() => actionTakenHandle(p)}
                                                                                className="btn-m"
                                                                                style={{
                                                                                    fontWeight: 'bold',
                                                                                    backgroundColor:
                                                                                        p.defectStatusID && p.rectifiedBy ? `${p.backgroundColour}` : ''
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Typography>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </Hidden>
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            ))}
        </>
    );
};
