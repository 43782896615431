import React, { useState } from 'react';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { FormHelperText, Grid, Typography, InputLabel } from '@material-ui/core';
import { SecondaryButton, DatePicker, InputText, MandatoryField, formatters } from '../../../../../Core/Controls';
import { JobsSubmitcost } from '../../../../../Core/Services/InternalUser/JOB/jobQueryService';

const SubmitEstimateModal = (props) => {
    const [state, setState] = useState({
        jobQueryMessage: '',
        errors: {},
        odometerReading: props.jobMileage,
        jobMileageDate: formatters.DateFormatter(props.jobMileageDate)
    });
    const [btnLoader, setBtnLoader] = useState(false);

    const { showToastSuccess, showToastError } = useToast();

    const validations = () => {
        const { jobMileageDate, odometerReading, jobQueryMessage } = state;
        let formIsValid = true;
        let errors = {};
        if (props.mileageIsRequired) {
            if (!jobMileageDate) {
                errors.jobMileageDate = 'Mileage date is required';
                formIsValid = false;
            }
            if (!odometerReading) {
                errors.odometerReading = 'Mileage is required';
                formIsValid = false;
            }
            if (props.jobStatus == 'JQ' && !jobQueryMessage) {
                errors.jobQueryMessage = 'Message is required';
                formIsValid = false;
            }
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const handleData = async () => {
        if (validations()) {
            try {
                setBtnLoader(true);
                let data = {
                    jobID: props.jobQueryJobID,
                    notes: state.jobQueryMessage,
                    mileageDate: state.jobMileageDate,
                    odometerReading: state.odometerReading
                };
                let res = await JobsSubmitcost(data);
                if (res.success) {
                    showToastSuccess('Job saved sucessfully');
                    setBtnLoader(false);
                    props.onClose(true);
                } else {
                    showToastError(res.message);
                    setBtnLoader(false);
                }
            } catch (error) {
                setBtnLoader(false);
                showToastError(`An error occurred: ${error.message}`);
            }
        }
    };

    const inputChange = (ev) => {
        let temp = ev.target;
        setState((st) => ({
            ...st,
            [temp.name]: temp.value
        }));
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <FormHelperText shrink>Order Date</FormHelperText>
                <Typography variant="button">{props.jobOrderDate}</Typography>
            </Grid>
            <Grid item xs={6}>
                <FormHelperText shrink>Job Reference</FormHelperText>
                <Typography variant="button"> {props.jobAgentOrderNo}</Typography>
            </Grid>

            <Grid item xs={6}>
                <FormHelperText>Order Total</FormHelperText>
                <Typography variant="button">£{props.jobOrderTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Typography>
            </Grid>

            <Grid item xs={6}>
                <FormHelperText shrink>Reg Number</FormHelperText>
                <Typography variant="button">{props.jobRegNumber}</Typography>
            </Grid>
            <Grid item xs={12} className={props.mileageIsRequired ? 'mandatory-fields' : ''}>
                <InputLabel required shrink>
                    Job Mileage Date
                </InputLabel>
                <DatePicker
                    inputProps={{
                        max: new Date().toISOString().slice(0, 10)
                    }}
                    value={state.jobMileageDate}
                    name="jobMileageDate"
                    onChange={inputChange}
                />
                <FormHelperText error>{state.errors.jobMileageDate}</FormHelperText>
            </Grid>
            <Grid item xs={12} className={props.mileageIsRequired ? 'mandatory-fields' : ''}>
                <MandatoryField
                    inputLabel="Job Mileage"
                    errors={state.errors.odometerReading}
                    type="number"
                    value={state.odometerReading}
                    name="odometerReading"
                    onChange={inputChange}
                />
            </Grid>
            <Grid item xs={12} className={props.jobStatus == 'JQ' ? 'mandatory-fields' : ''}>
                <InputLabel shrink>Message</InputLabel>
                <InputText name="jobQueryMessage" rows={2} multiline onChange={inputChange} />
                <FormHelperText error>{state.errors.jobQueryMessage}</FormHelperText>
            </Grid>
            <Grid item xs={12} className="submit_btn">
                <SecondaryButton isBtnLoader={btnLoader} onClick={handleData}>
                    {props.title || 'submit'}
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default SubmitEstimateModal;
