import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useLoginInfo } from '../../../../../../Core/Hooks/useLoginInfo';
import { useWindowSize } from '../../../../../../Core/Hooks/useWindowSize';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import moment from 'moment';
import { AppStorage } from '../../../../../../Core/Basic/storage-service';
import { getTitleData, getVehicleById } from '../../../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import {
    deleteImageByID,
    getChecksAddTabByID,
    getChecksEditByID,
    getImageByID,
    getTyreCheckData,
    postChecksData,
    PostImage
} from '../../../../../../Core/Services/InternalUser/Vehicle/vehicleTabChecks';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { usePortalSettings } from '../../../../../../Core/Hooks/usePortalSettings';

export const InspectionCheCkContaxt = createContext();

export const InspectionCheckProvider = (props) => {
    const portalSettings = usePortalSettings();
    const { displayName } = useLoginInfo();
    const [btnLoader, setBtnLoader] = useState(false);
    const [imgLoader, setImgLoader] = useState(false);

    const [state, setState] = useState({
        checksList: [],
        buttonList: [],
        passVal: false,
        imageUpload: [],
        date: moment().format('YYYY-MM-DD'),
        inspectionSheetAxlesVM: [],
        VehicleDailyCheckResultsVM: [],
        vehicleInspectionID: props.checkID || null,
        vehicleInspectionScheduleID: props.sheduleID,
        vehicleDailyCheckCheckerName: displayName,
        vehicleInspectionStatusID: null,
        vehicleInspectionDateInspected: moment().format('YYYY-MM-DD'),
        vehicleInspectionResult: null,
        vehicleInspectionSignedOffComment: null,
        vehicleInspectionSignedOffName: null,
        vehicleInspectionSignedOffSignature: null,
        vehicleInspectionSignedOffDate: null,
        vehicleInspectionSignedOffIPAddress: null,
        loader: true,
        signAvailable: AppStorage.getSignature() === 'absent' ? false : true,
        windowWidth: window.innerWidth,
        axleErrors: {},
        showTost: false,
        showConfirmMessage: false,
        vehicleNoOfAxles: null,
        selectedRowInspectionID: null,
        openDefectInspection: false,
        inspectionName: '',
        isDisabled: false,
        getImageList: [],
        inspectionSheetBrakeLiningsVM: [],
        showSubmit: false
    });
    const history = useHistory();
    let checkhaveAccess = AppStorage.getAllowAcces();

    const [flags, setFlags] = useState({});
    const [widths, heights] = useWindowSize();
    const { showToastSuccess, showToastError } = useToast();

    const handleInpectionRow = (id, name) => {
        setState((st) => ({ ...st, selectedRowInspectionID: id, openDefectInspection: true, inspectionName: name }));
    };

    const closeInspection = () => {
        setState((st) => ({ ...st, selectedRowInspectionID: null, openDefectInspection: false, inspectionName: '' }));
    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        if (name === 'vehicleInspectionBrakeTestTypeID') {
            setState((state) => ({ ...state, [name]: +value }));
        } else {
            setState((state) => ({ ...state, [name]: value }));
        }
    };

    useEffect(() => {
        async function FetchData() {
            try {
                if (props.sheduleVechicleID) {
                    let vehicleInformation = await getTitleData(props.sheduleVechicleID);

                    if (vehicleInformation.success) {
                        setState((st) => ({
                            ...st,
                            vehicleInfo: vehicleInformation.data.details[0]?.vehicleDescription,
                            vehicleNextCalibrationDate: vehicleInformation.data.details[0]?.vehicleNextCalibrationDate,
                            vehicleNextTachoCalibrationDate: vehicleInformation.data.details[0]?.vehicleNextTachoCalibrationDate,
                            vehicleNextLolerTestDate: vehicleInformation.data.details[0]?.vehicleNextLolerTestDate,
                            vehicleNoOfAxles: vehicleInformation?.data?.details[0]?.vehicleNoOfAxles,
                            customerCompanyName: vehicleInformation?.data?.details[0]?.customerCompanyName
                        }));
                    }
                    let res2 = await getVehicleById(props.sheduleVechicleID);
                    if (res2.success) {
                        const data = res2.data;
                        setState((st) => ({
                            ...st,
                            regNumber: data.vehicleRegNumber,
                            modelID: data.vehicleModel,
                            makeID: data.vehicleMake
                        }));
                    }

                    // pullInpectionChecks();
                }
            } catch (error) {
                console.error(error);
            }
        }

        return FetchData();
    }, [props.sheduleVechicleID, props.displayCheck, props.scheduleEventID, props.sheduleID]);

    //Add Inspection
    let pullInpectionChecks = async () => {
        let res = await getChecksAddTabByID(props.sheduleVechicleID, props.displayCheck, props.scheduleEventID, props.sheduleID);

        if (res.success) {
            let tempArray = [];
            res?.data?.inspectionSheetSectionsVM?.map((q) => {
                q.inspectionSheetChecksVM?.map((r) => {
                    r.inspectionSheetCheckResultCheckID = r.inspectionSheetCheckID;
                    r.inspectionSheetCheckResultOptionCode = null;
                    r.inspectionSheetCheckResultNotes = null;
                    r.inspectionSheetCheckResultIMReference = null;
                    r.inspectionSheetCheckResultDefectID = null;
                    tempArray.push(r);
                });
            });

            let inspectionSheetBrakeLiningsVM = res?.data?.inspectionSheetBrakeLiningsVM;

            let flagData = res?.data?.inspectionSheetTypesVM[0] || {};

            setFlags((prevFlag) => ({
                ...prevFlag,
                displayBrakePerformance: flagData.displayBrakePerformance,
                displayBrakeTest: flagData.displayBrakeTest,
                displayMileage: flagData.displayMileage,
                displayRoadTest: flagData.displayRoadTest,
                displayTyres: flagData.displayTyres,
                endEventLabel: flagData.endEventLabel,
                startEventLabel: flagData.startEventLabel,
                displayStartEvent: flagData.displayStartEvent,
                displayCustomerDeclaration: flagData.displayCustomerDeclaration,
                scheduleEventName: flagData.scheduleEventName
            }));

            setState((st) => ({
                ...st,
                checksList: res?.data?.inspectionSheetSectionsVM || [],
                buttonList: res.data?.inspectionSheetCheckOptionsVM || [],
                VehicleDailyCheckResultsVM: tempArray || [],
                loader: false,
                inspectionSheetBrakeLiningsVM: inspectionSheetBrakeLiningsVM || []
            }));
        }
    };

    console.log(state, '<<<<<<<<<<<<<state');
    //Edit Inspection
    let pullEditInpectionChecks = async () => {
        if (props.checkID) {
            let res = await getChecksEditByID(props.checkID, props.displayCheck);

            if (res.success) {
                res.data.vehicleDailyChecks.map((item) => {
                    if (item.vehicleInspectionSignedOffSignature) {
                        setState((st) => ({
                            ...st,
                            showbtn: false
                        }));
                    }
                    setState((st) => ({
                        ...st,
                        vehicleID: item.vehicleID,
                        vehicleRegNumber: item.vehicleRegNumber,
                        date: moment(item.vehicleInspectionDateInspected).format('YYYY-MM-DD'),
                        makeID: item.vehicleMake,
                        modelID: item.vehicleModel,
                        imgtechniciansign: item.vehicleInspectionInspectorSignature,
                        techniciansign: item.vehicleInspectionInspectorSignature,
                        vehicleInspectionScheduleID: item.vehicleInspectionScheduleID,
                        vehicleInspectionStatusID: item.vehicleInspectionStatusID,
                        vehicleInspectionDateInspected: moment(item.vehicleInspectionDateInspected).format('YYYY-MM-DD hh:mm'),
                        vehicleInspectionResult: item.vehicleInspectionResult,
                        vehicleDailyCheckMileage: item.vehicleInspectionMileage,
                        vehicleInspectionSlippageReasonID: item.vehicleInspectionSlippageReasonID,
                        vehicleDailyCheckCheckerName: item.vehicleInspectionInspectorName,
                        vehicleInspectionInspectionComment: item.vehicleInspectionInspectionComment,
                        vehicleInspectionSignedOffName: item.vehicleInspectionSignedOffName,
                        vehicleInspectionSignedOffSignature: item.vehicleInspectionSignedOffSignature,
                        signOffsignature: item.vehicleInspectionSignedOffSignature,
                        hideSignOff: item.vehicleInspectionSignedOffSignature,
                        vehicleInspectionSignedOffDate: item.vehicleInspectionSignedOffDate,
                        vehicleInspectionSignedOffIPAddress: item.vehicleInspectionSignedOffIPAddress,
                        vehicleInspectionID: item.vehicleInspectionID,
                        vehicleInspectionSignedOffComment: item.vehicleInspectionSignedOffComment,

                        vehicleInspectionBrakeTestTypeID: item.vehicleInspectionBrakeTestTypeID, // 2,
                        vehicleInspectionBrakeTestNumber: item.vehicleInspectionBrakeTestNumber, // "12345",
                        vehicleInspectionRoadCondition: item.vehicleInspectionRoadCondition, // "Test Road Conditions",
                        vehicleInspectionServiceBrakePerformance: item.vehicleInspectionServiceBrakePerformance, // 71,
                        vehicleInspectionSecondaryBrakePerformance: item.vehicleInspectionSecondaryBrakePerformance, // 72,
                        vehicleInspectionParkingBrakePerformance: item.vehicleInspectionParkingBrakePerformance, // 73,
                        vehicleInspectionBrakeTestPhoto: item.vehicleInspectionBrakeTestPhoto
                    }));
                });

                // let TempData = [...res.data.vehicleDailyChecksResult];
                let flagData = res.data.inspectionSheetTypesVM[0];

                let tempArray = res.data.vehicleDailyChecksResult?.map((q) => ({
                    ...q,
                    inspectionSheetCheckResultCheckID: q.inspectionSheetCheckID,
                    inspectionSheetCheckResultOptionCode: q.inspectionSheetCheckResultOptionCode || null,
                    inspectionSheetCheckResultNotes: q.inspectionSheetCheckResultNotes || null,
                    inspectionSheetCheckResultIMReference: q.inspectionSheetCheckResultIMReference || null,
                    inspectionSheetCheckResultDefectID: q.inspectionSheetCheckResultDefectID || null
                }));

                let inspectionSheetBrakeLiningsVM = res?.data?.inspectionSheetBrakeLiningsVM;

                const axles = [1, 2, 3, 4].map((num) => res?.data?.tyreAxles.find((x) => x.axleNumber === num) || { axleNumber: num });

                setFlags((prevFlag) => ({
                    ...prevFlag,
                    displayBrakePerformance: flagData.displayBrakePerformance,
                    displayBrakeTest: flagData.displayBrakeTest,
                    displayMileage: flagData.displayMileage,
                    displayRoadTest: flagData.displayRoadTest,
                    displayTyres: flagData.displayTyres,
                    endEventLabel: flagData.endEventLabel,
                    startEventLabel: flagData.startEventLabel,
                    displayStartEvent: flagData.displayStartEvent,
                    displayCustomerDeclaration: flagData.displayCustomerDeclaration
                }));
                setState((st) => ({
                    ...st,
                    inspectionSheetAxlesVM: axles,
                    loader: false,
                    buttonList: res.data?.vehicleDailyCheckOptions,
                    VehicleDailyCheckResultsVM: tempArray,
                    inspectionSheetBrakeLiningsVM: inspectionSheetBrakeLiningsVM || []
                }));
            }
        }
    };

    useEffect(() => {
        if (props.checkID) {
            pullEditInpectionChecks();
        } else {
            pullInpectionChecks();
        }
    }, [props.sheduleVechicleID, props.displayCheck, props.scheduleEventID, props.sheduleID, props.checkID]);

    useEffect(() => {
        if (props.checkID) {
            if (state.VehicleDailyCheckResultsVM.length > 0) {
                let checksList = state.VehicleDailyCheckResultsVM.map((m, i) => {
                    return {
                        inspectionSheetSectionDescription: m.inspectionSheetSectionDescription,
                        inspectionSheetChecksVM: state.VehicleDailyCheckResultsVM.filter(
                            (p) => m.inspectionSheetSectionDescription === p.inspectionSheetSectionDescription
                        ).map((list) => ({
                            ...list,
                            inspectionSheetCheckCanHideDefectDescription: list.inspectionSheetCheckCanHideDefectDescription,
                            inspectionSheetCheckCanHidePhotoUpload: list.inspectionSheetCheckCanHidePhotoUpload,
                            inspectionSheetCheckDescription: list.inspectionSheetCheckDescription,
                            inspectionSheetCheckFreeTextField: list.inspectionSheetCheckFreeTextField,
                            inspectionSheetCheckID: list.inspectionSheetCheckID,
                            inspectionSheetCheckResultOptionCode: list.inspectionSheetCheckResultOptionCode || '',
                            previousNotes: '',
                            previousResultCode: '',
                            previousVehicleInspectionID: 0,
                            startFreeText: list.startFreeText,
                            startInspectionSheetCheckResultID: list.startInspectionSheetCheckResultID,
                            startNotes: list.startNotes,
                            startPhotosUploaded: list.startPhotosUploaded,
                            startResultCode: list.startResultCode,
                            startVehicleInspectionID: list.startVehicleInspectionID,
                            inspectionSheetCheckResultNotes: list.inspectionSheetCheckResultNotes
                        }))
                    };
                });

                let vehicleChecksList = Object.values(
                    checksList.reduce((acc, cur) => Object.assign(acc, { [cur.inspectionSheetSectionDescription]: cur }), {})
                );
                setState((st) => ({ ...st, checksList: vehicleChecksList }));
            }
        }
    }, [state.VehicleDailyCheckResultsVM, props.checkID]);

    let getPreviousTyreData = async () => {
        let res = await getTyreCheckData(props.sheduleVechicleID);
        if (res.success) {
            if (res.data.previousAxle.length > 0) {
                setState((st) => ({ ...st, inspectionSheetAxlesVM: [...res.data.previousAxle] }));
                showToastSuccess('Data Added Successfully');
            } else {
                showToastError('No Record Found');
            }
        } else {
            showToastError(res.data.message);
        }
    };

    //Signature

    const signCanvas = useRef({});

    const clear = () => {
        if (state.imgtechniciansign) {
            setState((st) => ({
                ...st,
                imgtechniciansign: false,
                techniciansign: null
            }));
        } else {
            signCanvas.current.clear();
        }
    };

    const GetTechnicianSign = () => {
        setState((st) => {
            const nst = {
                ...st,
                techniciansign: signCanvas.current.toDataURL()
            };
            return nst;
        });
    };

    const useDefaultSig = () => {
        setState((st) => ({ ...st, imgtechniciansign: true, techniciansign: AppStorage.getSignature() }));
    };

    //Handle Inspections

    const changeState = useCallback(
        (buttonVal, checkId) => {
            console.log(buttonVal, checkId);
            let changedArray = [...state.VehicleDailyCheckResultsVM];
            let objIndex = state.VehicleDailyCheckResultsVM.findIndex((x) => x.inspectionSheetCheckID === checkId);
            if (objIndex > -1) {
                changedArray[objIndex].inspectionSheetCheckResultOptionCode = buttonVal;
                setState((st) => ({ ...st, VehicleDailyCheckResultsVM: changedArray }));
            }
        },
        [state.VehicleDailyCheckResultsVM]
    );

    const changeNotesState = useCallback(
        (id, des) => (e) => {
            let changedArray = [...state.VehicleDailyCheckResultsVM];
            let objIndex = state.VehicleDailyCheckResultsVM.findIndex((x) => x.inspectionSheetCheckID === id);
            changedArray[objIndex].inspectionSheetCheckResultNotes = e.target.value;
            setState((st) => ({ ...st, VehicleDailyCheckResultsVM: changedArray }));
        },
        [state.VehicleDailyCheckResultsVM]
    );
    const changeResutNotesState = useCallback(
        (id, des) => (e) => {
            let changedArray = [...state.VehicleDailyCheckResultsVM];
            let objIndex = state.VehicleDailyCheckResultsVM.findIndex((x) => x.inspectionSheetCheckID === id);
            changedArray[objIndex].inspectionSheetCheckResultEnteredText = e.target.value;
            setState((st) => ({ ...st, VehicleDailyCheckResultsVM: changedArray }));
        },
        [state.VehicleDailyCheckResultsVM]
    );

    const changeAllChildChecks = (buttonId, condition, groupLabel) => () => {
        const grp = state.checksList.find((l) => l.inspectionSheetSectionDescription === groupLabel);
        if (grp.state === true) {
            grp.state = false;
        } else {
            grp.state = condition;
        }
        if (grp) {
            const sheetsCheckIds = grp.inspectionSheetChecksVM.map((p) => p.inspectionSheetCheckID);
            setState((st) => {
                const newSt = { ...st };
                sheetsCheckIds.forEach((id) => {
                    const sheetsCheck = newSt.VehicleDailyCheckResultsVM.find((c) => c.inspectionSheetCheckID === id);
                    if (sheetsCheck) {
                        sheetsCheck.inspectionSheetCheckResultOptionCode = grp.state ? buttonId : null;
                        newSt[id] = grp.state ? buttonId : null;
                    }
                });
                return { ...newSt, VehicleDailyCheckResultsVM: [...newSt.VehicleDailyCheckResultsVM] };
            });
        }
    };

    //Handle Inspection tyress --> Copy Data

    const handleInspection = () => {
        const mergeAxles = (axles) => {
            return axles.reduce((acc, curr) => {
                const existingAxleIndex = acc.findIndex((item) => item.axleNumber === curr.axleNumber);
                if (existingAxleIndex !== -1) {
                    // Merge properties without overriding
                    acc[existingAxleIndex] = { ...acc[existingAxleIndex], ...curr };
                } else {
                    // Add new axle
                    acc.push(curr);
                }
                return acc;
            }, []);
        };

        let value = state.inspectionSheetAxlesVM[0]?.osTyreMaker || 'AS';
        let depth = state.inspectionSheetAxlesVM[0]?.osTreadDepth || null;
        let pressure = state.inspectionSheetAxlesVM[0]?.osTyrePressure || null;
        let breaks = state.inspectionSheetAxlesVM[0]?.osBrakeLineRemaining || null;

        let axle = [
            {
                axleNumber: 1,
                osTyreMaker: value,
                nsTyreMaker: value,
                nsTreadDepth: depth,
                osTreadDepth: depth,
                nsTyrePressure: pressure,
                nsBrakeLineRemaining: breaks,
                osTyrePressure: pressure,
                osBrakeLineRemaining: breaks
            },
            {
                axleNumber: 2,
                osTyreMaker: value,
                osFrontTyreMaker: value,
                nsFrontTyreMaker: value,
                nsTyreMaker: value,
                nsFrontTreadDepth: depth,
                nsTreadDepth: depth,
                osFrontTreadDepth: depth,
                osTreadDepth: depth,
                nsFrontTyrePressure: pressure,
                nsTyrePressure: pressure,
                nsBrakeLineRemaining: breaks,
                osFrontTyrePressure: pressure,
                osTyrePressure: pressure,
                osBrakeLineRemaining: breaks
            }
        ];
        if (state.vehicleNoOfAxles === 3) {
            axle.push({
                axleNumber: 3,
                osTyreMaker: value,
                osFrontTyreMaker: value,
                nsFrontTyreMaker: value,
                nsTyreMaker: value,
                nsFrontTreadDepth: depth,
                nsTreadDepth: depth,
                osFrontTreadDepth: depth,
                osTreadDepth: depth,
                nsFrontTyrePressure: pressure,
                nsTyrePressure: pressure,
                nsBrakeLineRemaining: breaks,
                osFrontTyrePressure: pressure,
                osTyrePressure: pressure,
                osBrakeLineRemaining: breaks
            });
        }

        if (state.vehicleNoOfAxles === 4) {
            axle.push(
                {
                    axleNumber: 3,
                    osTyreMaker: value,
                    osFrontTyreMaker: value,
                    nsFrontTyreMaker: value,
                    nsTyreMaker: value,
                    nsFrontTreadDepth: depth,
                    nsTreadDepth: depth,
                    osFrontTreadDepth: depth,
                    osTreadDepth: depth,
                    nsFrontTyrePressure: pressure,
                    nsTyrePressure: pressure,
                    nsBrakeLineRemaining: breaks,
                    osFrontTyrePressure: pressure,
                    osTyrePressure: pressure,
                    osBrakeLineRemaining: breaks
                },
                {
                    axleNumber: 4,
                    osTyreMaker: value,
                    osFrontTyreMaker: value,
                    nsFrontTyreMaker: value,
                    nsTyreMaker: value,
                    nsFrontTreadDepth: depth,
                    nsTreadDepth: depth,
                    osFrontTreadDepth: depth,
                    osTreadDepth: depth,
                    nsFrontTyrePressure: pressure,
                    nsTyrePressure: pressure,
                    nsBrakeLineRemaining: breaks,
                    osFrontTyrePressure: pressure,
                    osTyrePressure: pressure,
                    osBrakeLineRemaining: breaks
                }
            );
        }
        setState((st) => {
            return {
                ...st,
                inspectionSheetAxlesVM: mergeAxles([...st.inspectionSheetAxlesVM, ...axle])
            };
        });
    };

    const handleInputs = (axelNo) => (e) => {
        let showError = {};
        const { name, value } = e.target;
        let showTost = false;
        setState((st) => {
            let ax = st.inspectionSheetAxlesVM.find((a) => a.axleNumber === axelNo);
            if (!ax) {
                ax = { axleNumber: axelNo };
                st.inspectionSheetAxlesVM.push(ax);
            }
            ax[name] = value;
            switch (name) {
                case 'osTreadDepth':
                    showError.message = (value > 0 && value > 20) || value < 0 ? `Axle ${axelNo} ${name}  must be Smaller than 20 and greater than 0` : null;
                    showTost = true;
                    break;
                case 'osFrontTreadDepth':
                    showError.message = (value > 0 && value > 20) || value < 0 ? `Axle ${axelNo} ${name}  must be Smaller than 20 and greater than 0` : null;
                    showTost = true;
                    break;
                case 'nsTreadDepth':
                    showError.message = (value > 0 && value > 20) || value < 0 ? `Axle ${axelNo} ${name}  must be Smaller than 20 and greater than 0` : null;
                    showTost = true;
                    break;
                case 'nsFrontTreadDepth':
                    showError.message = (value > 0 && value > 20) || value < 0 ? `Axle ${axelNo} ${name}  must be Smaller than 20 and greater than 0` : null;
                    showTost = true;
                    break;
                case 'osTyrePressure':
                    showError.message = (value > 0 && value > 130) || value < 0 ? `Axle ${axelNo} ${name}  must be Smaller than 130 and greater than 0` : null;
                    showTost = true;
                    break;
                case 'osFrontTyrePressure':
                    showError.message = (value > 0 && value > 130) || value < 0 ? `Axle ${axelNo} ${name}  must be Smaller than 130 and greater than 0` : null;
                    showTost = true;
                    break;
                case 'nsTyrePressure':
                    showError.message = (value > 0 && value > 130) || value < 0 ? `Axle ${axelNo} ${name}  must be Smaller than 130 and greater than 0` : null;
                    showTost = true;
                    break;
                case 'nsFrontTyrePressure':
                    showError.message = (value > 0 && value > 130) || value < 0 ? `Axle ${axelNo} ${name}  must be Smaller than 130 and greater than 0` : null;
                    showTost = true;
                    break;
                case 'nsBrakeLineRemaining':
                    showError.message = (value > 0 && value > 100) || value < 0 ? `Axle ${axelNo} ${name}  must be Smaller than 100 and greater than 0` : null;
                    showTost = true;
                    break;
                case 'osBrakeLineRemaining':
                    showError.message = (value > 0 && value > 100) || value < 0 ? `Axle ${axelNo} ${name}  must be Smaller than 100 and greater than 0` : null;
                    showTost = true;
                    break;

                default:
                    showError.message = null;
                    showTost = false;
                    break;
            }

            return { ...st, inspectionSheetAxlesVM: [...st.inspectionSheetAxlesVM], axleErrors: showError, showTost: showTost };
        });
    };

    //Submit Data...........

    const validations = () => {
        let formIsValid = true;
        let errors = {};
        let errorMessage = '';
        let checksOption = state.VehicleDailyCheckResultsVM.filter((x) => {
            return x.inspectionSheetCheckResultOptionCode === null;
        });

        let checksNotes = state.VehicleDailyCheckResultsVM.filter((x) => {
            return (
                (x.inspectionSheetCheckResultOptionCode === 'D' && x.inspectionSheetCheckResultNotes == null) ||
                (x.inspectionSheetCheckResultOptionCode === 'M' && x.inspectionSheetCheckResultNotes == null)
            );
        });
        let axel1Validation = state.inspectionSheetAxlesVM.find((a) => a.axleNumber === 1);
        let axel2Validation = state.inspectionSheetAxlesVM.find((a) => a.axleNumber === 2);
        let axel3Validation = state.inspectionSheetAxlesVM.find((a) => a.axleNumber === 3);
        let axel4Validation = state.inspectionSheetAxlesVM.find((a) => a.axleNumber === 4);
        let Axeloneval = 1;
        let Axeltwoval = 1;
        let AxelThreeVal = 1;
        let AxelFourVal = 1;

        if (axel1Validation) {
            Axeloneval = Object.keys(axel1Validation).length;
        }
        if (axel2Validation) {
            Axeltwoval = Object.keys(axel2Validation).length;
        }

        if (axel3Validation) {
            AxelThreeVal = Object.keys(axel3Validation).length;
        }

        if (axel4Validation) {
            AxelFourVal = Object.keys(axel4Validation).length;
        }

        if (flags.displayMileage) {
            if (!state.vehicleDailyCheckMileage) {
                errors.vehicleDailyCheckMileage = `Odometer Reading  is required`;
                errorMessage = errorMessage.concat(errors.vehicleDailyCheckMileage) + '\n';
                formIsValid = false;
            }
        }
        // if (checksOption.length > 0) {
        //     errors.message = 'This field is required';
        //     formIsValid = false;
        // }
        if (flags.displayTyres) {
            if (state.vehicleNoOfAxles) {
                if (state.vehicleNoOfAxles === 1) {
                    if (Axeloneval <= 6) {
                        errors.axelone = 'Axel 1 fields are required';
                        errorMessage = errorMessage.concat(errors.axelone) + '\n';
                        formIsValid = false;
                    }
                }
                if (state.vehicleNoOfAxles === 2) {
                    if (Axeloneval <= 6) {
                        errors.axelone = 'Axel 1 fields are required';
                        errorMessage = errorMessage.concat(errors.axelone) + '\n';
                        formIsValid = false;
                    }

                    if (Axeltwoval <= 10) {
                        errors.axeltwo = 'Axel 2 fields are required';
                        errorMessage = errorMessage.concat(errors.axeltwo) + '\n';
                        formIsValid = false;
                    }
                }
                if (state.vehicleNoOfAxles === 3) {
                    if (Axeloneval <= 6) {
                        errors.axelone = 'Axel 1 fields are required';
                        errorMessage = errorMessage.concat(errors.axelone) + '\n';
                        formIsValid = false;
                    }

                    if (Axeltwoval <= 10) {
                        errors.axeltwo = 'Axel 2 fields are required';
                        errorMessage = errorMessage.concat(errors.axeltwo) + '\n';
                        formIsValid = false;
                    }
                    if (AxelThreeVal <= 10) {
                        errors.axelthree = 'Axel 3 fields are required';
                        errorMessage = errorMessage.concat(errors.axelthree) + '\n';
                        formIsValid = false;
                    }
                }
                if (state.vehicleNoOfAxles === 4) {
                    if (Axeloneval <= 6) {
                        errors.axelone = 'Axel 1 fields are required';
                        errorMessage = errorMessage.concat(errors.axelone) + '\n';
                        formIsValid = false;
                    }

                    if (Axeltwoval <= 10) {
                        errors.axeltwo = 'Axel 2 fields are required';
                        errorMessage = errorMessage.concat(errors.axeltwo) + '\n';
                        formIsValid = false;
                    }
                    if (AxelThreeVal <= 10) {
                        errors.axelthree = 'Axel 3 fields are required';
                        errorMessage = errorMessage.concat(errors.axelthree) + '\n';
                        formIsValid = false;
                    }
                    if (AxelFourVal <= 10) {
                        errors.axelfour = 'Axel 4 fields are required';
                        errorMessage = errorMessage.concat(errors.axelfour) + '\n';
                        formIsValid = false;
                    }
                }
            } else {
                if (Axeloneval <= 6) {
                    errors.axelone = 'Axel 1 fields are required';
                    errorMessage = errorMessage.concat(errors.axelone) + '\n';
                    formIsValid = false;
                }

                if (Axeltwoval <= 10) {
                    errors.axeltwo = 'Axel 2 fields are required';
                    errorMessage = errorMessage.concat(errors.axeltwo) + '\n';
                    formIsValid = false;
                }
            }
        }

        // if (checksNotes.length > 0) {
        //     errors.note = 'Note is required';
        //     formIsValid = false;
        // }

        if (!state.techniciansign) {
            errors.techniciansign = 'Signature is required';
            errorMessage = errorMessage.concat(errors.techniciansign) + '\n';
            formIsValid = false;
        }
        // if (!state.vehicleInspectionInspectionComment) {
        //     errors.vehicleInspectionInspectionComment = 'Vehicle checker Comment is required';
        //     formIsValid = false;
        // }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        // return true;
        if (errorMessage) {
            alert(errorMessage);
        }
        return formIsValid;
    };

    const handleConfirmPopup = () => {
        if (validations()) {
            setBtnLoader(true);
            setState((st) => ({
                ...st,
                showConfirmMessage: !st.showConfirmMessage
            }));
            setBtnLoader(false);
        }
    };

    const submitData = async (status) => {
        let resultVM = state.VehicleDailyCheckResultsVM.map((e) => ({
            inspectionSheetCheckResultCheckID: e.inspectionSheetCheckResultCheckID,
            inspectionSheetCheckResultOptionCode: e.inspectionSheetCheckResultOptionCode,
            inspectionSheetCheckResultNotes: e.inspectionSheetCheckResultNotes,
            inspectionSheetCheckResultIMReference: e.inspectionSheetCheckResultIMReference,
            inspectionSheetCheckResultDefectID: e.inspectionSheetCheckResultDefectID,
            inspectionSheetCheckResultEnteredText: e.inspectionSheetCheckResultEnteredText
        }));

        let data = {
            vehicleInspectionScheduleID: state.vehicleInspectionScheduleID,
            vehicleInspectionStatusID: status,
            vehicleInspectionDateInspected: state.date,
            vehicleInspectionResult: state.vehicleInspectionResult,
            vehicleInspectionMileage: state.vehicleDailyCheckMileage,
            vehicleInspectionInspectorName: state.vehicleDailyCheckCheckerName,
            vehicleInspectionInspectorSignature: state.techniciansign,
            vehicleInspectionInspectionComment: state.vehicleInspectionInspectionComment,
            vehicleInspectionSignedOffName: state.vehicleInspectionSignedOffName,
            vehicleInspectionSignedOffSignature: state.vehicleInspectionSignedOffSignature,
            vehicleInspectionSignedOffDate: state.vehicleInspectionSignedOffDate,
            vehicleInspectionSignedOffIPAddress: state.vehicleInspectionSignedOffIPAddress,
            vehicleInspectionBrakeTestTypeID: state.vehicleInspectionBrakeTestTypeID,
            vehicleInspectionBrakeTestNumber: state.vehicleInspectionBrakeTestNumber,
            vehicleInspectionRoadCondition: state.vehicleInspectionRoadCondition,
            vehicleInspectionServiceBrakePerformance: state.vehicleInspectionServiceBrakePerformance,
            vehicleInspectionSecondaryBrakePerformance: state.vehicleInspectionSecondaryBrakePerformance,
            vehicleInspectionParkingBrakePerformance: state.vehicleInspectionParkingBrakePerformance,
            vehicleInspectionID: state.vehicleInspectionID,
            vehicleInspectionSignedOffComment: state.vehicleInspectionSignedOffComment,
            inspectionSheetCheckResultsVM: resultVM,
            inspectionSheetAxlesVM: state.inspectionSheetAxlesVM,
            inspectionSheetCheckPhotoVM: state.imageUpload,
            vehicleInspectionBrakeTestPhoto: state.vehicleInspectionBrakeTestPhoto || ''
        };
        try {
            setBtnLoader(true);
            let res = await postChecksData(data);
            if (res.success) {
                showToastSuccess('Vehicle Checks added successfully');
                if (checkhaveAccess?.CanViewJobs && res?.data?.jobID) {
                    history.push({
                        pathname: `/controller/jobTabScreen/${res.data?.jobID}/${props.sheduleVechicleID}`,
                        state: 'jobs',
                        search: 'tab_testing_tabs=1'
                    });
                    props.onClose(true);
                } else {
                    props.onClose(true);
                }
                setBtnLoader(false);
            } else {
                showToastError(res.message);
                setBtnLoader(false);
            }
        } catch (error) {
            setBtnLoader(false);
            showToastError(`An error occurred: ${error.message}`);
        }
    };

    //Image Handler
    //     if (hideImg && hideDefect) {
    //         colSpan = 3;
    //     } else if (hideDefect || hideImg) {
    //         colSpan = hideDefect ? 1 : 2;
    //     } else {
    //         colSpan = 1;
    //     }

    //     return colSpan;
    // };

    //Image Handler
    const imageHandler = async (ResultID = null, inspectioncheckID) => {
        if (props.checkID) {
            try {
                let res = await getImageByID(ResultID);
                let imageList = [...res?.data?.list];
                if (res?.data?.list.length > 0) {
                    setState((st) => ({
                        ...st,
                        showImageModal: false,
                        showImageModalAPI: true,
                        inspectioncheckID: inspectioncheckID,
                        inspectionCheckResultID: ResultID,
                        selectedImages: imageList.map((m) => m.vehicleInspectionsPhotoObject) || [],
                        getImageList: imageList || [],
                        fromAPI: ResultID ? true : false
                    }));
                } else {
                    let changedArray = [...state.VehicleDailyCheckResultsVM];
                    let objIndex = state.VehicleDailyCheckResultsVM.findIndex((x) => x.inspectionSheetCheckID === inspectioncheckID);
                    setState((st) => ({
                        ...st,
                        showImageModal: true,
                        showImageModalAPI: false,
                        inspectioncheckID: inspectioncheckID,
                        selectedImages: changedArray[objIndex].images || [],
                        isDisabled: false,
                        fromAPI: false
                    }));
                }
            } catch (error) {
                showToastError('Error fetching images');
            }
        } else {
            let changedArray = [...state.VehicleDailyCheckResultsVM];
            let objIndex = state.VehicleDailyCheckResultsVM.findIndex((x) => x.inspectionSheetCheckID === inspectioncheckID);
            setState((st) => ({
                ...st,
                showImageModal: true,
                showImageModalAPI: false,
                inspectioncheckID: inspectioncheckID,
                selectedImages: changedArray[objIndex].images || [],
                isDisabled: false,
                fromAPI: false
            }));
        }
    };

    // const InHire_imageHandler = async (inspectionCheckResultID) => {
    //     let res = await getImageByID(inspectionCheckResultID);
    //     if (!res.data.list) {
    //         let changedArray = [...state.ChecksObj];
    //         let objIndex = state.ChecksObj.findIndex((x) => x.inspectionSheetCheckResultID === inspectionCheckResultID);
    //         setState((st) => ({
    //             ...st,
    //             isDisabled: true,
    //             showImageModal: true,
    //             inspectionCheckResultID: inspectionCheckResultID,
    //             selectedImages: changedArray[objIndex].images || []
    //         }));
    //     } else {
    //         setState((st) => ({
    //             ...st,
    //             isDisabled: true,
    //             showImageModal: true,
    //             inspectionCheckResultID: inspectionCheckResultID,
    //             selectedImages: res.data.list.map((m) => m.vehicleInspectionsPhotoObject) || []
    //         }));
    //     }
    // };

    const sumbitImage = () => {
        setImgLoader(true);
        if (state.selectedImages?.length > 0) {
            showToastSuccess('Images Uploaded successfully');
            let changedArray = [...state.VehicleDailyCheckResultsVM];
            let objIndex = state.VehicleDailyCheckResultsVM.findIndex((x) => x.inspectionSheetCheckID === state.inspectioncheckID);
            changedArray[objIndex].basicCount = changedArray[objIndex]?.images.length; // changedArray[objIndex].inspectionSheetCheckResultNotes = e.target.value;
            setState((st) => ({ ...st, VehicleDailyCheckResultsVM: changedArray }));
        }
        setState((st) => ({ ...st, showImageModal: false, showSubmit: false }));
        setImgLoader(false);
    };

    const sumbitImageApi = async () => {
        setImgLoader(true);
        let tempArray = [...state.postImagesList];
        let data = {
            vehicleInspectionsPhotoID: null,
            vehicleInspectionsPhotoResultID: state.inspectionCheckResultID,
            vehicleInspectionsPhotoObject: tempArray
        };
        let res = await PostImage(data);
        if (res.success) {
            let changedArray = [...state.VehicleDailyCheckResultsVM];
            let objIndex = state.VehicleDailyCheckResultsVM.findIndex((x) => x.inspectionSheetCheckResultID === state.inspectionCheckResultID);
            changedArray[objIndex].basicCount = state.selectedImages.length;
            changedArray[objIndex].photosUploaded = state.selectedImages.length;
            showToastSuccess('image Uploaded successfully');

            setState((st) => ({ ...st, showImageModal: false, showImageModalAPI: false, VehicleDailyCheckResultsVM: changedArray, showSubmit: false }));
        }
        setImgLoader(false);
    };

    const onImageChangeAPI = (imageList) => {
        setImgLoader(true);
        console.log(imageList, 'imageList');
        let changedArray = [...state.VehicleDailyCheckResultsVM];
        let FilterimagesList;
        let objIndex = state.VehicleDailyCheckResultsVM.findIndex((x) => x.inspectionSheetCheckResultID === state.inspectionCheckResultID);

        if (objIndex > -1) {
            FilterimagesList = imageList.map((m) => (m.data_url ? m.data_url : m));
            changedArray[objIndex].images = FilterimagesList;
        }
        let UploadedPhoto = imageList.filter((k) => k.data_url);
        let uniqueImages = UploadedPhoto.map((k) => k.data_url);

        setState((st) => ({
            ...st,
            VehicleDailyCheckResultsVM: changedArray,
            selectedImages: FilterimagesList,
            postImagesList: uniqueImages,
            showSubmit: true
        }));
        setImgLoader(false);
    };

    const onImageChange = (imageList) => {
        setImgLoader(true);
        let changedArray = [...state.VehicleDailyCheckResultsVM];
        let FilterimagesList;

        // Find index based on inspectionSheetCheckID
        let objIndex = state.VehicleDailyCheckResultsVM.findIndex((x) => x.inspectionSheetCheckID === state.inspectioncheckID);
        if (objIndex !== -1) {
            FilterimagesList = imageList.map((m) => (m.data_url ? m.data_url : m));
            changedArray[objIndex].images = FilterimagesList;
        }

        let tempArray = [...state.imageUpload];
        let tempArrayObjIndex = tempArray.findIndex((x) => x.inspectionSheetCheckCheckID === state.inspectioncheckID);

        if (tempArrayObjIndex !== -1) {
            tempArray[tempArrayObjIndex].inspectionSheetCheckPhotos = [...FilterimagesList];
        } else {
            tempArray.push({ inspectionSheetCheckCheckID: state.inspectioncheckID, inspectionSheetCheckPhotos: FilterimagesList });
        }

        setState((st) => ({
            ...st,
            VehicleDailyCheckResultsVM: changedArray,
            selectedImages: FilterimagesList,
            imageUpload: tempArray,
            showSubmit: true
        }));
        setImgLoader(false);
    };

    const RemoveImage = async (val, i) => {
        setImgLoader(true);
        const tempList = [...state.getImageList];
        let changedArray = [...state.VehicleDailyCheckResultsVM];
        let FilterimagesList = [...state.selectedImages];
        let tempObj = tempList.findIndex((k) => k.vehicleInspectionsPhotoObject == val);

        if (tempObj === i) {
            let res = await deleteImageByID(tempList[i].vehicleInspectionPhotoID);
            if (res.success) {
                let objIndex = state.VehicleDailyCheckResultsVM.findIndex((x) => x.inspectionSheetCheckID === state.inspectioncheckID);
                if (objIndex !== -1) {
                    // let index = state.selectedImages.findIndex((k) => k === val);
                    FilterimagesList.splice(i, 1);

                    changedArray[objIndex].images = FilterimagesList;
                    changedArray[objIndex].basicCount = FilterimagesList.length;
                    changedArray[objIndex].photosUploaded = FilterimagesList.length;
                }
                setState((st) => ({
                    ...st,
                    VehicleDailyCheckResultsVM: changedArray,
                    selectedImages: FilterimagesList
                    //   imageUpload: tempArray
                }));
                // pullEditInpectionChecks();
                showToastSuccess('Image Removed Successfully');
            }
        } else {
            let objIndex = state.VehicleDailyCheckResultsVM.findIndex((x) => x.inspectionSheetCheckID === state.inspectioncheckID);
            if (objIndex > -1) {
                // let index = state.selectedImages.findIndex((k) => k === val);
                FilterimagesList.splice(i, 1);
                changedArray[objIndex].images = FilterimagesList;
            }
            setState((st) => ({
                ...st,
                VehicleDailyCheckResultsVM: changedArray,
                selectedImages: FilterimagesList
            }));
        }
        setImgLoader(false);
    };

    return (
        <InspectionCheCkContaxt.Provider
            value={{
                btnLoader,
                state,
                flags,
                widths,
                handleInput,
                signCanvas,
                clear,
                GetTechnicianSign,
                imageHandler,
                onImageChange,
                changeState,
                changeNotesState,
                changeResutNotesState,
                changeAllChildChecks,
                handleInputs,
                useDefaultSig,
                handleConfirmPopup,
                submitData,
                sumbitImage,
                setState,
                handleInspection,
                handleInpectionRow,
                closeInspection,
                portalSettings,
                getPreviousTyreData,
                RemoveImage,
                sumbitImageApi,
                onImageChangeAPI,
                imgLoader
            }}
        >
            {props.children}
        </InspectionCheCkContaxt.Provider>
    );
};
