import React, { useEffect, useState } from 'react';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { getUserPermissionsFromID, setUserPermissions } from '../../../../Core/Services/Admin/userService';
import { useToast } from '../../../../Core/Hooks/useToast';
import Grid from '@material-ui/core/Grid';
import { YesButton, NoButton, SecondaryButton, SecondaryCheckbox } from '../../../../Core/Controls';
import './../user.scss';
import { Checkbox, FormControlLabel, FormLabel } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import { getUserById } from '../../../../Core/Services/Admin/userService';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import { ReactDOM } from 'react-dom';

const AssignPermissionScreen = (props) => {
    const [permissions, setPermissions] = useState({});
    const [userID, setUserID] = useState(null);
    const [btnLoader, setBtnLoader] = useState(false);

    const { showToastSuccess, showToastError } = useToast();

    const isInternal = props?.userRole == 'internal';
    const isCustomer = props?.userRole == 'customer';
    const isServiceAgent = false; //props?.userRole == 'serviceagent';

    useEffect(async () => {
        let res = await getUserPermissionsFromID(props.userId);
        // console.log(res);

        const permissions = res.data.details[0];

        for (const key in permissions) {
            const perm = key.split('canView')[1];
            if (key.includes('canView')) {
                if (permissions['canDelete' + perm] || permissions['canUpdate' + perm] || permissions['canAuthorise' + perm] || permissions['canAdd' + perm]) {
                    permissions[key] = true;
                }
            }
        }

        setPermissions(() => ({
            ...permissions
        }));
    }, [props.userId]);

    useEffect(async () => {
        let res = await getUserById(props.userId);
        if (res.success) {
            setUserID(res?.data?.userRole);
        }
    }, []);

    useEffect(() => {}, []);

    const handleSubmit = async () => {
        const data = { ...permissions };
        data['userid'] = props.userId;
        try {
            setBtnLoader(true);
            let res = await setUserPermissions(data);

            if (res.success) {
                showToastSuccess(res.data.message);
                props.onFormSubmit(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        } catch (error) {
            setBtnLoader(false);
            showToastError(`An error occurred: ${error.message}`);
        }
    };

    const handleClick = (val) => (e) => {
        const { name, checked } = e.target;
        let toCheck = name.includes('canDelete')
            ? 'canDelete'
            : name.includes('canUpdate')
            ? 'canUpdate'
            : name.includes('canAuthorise')
            ? 'canAuthorise'
            : name.includes('canAdd')
            ? 'canAdd'
            : name.includes('canBook')
            ? 'canBook'
            : name.includes('canUpload')
            ? 'canUpload'
            : null;

        if (name.includes(toCheck)) {
            const perm = 'canView' + name.split(toCheck)[1];
            if (perm in permissions) {
                setPermissions((st) => ({
                    ...st,
                    [perm]: true
                }));
            }
        }
        let newst = {};
        newst[name] = checked;
        if (val && checked) {
            newst[val] = true;
        }
        setPermissions((st) => ({
            ...st,
            ...newst
        }));
    };

    const Permission = (props) => {
        const shouldBeDisabled = (input) => {
            const perm = input.split('canView')[1];
            if (input.includes('canView')) {
                if (
                    permissions['canDelete' + perm] ||
                    permissions['canUpdate' + perm] ||
                    permissions['canAuthorise' + perm] ||
                    permissions['canBook' + perm] ||
                    permissions['canUpload' + perm] ||
                    permissions['canAdd' + perm]
                ) {
                    return true;
                }
            }
            return false;
        };

        return props?.hidden ? null : (
            <Grid container xs={6} s={4} md={4} lg={3} xl={3}>
                <FormControlLabel
                    label={props.displayName}
                    control={
                        <Checkbox name={props.name} onChange={handleClick(props.DefaultState)} checked={props.state} disabled={shouldBeDisabled(props.name)} />
                    }
                />
            </Grid>
        );
    };

    return (
        <Grid container className="assign-permission-menu">
            <Grid item xs={12} md={12} lg={12}>
                <Typography variant="h5" color="secondary" style={{ marginTop: '15px', marginBottom: '15px' }}>
                    {`Assign User Permissions - ${props.displayName} - ${props.userRole}`}
                </Typography>
                <hr />
            </Grid>
            <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                <Typography variant="h6" color="secondary">
                    Breakdowns, Defects and VOR
                </Typography>
                <hr />
            </Grid>
            <Grid container item lg={12}>
                <Permission name={'canViewBreakdowns'} displayName={'Can View Breakdowns?'} state={permissions.canViewBreakdowns} />
                <Permission name={'canAddBreakdowns'} displayName={'Can Add Breakdowns?'} state={permissions.canAddBreakdowns} />
                <Permission
                    DefaultState="canViewBreakdowns"
                    name={'canUpdateVehicleBreakdowns'}
                    displayName={'Can Update Vehicle Breakdowns?'}
                    state={permissions.canUpdateVehicleBreakdowns}
                />
                <Permission
                    DefaultState="canViewBreakdowns"
                    name={'canUploadBreakdownsDocuments'}
                    displayName={'Can Upload Breakdowns Documents?'}
                    state={permissions.canUploadBreakdownsDocuments}
                />
                <Permission
                    DefaultState="canViewBreakdowns"
                    name={'canDeleteBreakdown'}
                    displayName={'Can Delete Breakdown?'}
                    state={permissions.canDeleteBreakdown}
                />
            </Grid>
            <Grid container item lg={12}>
                <Permission name={'canViewDefects'} displayName={'Can View Defects?'} state={permissions.canViewDefects} />
                <Permission name={'canAddDefects'} displayName={'Can Add Defects?'} state={permissions.canAddDefects} />
                <Permission
                    DefaultState="canViewDefects"
                    name={'canUpdateVehicleDefects'}
                    displayName={'Can Update Vehicle Defects?'}
                    state={permissions.canUpdateVehicleDefects}
                />
                <Permission
                    DefaultState="canViewDefects"
                    name={'canUploadDefectDocuments'}
                    displayName={'Can Upload Defect Documents?'}
                    state={permissions.canUploadDefectDocuments}
                />
            </Grid>
            <Permission name={'canViewVOR'} displayName={'Can View VOR?'} state={permissions.canViewVOR} />
            <Permission name={'canAddVOR'} displayName={'Can Add VOR?'} state={permissions.canAddVOR} />
            <Permission name={'canUpdateVOR'} displayName={'Can Update Vehicle VOR?'} state={permissions.canUpdateVOR} />
            {!isServiceAgent && (
                <>
                    <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                        <Typography variant="h6" color="secondary">
                            Contracts
                        </Typography>
                        <hr />
                    </Grid>
                    <Permission
                        name={'canViewHireContracts'}
                        displayName={'Can View Hire Contracts?'}
                        state={permissions.canViewHireContracts}
                        hidden={isCustomer || isServiceAgent}
                    />
                    <Permission
                        name={'canUpdateHireContracts'}
                        displayName={'Can Update Hire Contracts?'}
                        state={permissions.canUpdateHireContracts}
                        hidden={isCustomer || isServiceAgent}
                    />
                    <Permission
                        name={'canAddHireContracts'}
                        displayName={'Can Add Hire Contracts?'}
                        state={permissions.canAddHireContracts}
                        hidden={isCustomer || isServiceAgent}
                    />
                    <Permission
                        name={'canImportContracts'}
                        displayName={'Can Import Contracts?'}
                        state={permissions.canImportContracts}
                        hidden={isServiceAgent}
                    />
                    <Permission
                        name={'canViewMaintenanceContracts'}
                        displayName={'Can View Maintenance Contracts?'}
                        DefaultState="canViewMaintenanceContracts"
                        state={permissions.canViewMaintenanceContracts}
                        hidden={isCustomer || isServiceAgent}
                    />
                    <Permission
                        name={'canUpdateMaintenanceContracts'}
                        displayName={'Can Update Maintenance Contracts?'}
                        DefaultState="canUpdateMaintenanceContracts"
                        state={permissions.canUpdateMaintenanceContracts}
                        hidden={isCustomer || isServiceAgent}
                    />
                    <Permission
                        name={'canAddMaintenanceContracts'}
                        displayName={'Can Add Maintenance Contracts?'}
                        DefaultState="canAddMaintenanceContracts"
                        state={permissions.canAddMaintenanceContracts}
                        hidden={isCustomer || isServiceAgent}
                    />
                    <Permission
                        name={'canUploadContractDocuments'}
                        displayName={'Can Upload Contract Documents?'}
                        DefaultState="canViewContracts"
                        state={permissions.canUploadContractDocuments}
                        // hidden={isCustomer || isServiceAgent}
                    />
                    <Permission
                        name={'canViewContracts'}
                        displayName={'Can View Contracts?'}
                        DefaultState="canViewContracts"
                        state={permissions.canViewContracts}
                        hidden={isCustomer || isServiceAgent}
                    />
                    <Permission
                        name={'canUpdateContracts'}
                        displayName={'Can Update Contracts?'}
                        DefaultState="canUpdateContracts"
                        state={permissions.canUpdateContracts}
                        hidden={isCustomer || isServiceAgent}
                    />
                    <Permission
                        name={'canAddContracts'}
                        displayName={'Can Add Contracts?'}
                        DefaultState="canAddContracts"
                        state={permissions.canAddContracts}
                        hidden={isCustomer || isServiceAgent}
                    />
                </>
            )}
            {!isServiceAgent && (
                <>
                    <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                        <Typography variant="h6" color="secondary">
                            Customer
                        </Typography>
                        <hr />
                    </Grid>
                    <Permission name={'canViewCustomers'} displayName={'Can View Customers?'} state={permissions.canViewCustomers} hidden={isServiceAgent} />
                    <Permission
                        name={'canUpdateCustomers'}
                        displayName={'Can Update Customers?'}
                        state={permissions.canUpdateCustomers}
                        hidden={isServiceAgent}
                    />
                    <Permission
                        name={'canUpdateCustomerNotes'}
                        displayName={'Can Update Customer Notes?'}
                        DefaultState="canViewCustomers"
                        state={permissions.canUpdateCustomerNotes}
                        hidden={isServiceAgent}
                    />
                    <Permission
                        name={'canUploadCustomerDocuments'}
                        displayName={'Can Upload Customer Documents?'}
                        DefaultState="canViewCustomers"
                        state={permissions.canUploadCustomerDocuments}
                        hidden={isServiceAgent}
                    />
                    <Permission
                        name={'canSendMessageToCustomers'}
                        displayName={'Can Send Message To Customers?'}
                        DefaultState="canViewCustomers"
                        state={permissions.canSendMessageToCustomers}
                        hidden={isServiceAgent}
                    />
                    <Permission
                        name={'canUpdateCustomerCostCentres'}
                        displayName={'Can Update Customer Cost Centres?'}
                        DefaultState="canViewCustomers"
                        state={permissions.canUpdateCustomerCostCentres}
                        hidden={isServiceAgent}
                    />
                    <Permission
                        name={'canUpdateCustomerFleetInsurance'}
                        displayName={'Can Update Customer Fleet Insurance?'}
                        DefaultState="canViewCustomers"
                        state={permissions.canUpdateCustomerFleetInsurance}
                        hidden={isServiceAgent}
                    />
                    <Permission
                        name={'canImportCustomer'}
                        displayName={'Can Import Customers?'}
                        state={permissions.canImportCustomer}
                        hidden={isServiceAgent}
                    />
                </>
            )}
            <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                <Typography variant="h6" color="secondary">
                    Daily Checks & Inspections
                </Typography>
                <hr />
            </Grid>
            <Grid container item lg={12}>
                <Permission name={'canViewDailyChecks'} displayName={'Can View Daily Checks?'} state={permissions.canViewDailyChecks} />
                <Permission name={'canAddDailyChecks'} displayName={'Can Add Daily Checks?'} state={permissions.canAddDailyChecks} />
            </Grid>
            <Grid container item lg={12}>
                <Permission name={'canViewInspections'} displayName={'Can View Inspections?'} state={permissions.canViewInspections} />
                <Permission name={'canAddInspections'} displayName={'Can Add Inspections?'} state={permissions.canAddInspections} />
                <Permission
                    name={'canUpdateVehicleInspections'}
                    displayName={'Can Update Vehicle Inspections?'}
                    DefaultState="canViewInspections"
                    state={permissions.canUpdateVehicleInspections}
                />
            </Grid>
             {!isServiceAgent && (
                <>
                    <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                        <Typography variant="h6" color="secondary">
                            Depot
                        </Typography>
                        <hr />
                    </Grid>
                    <Permission name={'canViewDepots'} displayName={'Can View Depot?'} state={permissions.canViewDepots} hidden={isServiceAgent} />
                    <Permission name={'canUpdateDepots'} displayName={'Can Update Depot?'} state={permissions.canUpdateDepots} hidden={isServiceAgent} />
                    <Permission name={'canImportDepots'} displayName={'Can Import Depot?'} state={permissions.canImportDepots} />
                    <Permission name={'canUploadDepotDocuments'} displayName={'Can Upload Depot Documents?'} state={permissions.canUploadDepotDocuments} />
                    <Permission
                        name={'canUpdateDepotDepartments'}
                        displayName={'Can Update Depot Departments?'}
                        DefaultState="canViewDepots"
                        state={permissions.canUpdateDepotDepartments}
                        hidden={isServiceAgent}
                    />
                    <Permission
                        name={'canUpdateDepotNotes'}
                        displayName={'Can Update Depot Notes?'}
                        DefaultState="canViewDepots"
                        state={permissions.canUpdateDepotNotes}
                        hidden={isServiceAgent}
                    />
                </>
            )}
            {!isServiceAgent && (
                <>
                    <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                        <Typography variant="h6" color="secondary">
                            Drivers
                        </Typography>
                        <hr />
                    </Grid>
                    <Permission name={'canViewDrivers'} displayName={'Can View Drivers?'} state={permissions.canViewDrivers} hidden={isServiceAgent} />
                    <Permission name={'canUpdateDrivers'} displayName={'Can Update Drivers?'} state={permissions.canUpdateDrivers} hidden={isServiceAgent} />
                    <Permission
                        name={'canUploadDriverDailyCheckDocuments'}
                        displayName={'Can Upload Driver Daily Check Documents?'}
                        DefaultState="canViewDrivers"
                        state={permissions.canUploadDriverDailyCheckDocuments}
                    />
                    <Permission name={'canAddDrivers'} displayName={'Can Add Drivers?'} state={permissions.canAddDrivers} hidden={isServiceAgent} />

                    <Permission
                        name={'canDoDriverLicenceChecks'}
                        displayName={'Can Do Driver Licence Checks?'}
                        DefaultState="canViewDrivers"
                        state={permissions.canDoDriverLicenceChecks}
                        hidden={isServiceAgent}
                    />

                    <Permission
                        name={'canUpdateDriverNotes'}
                        displayName={'Can Update Driver Notes?'}
                        DefaultState="canViewDrivers"
                        state={permissions.canUpdateDriverNotes}
                        hidden={isServiceAgent}
                    />
                    <Permission
                        name={'canUploadDriverDocuments'}
                        displayName={'Can Upload Driver Documents?'}
                        DefaultState="canViewDrivers"
                        state={permissions.canUploadDriverDocuments}
                    />
                </>
            )}
               {!isServiceAgent && (
                <>
                    <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                        <Typography variant="h6" color="secondary">
                            Invoices
                        </Typography>
                        <hr />
                    </Grid>
                    <Permission name={'canViewInvoices'} displayName={'Can View Invoices?'} state={permissions.canViewInvoices} />
                    <Permission
                        name={'canAddPurchaseInvoices'}
                        displayName={'Can Add Purchase Invoices?'}
                        state={permissions.canAddPurchaseInvoices}
                        hidden={isServiceAgent}
                    />

                    <Permission
                        name={'canUpdatePurchaseInvoices'}
                        displayName={'Can Update Purchase Invoices?'}
                        state={permissions.canUpdatePurchaseInvoices}
                        hidden={isCustomer || isServiceAgent}
                    />
                    <Permission
                        name={'canUploadInvoiceDocuments'}
                        displayName={'Can Upload Invoice Documents?'}
                        state={permissions.canUploadInvoiceDocuments}
                    />
                    <Permission
                        name={'canViewPurchaseInvoices'}
                        displayName={'Can View Purchase Invoices?'}
                        state={permissions.canViewPurchaseInvoices}
                        hidden={isServiceAgent}
                    />
                    <Permission
                        name={'canViewSalesInvoices'}
                        displayName={'Can Add Sales Invoices?'}
                        DefaultState="canViewPurchaseInvoices"
                        state={permissions.canViewSalesInvoices}
                        hidden={isCustomer || isServiceAgent}
                    />
                    <Permission
                        name={'canUpdateSalesInvoices'}
                        displayName={'Can Update Sales Invoices?'}
                        DefaultState="canViewPurchaseInvoices"
                        state={permissions.canUpdateSalesInvoices}
                        hidden={isCustomer || isServiceAgent}
                    />
                    <Permission
                        name={'canUploadSalesInvoiceDocuments'}
                        displayName={'Can Upload Sales Invoice Documents?'}
                        DefaultState="canViewPurchaseInvoices"
                        state={permissions.canUploadSalesInvoiceDocuments}
                    />
                </>
            )}
            <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                <Typography variant="h6" color="secondary">
                    Jobs
                </Typography>
                <hr />
            </Grid>
            <Permission name={'canViewJobs'} displayName={'Can View Jobs?'} state={permissions.canViewJobs} />
            <Permission name={'canUpdateJobs'} displayName={'Can Update Jobs?'} state={permissions.canUpdateJobs} />
            <Permission name={'canDeleteJobs'} displayName={'Can Delete Jobs?'} state={permissions.canDeleteJobs} />
            <Permission
                name={'canUploadJobDocuments'}
                displayName={'Can Upload Job Documents?'}
                DefaultState="canViewJobs"
                state={permissions.canUploadJobDocuments}
            />
            <Permission name={'canAuthoriseJobs'} displayName={'Can Authorise Jobs?'} state={permissions.canAuthoriseJobs} hidden={isCustomer} />
            <Permission
                name={'canDeleteJobLines'}
                displayName={'Can Delete Job Lines?'}
                DefaultState="canViewJobs"
                state={permissions.canDeleteJobLines}
                hidden={isCustomer}
            />
            <Permission
                name={'canUpdateJobLimit'}
                displayName={'Can Update Job Limit?'}
                DefaultState="canViewJobs"
                state={permissions.canUpdateJobLimit}
                hidden={isCustomer || isServiceAgent}
            />
            <Permission name={'canUploadJobItemDocuments'} displayName={'Can Upload Job Item Documents?'} state={permissions.canUploadJobItemDocuments} />
            <Permission
                name={'canSendReplyJobQueries'}
                displayName={'Can Send Reply Job Queries?'}
                DefaultState="canViewJobs"
                state={permissions.canSendReplyJobQueries}
            />
            <Permission
                name={'canAddScheduleItemstoJob'}
                displayName={'Can Add Schedule Items to Job?'}
                DefaultState="canViewJobs"
                state={permissions.canAddScheduleItemstoJob}
            />
            <Permission name={'canSendTextMsg'} displayName={'Can Sent Text Message?'} DefaultState="canViewJobs" state={permissions.canSendTextMsg} />
            <Permission name={'canJobSignOff'} displayName={'Can Job Sign Off ?'} DefaultState="canViewJobs" state={permissions.canJobSignOff} />
            <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                <Typography variant="h6" color="secondary">
                    Misc
                </Typography>
                <hr />
            </Grid>
            <Permission name={'canViewDocuments'} displayName={'Can View Documents?'} state={permissions.canViewDocuments} />
            <Permission name={'canImportMileages'} displayName={'Can Import Mileages?'} state={permissions.canImportMileages} hidden={isServiceAgent} />
            <Permission name={'canUploadDocuments'} displayName={'Can Upload Documents?'} state={permissions.canUploadDocuments} />
            <Permission
                name={'canUpdateCompanyDetails'}
                displayName={'Can Update Company Details?'}
                state={permissions.canUpdateCompanyDetails}
                hidden={isCustomer || isServiceAgent}
            />
            <Permission name={'canViewNotes'} displayName={'Can View Notes?'} state={permissions.canViewNotes} />
            <Permission
                name={'canImportFuelPurchases'}
                displayName={'Can Import Fuel Purchases?'}
                state={permissions.canImportFuelPurchases}
                hidden={isServiceAgent}
            />
            <Permission name={'canUploadLeadsDocuments'} displayName={'Can Upload Leads Documents?'} state={permissions.canUploadLeadsDocuments} />

            <Permission name={'canViewMessages'} displayName={'Can View Messages?'} state={permissions.canViewMessages} />

            <Permission name={'canViewAddresss'} displayName={'Can View Address?'} state={permissions.canViewAddresss} hidden={isServiceAgent} />
            <Permission name={'canImportAddress'} displayName={'Can Import Address?'} state={permissions.canImportAddress} hidden={isServiceAgent} />
            <Permission name={'canUploadEnquiriesDocuments'} displayName={'Can Upload Enquiries Documents?'} state={permissions.canUploadEnquiriesDocuments} />

            <Permission name={'canUpdateAddress'} displayName={'Can Edit Address?'} state={permissions.canUpdateAddress} hidden={isServiceAgent} />
            <Permission name={'canViewHolidayLeave'} displayName={'Can View Holiday Leave?'} state={permissions.canViewHolidayLeave} />
            <Permission name={'canAddUpdateHolidays'} displayName={'Can Add Update Holiday Leave?'} state={permissions.canAddUpdateHolidays} />

            <Permission name={'canImportJourneys'} displayName={'Can Import Journeys?'} state={permissions.canImportJourneys} hidden={isServiceAgent} />
            <Permission name={'canUploadUserDocuments'} displayName={'Can Upload User Documents?'} state={permissions.canUploadUserDocuments} />
            <Permission name={'canDeleteDocuments'} displayName={'Can Delete Documents?'} state={permissions.canDeleteDocuments} />

            <Permission
                name={'canViewCompanyDetails'}
                displayName={'Can View Company Details?'}
                state={permissions.canViewCompanyDetails}
                hidden={isCustomer || isServiceAgent}
            />
            <Grid item xs={12} md={12} lg={12}>
                <Typography variant="h6" color="secondary" style={{ paddingTop: '15px' }}>
                    Schedules
                </Typography>
                <hr />
            </Grid>
            <Permission name={'canViewSchedules'} displayName={'Can View Schedules?'} state={permissions.canViewSchedules} />
            <Permission name={'canUpdateSchedules'} displayName={'Can Update Schedules?'} state={permissions.canUpdateSchedules} />
            <Permission
                name={'canDeleteSchedules'}
                displayName={'Can Delete Schedules?'}
                state={permissions.canDeleteSchedules}
                hidden={isCustomer || isServiceAgent}
            />
            <Permission name={'canBookSchedules'} displayName={'Can Book Schedules?'} state={permissions.canBookSchedules} />
            <Permission
                name={'canUploadScheduleDocuments'}
                displayName={'Can Upload Schedule Documents?'}
                state={permissions.canUploadScheduleDocuments}
                DefaultState="canViewSchedules"
            />
            <Permission
                name={'canUpdateScheduleNotes'}
                displayName={'Can Update Schedule Notes?'}
                state={permissions.canUpdateScheduleNotes}
                DefaultState="canViewSchedules"
            />
            <Permission
                name={'canCompleteSchedule'}
                displayName={'Can Complete Schedule?'}
                state={permissions.canCompleteSchedule}
                DefaultState="canViewSchedules"
            />
              {!isServiceAgent && !isCustomer && (
                <>
                    <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                        <Typography variant="h6" color="secondary">
                            Service Areas
                        </Typography>
                        <hr />
                    </Grid>
                    <Permission
                        name={'canViewServiceAgents'}
                        displayName={'Can View Service Areas?'}
                        state={permissions.canViewServiceAgents}
                        hidden={isCustomer || isServiceAgent}
                    />
                    <Permission
                        name={'canUpdateServiceAgents'}
                        displayName={'Can Update Service Areas?'}
                        state={permissions.canUpdateServiceAgents}
                        hidden={isCustomer || isServiceAgent}
                    />
                    <Permission
                        name={'canAddServiceAgents'}
                        displayName={'Can Add Service Areas?'}
                        state={permissions.canAddServiceAgents}
                        hidden={isCustomer || isServiceAgent}
                    />
                    <Permission
                        name={'canImportServiceAgents'}
                        displayName={'Can Import Service Areas?'}
                        state={permissions.canImportServiceAgents}
                        hidden={isCustomer || isServiceAgent}
                    />
                    <Permission
                        name={'canUpdateServiceAgentRating'}
                        displayName={'Can Update Service Area Rating?'}
                        DefaultState="canViewServiceAgents"
                        state={permissions.canUpdateServiceAgentRating}
                        hidden={isCustomer || isServiceAgent}
                    />
                    <Permission
                        name={'canUpdateServiceAgentNotes'}
                        displayName={'Can Update Service Area Notes?'}
                        DefaultState="canViewServiceAgents"
                        state={permissions.canUpdateServiceAgentNotes}
                        hidden={isCustomer || isServiceAgent}
                    />
                    <Permission
                        name={'canUploadServiceAgentDocuments'}
                        displayName={'Can Upload Service Area Documents?'}
                        DefaultState="canViewServiceAgents"
                        state={permissions.canUploadServiceAgentDocuments}
                    />
                </>
            )}
            {!isServiceAgent && (
                <>
                    <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                        <Typography variant="h6" color="secondary">
                            Vehicles
                        </Typography>
                        <hr />
                    </Grid>
                    <Permission name={'canViewVehicles'} displayName={'Can View Vehicles?'} state={permissions.canViewVehicles} hidden={isServiceAgent} />
                    <Permission name={'canUpdateVehicles'} displayName={'Can Update Vehicles?'} state={permissions.canUpdateVehicles} />
                    <Permission
                        name={'canUpdateVehicleFuelRecords'}
                        displayName={'Can Update Vehicle Fuel Records?'}
                        DefaultState="canViewVehicles"
                        state={permissions.canUpdateVehicleFuelRecords}
                        hidden={isServiceAgent}
                    />
                    <Permission name={'canImportVehicles'} displayName={'Can Import Vehicles?'} state={permissions.canImportVehicles} hidden={isServiceAgent} />
                    <Permission name={'canAddVehicles'} displayName={'Can Add Vehicles?'} state={permissions.canAddVehicles} hidden={isServiceAgent} />
                    <Permission
                        name={'canUpdateVehicleContracts'}
                        displayName={'Can Update Vehicle Contracts?'}
                        DefaultState="canViewVehicles"
                        state={permissions.canUpdateVehicleContracts}
                        hidden={isServiceAgent}
                    />
                    <Permission
                        name={'canUpdateVehicleDailyChecks'}
                        displayName={'Can Update Vehicle Daily Checks?'}
                        DefaultState="canViewVehicles"
                        state={permissions.canUpdateVehicleDailyChecks}
                        hidden={isServiceAgent}
                    />
                    <Permission
                        name={'canUpdateVehicleJourneys'}
                        displayName={'Can Update Vehicle Journeys?'}
                        DefaultState="canViewVehicles"
                        state={permissions.canUpdateVehicleJourneys}
                        hidden={isServiceAgent}
                    />
                    <Permission
                        name={'canUpdateVehicleMileages'}
                        displayName={'Can Update Vehicle Mileages?'}
                        DefaultState="canViewVehicles"
                        state={permissions.canUpdateVehicleMileages}
                        hidden={isServiceAgent}
                    />
                    <Permission
                        name={'canUpdateVehicleNotes'}
                        displayName={'Can Update Vehicle Notes?'}
                        DefaultState="canViewVehicles"
                        state={permissions.canUpdateVehicleNotes}
                        hidden={isServiceAgent}
                    />
                    <Permission
                        name={'canUpdateVehicleRecalls'}
                        displayName={'Can Update Vehicle Recalls?'}
                        DefaultState="canViewVehicles"
                        state={permissions.canUpdateVehicleRecalls}
                        hidden={isServiceAgent}
                    />
                    <Permission
                        name={'canUploadRecallsDocuments'}
                        displayName={'Can Upload Recalls Documents?'}
                        state={permissions.canUploadRecallsDocuments}
                    />
                    <Permission
                        name={'canArchiveVehicle'}
                        displayName={'Can Archive Vehicle?'}
                        DefaultState="canViewVehicles"
                        state={permissions.canArchiveVehicle}
                    />
                    <Permission
                        name={'canUploadVehicleDocuments'}
                        displayName={'Can Upload Vehicle Documents?'}
                        DefaultState="canViewVehicles"
                        state={permissions.canUploadVehicleDocuments}
                    />
                </>
            )}
          
            <Grid item xs={12} className="submit_btn" style={{ paddingTop: '25px' }}>
                <SecondaryButton onClick={handleSubmit} isBtnLoader={btnLoader}>
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default AssignPermissionScreen;
