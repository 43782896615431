import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Paper,
    TableCell,
    Typography,
    InputLabel,
    Tooltip,
    FormHelperText,
    ButtonGroup,
    Button,
    TextField
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import SignaturePad from 'react-signature-canvas';
import DeleteIcon from '@material-ui/icons/Delete';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { AppStorage } from '../../../../../../Core/Basic/storage-service';
import { useToast } from '../../../../../../Core/Hooks/useToast';

import moment from 'moment';
import { AppButtonGroup, MandatoryField, SecondaryButton } from '../../../../../../Core/Controls';
import { getSingleChecks, postSingleChecksData } from '../../../../../../Core/Services/InternalUser/Vehicle/vehicleTabChecks';

export const ActionTakenModal = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);

    const inputRef = useRef(null);
    const inputHiddenRef = useRef(null); // Hidden input to shift focus

    const [state, setState] = useState({
        errors: {},
        statusList: [],
        signAvailable: AppStorage.getSignature() === 'absent' ? false : true
    });
    const signCanvas = useRef({});

    useEffect(() => {
        getCheckData();
    }, []);

    const getCheckData = async () => {
        let res = await getSingleChecks(props.selectedCheck.inspectionSheetCheckResultID);
        if (res.success) {
            let data = res.data.defect[0];

            setState((st) => {
                let newSt = { ...st };
                if (data) {
                    newSt.defectDetail = data.defect;
                    newSt.vehicleInspectionsDefectActionTaken = data.vehicleInspectionsDefectActionTaken;
                    newSt.vehicleInspectionsDefectRectifiedSignature = data.vehicleInspectionsDefectRectifiedSignature;
                    newSt.vehicleInspectionsDefectStatusID = data.vehicleInspectionsDefectStatusID;
                    newSt.imgtechniciansign = data.vehicleInspectionsDefectRectifiedSignature ? true : false;
                    newSt.isDisabled = data.rectifiedBy ? true : false;
                    newSt.vehicleInspectionsDefectRectifiedDate = data.vehicleInspectionsDefectRectifiedDate
                        ? moment(data.vehicleInspectionsDefectRectifiedDate).format('DD-MM-YYYY hh:mm')
                        : '';
                    newSt.vehicleInspectionsDefectRectifiedName = data.vehicleInspectionsDefectRectifiedName;
                    newSt.vehicleInspectionsDefectID = data.vehicleInspectionsDefectID;
                }
                newSt.statusList = res.data.list;
                return newSt;
            });
        } else {
            showToastError(res.message);
        }
    };

    const inputChange = useCallback((e) => {
        const { name, value, type } = e.target;

        setState((st) => ({ ...st, [name]: value }));

        // if (inputRef.current && !inputRef.current.contains(event.target)) {
        //     inputRef.current.blur(); // Remove focus
        // }
    }, []);

    const handleBlur = () => {
        if (inputRef.current) {
            // Try to blur using a short timeout to ensure the event finishes
            setTimeout(() => {
                inputRef.current.blur();

                // Move focus to a hidden input to trick the browser
                if (inputHiddenRef.current) {
                    inputHiddenRef.current.focus();
                }
            }, 0);
        }
    };

    const validations = () => {
        let formIsValid = true;
        let errors = {};

        if (state.vehicleInspectionsDefectStatusID !== '3' && !state.vehicleInspectionsDefectActionTaken) {
            errors.vehicleInspectionsDefectActionTaken = `Action taken is required`;
            formIsValid = false;
        }

        if (state.vehicleInspectionsDefectStatusID == 1 || !state.vehicleInspectionsDefectStatusID) {
            errors.vehicleInspectionsDefectStatusID = `Please select defect status`;
            formIsValid = false;
        }
        if (!state.vehicleInspectionsDefectRectifiedSignature) {
            errors.vehicleInspectionsDefectRectifiedSignature = `Engineer Signature is required`;
            formIsValid = false;
        }

        console.log(errors, '<<<<<<<<<<<<< ');
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    const submitData = async () => {
        if (validations()) {
            let data = {
                vehicleInspectionsDefectID: state.vehicleInspectionsDefectID,
                vehicleInspectionsDefectActionTaken: state.vehicleInspectionsDefectActionTaken,
                // vehicleInspectionsDefectRectifiedName: state.vehicleInspectionsDefectRectifiedName,
                vehicleInspectionsDefectRectifiedSignature: state.vehicleInspectionsDefectRectifiedSignature,
                vehicleInspectionsDefectStatusID: state.vehicleInspectionsDefectStatusID
            };
            setBtnLoader(true);
            let res = await postSingleChecksData(data);
            if (res.success) {
                showToastSuccess('Defect Details Added Successfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };
    const useDefaultSig = () => {
        setState((st) => ({ ...st, imgtechniciansign: true, vehicleInspectionsDefectRectifiedSignature: AppStorage.getSignature() }));
    };

    const clear = () => {
        if (state.imgtechniciansign) {
            setState((st) => ({
                ...st,
                imgtechniciansign: false,
                vehicleInspectionsDefectRectifiedSignature: null
            }));
        } else {
            signCanvas.current.clear();
            setState((st) => ({
                ...st,
                vehicleInspectionsDefectRectifiedSignature: null
            }));
        }
    };

    const GetTechnicianSign = () => {
        setState((st) => {
            const nst = {
                ...st,
                vehicleInspectionsDefectRectifiedSignature: signCanvas.current.toDataURL()
            };
            return nst;
        });
    };
    const onBtnSelected = (o) => {
        setState((dt) => ({
            ...dt,
            ...o
        }));
    };

    console.log(state.vehicleInspectionsDefectStatusID, 'state.vehicleInspectionsDefectStatusID');
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} className="mandatory-fields">
                <InputLabel style={{ fontWeight: 'bold' }}>{state.defectDetail}</InputLabel>
                {/* <Typography variant="body2"> {state.defectDetail}</Typography> */}
            </Grid>

            <Grid item xs={12} sm={12} className="mandatory-fields">
                <InputLabel shrink> Action Taken</InputLabel>
                <TextField
                    size="small"
                    variant="outlined"
                    disabled={state.isDisabled}
                    autoComplete="off"
                    fullWidth
                    // autoFocus
                    inputLabel="Action Taken"
                    multiline
                    rows={4}
                    placeholder="Action Taken"
                    value={state.vehicleInspectionsDefectActionTaken}
                    onChange={inputChange}
                    name="vehicleInspectionsDefectActionTaken"
                    errors={state.errors.vehicleInspectionsDefectActionTaken}
                    // disabled={state.isDisabled}
                    ref={inputRef}
                />
                <FormHelperText error>{state.errors.vehicleInspectionsDefectActionTaken}</FormHelperText>

                {/* <MandatoryField
                    inputLabel="Action Taken"
                    multiline
                    rows={4}
                    placeholder="Action Taken"
                    value={state.vehicleInspectionsDefectActionTaken}
                    onChange={inputChange}
                    name="vehicleInspectionsDefectActionTaken"
                    errors={state.errors.vehicleInspectionsDefectActionTaken}
                    disabled={state.isDisabled}
                    ref={inputRef}
                    // onBlur={handleBlur} // Handle blur event
                /> */}
            </Grid>

            <Grid item xs={12} className="mandatory-fields">
                <InputLabel shrink required>
                    Defect Status
                </InputLabel>
                <AppButtonGroup
                    width="50%"
                    name="vehicleInspectionsDefectStatusID"
                    onChange={onBtnSelected}
                    value={`${state.vehicleInspectionsDefectStatusID}`}
                    options={state.statusList}
                    valuePropertyName="statusID"
                    textPropertyName="status"
                    disabled={state.isDisabled}
                />
                <FormHelperText error>{state.errors.vehicleInspectionsDefectStatusID}</FormHelperText>
            </Grid>

            <div ref={inputHiddenRef} tabIndex={-1} style={{ position: 'absolute', opacity: 0 }} />

            <Grid item xs={12} className="mandatory-fields">
                <div style={{ position: 'relative' }}>
                    <InputLabel
                        shrink
                        component="legend"
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        Engineer Signature
                        {!state.isDisabled && state.signAvailable && (
                            <Tooltip title="Use default signature">
                                <BorderColorIcon onClick={useDefaultSig} style={{ margin: '0px 0px -5px 5px' }} />
                            </Tooltip>
                        )}
                    </InputLabel>

                    <div
                        style={{
                            position: 'absolute',
                            right: 0
                        }}
                    >
                        {!state.isDisabled && (
                            <Tooltip title="Clear Signature">
                                <DeleteIcon onClick={clear} />
                            </Tooltip>
                        )}
                    </div>

                    {state.imgtechniciansign ? (
                        <div
                            style={{
                                position: 'relative'
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                    bottom: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end'
                                }}
                            >
                                <Typography variant="h6">{state.vehicleInspectionsDefectRectifiedName}</Typography>
                                <Typography variant="button">{state.vehicleInspectionsDefectRectifiedDate}</Typography>
                            </div>
                            <img
                                src={state.vehicleInspectionsDefectRectifiedSignature}
                                alt="my signature"
                                style={{
                                    display: 'block',
                                    margin: '0 auto',
                                    border: '1px solid black',
                                    width: '100%',
                                    height: '165px',
                                    padding: '5px 0px'
                                }}
                            />
                        </div>
                    ) : (
                        <div
                            className="sigContainer"
                            // onMouseDown={() => inputRef.current && inputRef.current.blur()} // Handle mouse interactions (desktop/tablet with mouse)
                            // onTouchStart={() => inputRef.current && inputRef.current.blur()} // Handle touch interactions (tablet/phone)
                        >
                            <SignaturePad
                                canvasProps={{ className: 'sigPad' }}
                                ref={signCanvas}
                                onEnd={() => {
                                    GetTechnicianSign();
                                    handleBlur();

                                    // inputRef.current && inputRef.current.blur();
                                    // signCanvas.current && signCanvas.current.focus();
                                }}
                                // onBegin={handleClickOutside}
                            />
                        </div>
                    )}

                    <FormHelperText error>{state.errors.vehicleInspectionsDefectRectifiedSignature}</FormHelperText>
                </div>
            </Grid>
            {!state.isDisabled ? (
                <Grid item xs={12}>
                    <SecondaryButton onClick={submitData} isBtnLoader={btnLoader}>
                        Confirm
                    </SecondaryButton>
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <SecondaryButton onClick={() => props.onClose(false)}>Cancel</SecondaryButton>
                </Grid>
            )}
        </Grid>
    );
};

export default ActionTakenModal;
