import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    editorContainer: {
        '& .ck-editor__editable': {
            height: (props) => `${props.height || 230}px`,
            overflow: 'auto',
            padding: '0 var(--ck-spacing-standard)',
            border: '1px solid transparent'
        }
    }
});

export const CustomeEditor = (props) => {
    const classes = useStyles(props);
    const { height, ...otherProps } = props;

    return (
        <div className={classes.editorContainer}>
            <CKEditor
                editor={ClassicEditor}
                config={{
                    toolbar: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        '|',
                        'numberedList',
                        'bulletedList',
                        '|',
                        'outdent',
                        'indent',
                        '|',
                        // 'insertTable',
                        'undo',
                        'redo'
                    ]
                }}
                {...otherProps}
            />
        </div>
    );
};

export default CustomeEditor;
