import React, { useCallback, useEffect, useMemo } from 'react';
import { BreadCrumbs, DatePicker, DateTimePicker, EditButton, formatters, SelectBox, SimpleField, SingleSelect, TextBox } from '../../../../Core/Controls';
import {
    Card,
    Grid,
    InputLabel,
    Typography,
    CircularProgress,
    Modal,
    ButtonGroup,
    Button,
    Popover,
    TableContainer,
    makeStyles,
    TableRow,
    withStyles,
    TableCell,
    TableHead,
    TableBody,
    Table
} from '@material-ui/core';
import { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, ComposedChart } from 'recharts';
import { PieChart, Pie, Sector, LabelList } from 'recharts';
import './jobReport.scss';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import { useState } from 'react';
import moment from 'moment';
import { getjobByType, getPartUsage } from '../../../../Core/Services/InternalUser/jobReportingService';
import {
    getVehicleScreenDropdowns,
    JobReporting_ScreenDropDown,
    PartUsage_ScreenDropDown
} from '../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { GetJobReportingExcelFile } from '../../../../Core/Services/InternalUser/excelDownload';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { getJobEnggById } from '../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import DateFieldsModal from '../Vehicles/more/dateFields';
import { useWindowSize } from '../../../../Core/Hooks/useWindowSize';
import { DescriptionIcon } from '@material-ui/icons/Description';
import { GetAllPartList, GetAllPartsByPartNumber } from '../../../../Core/Services/InternalUser/JOB/jobItemsService';
import { Alert } from '@material-ui/lab';

const Crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Part Usage', active: true }
];

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'grey',
        color: theme.palette.common.white,
        padding: '10px 5px'
    },
    body: {
        fontSize: 15,
        padding: '2px 5px',
        color: theme.palette.common.black
    }
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        },
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white'
        }
    }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: '500px',
        backgroundColor: 'white'
    },
    popover: {
        pointerEvents: 'none'
    }
});

function generateRandomColorArray(numColors) {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
        // Generate random RGB values for each color
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        colors.push(`rgb(${r}, ${g}, ${b})`);
    }
    return colors;
}

const COLORS = [
    '#0074D9',
    '#FF4136',
    '#2ECC40',
    '#FF851B',
    '#7FDBFF',
    '#B10DC9',
    '#FFDC00',
    '#001f3f',
    '#39CCCC',
    '#01FF70',
    '#85144b',
    '#F012BE',
    '#3D9970',
    '#111111',
    '#AAAAAA'
];

const barColors = {
    0: '#0074D9',
    1: '#FF4136',
    2: '#2ECC40',
    3: '#FF851B',
    4: '#7FDBFF',
    5: '#B10DC9',
    6: '#FFDC00',
    7: '#001f3f',
    8: '#39CCCC',
    9: '#01FF70',
    10: '#85144b',
    11: '#F012BE',
    12: '#3D9970',
    13: '#111111',
    14: '#AAAAAA'
};

const DateList = [
    { id: 'S', name: 'Sign Off' },
    { id: 'I', name: 'Invoiced' },
    { id: 'R', name: 'Reviewed' },
    { id: 'B', name: 'Booking Date' },
    { id: 'A', name: 'All' }
];
const JobTypeList = [
    { id: 'S', name: 'Standard' },
    { id: 'P', name: 'Purchase' }
];

const columns = [
    { key: 'reg', name: 'Reg Number', width: 100 },
    { key: 'partNumber', name: 'Part Number', width: 100 },
    { key: 'jobAgentOrderNo', name: 'Order No', width: 150 },
    { key: 'partDesc', name: 'Description', width: 230 },
    { key: 'customer', name: 'Customer Name', width: 250 },
    // { key: 'depot', name: 'Depot Name', width: 150 },
    { key: 'warehouseDescription', name: 'Warehouse', width: 100 },
    // { key: 'eng', name: 'Engineer', width: 120 },
    { key: 'sa', name: 'Service Agent', width: 100 },
    { key: 'vehicleJobPartPaymentType', name: 'Payment', width: 120 }
];

let startDate = moment().startOf('month').format('YYYY-MM-DD');
let endDate = moment().format('YYYY-MM-DD');

let options = [
    { id: 1, name: 'Hours' },
    { id: 2, name: 'amount' }
];

const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;

    return (
        <g>
            <text x={x + width / 2} y={y - radius} fill="#000000" textAnchor="middle" dominantBaseline="middle">
                {value ? <>£{value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}
            </text>
        </g>
    );
};

const PartUsageScreen = (props) => {
    const isCustomer = useLoginInfo().userRoles.includes('customer');
    const isEngineer = useLoginInfo().userRoles.includes('engineer');
    const history = useHistory();
    const [state, setState] = useState({
        startDate: startDate,
        endDate: endDate,
        deoptList: [],
        jobTypeList: [],
        monthYearList: [],
        vmrsDescriptionList: [],
        url: `VehicleJobItems/VehicleJobs_PartUsage?startDate=${startDate}&endDate=${endDate}`,
        checkCondition: false,
        showDetailsPopup: false,
        filterTechnician: [],
        makes: [],
        warehouse: [],
        vehicleTypes: [],
        vehicleCategories: [],
        vehicleOwnershipTypes: [],
        dateFilters: [],
        costTyes: [],
        graphData: 1,
        allPartsDataList: []
    });

    const [anchorEl, setAnchorEl] = React.useState(false);

    const handleClick = () => {
        setAnchorEl(true);
    };

    const handleClose = () => {
        setAnchorEl(false);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [value, setValue] = useState({
        name: '',
        value: '',
        heading: ''
    });

    const [chartData, setChartData] = useState({
        vmrsList: [],
        deoptList: []
    });

    useEffect(() => {
        setChartData((st) => ({ ...st, vmrsList: state.vmrsDescriptionList, deoptList: state.deoptList }));
    }, [state.vmrsDescriptionList, state.deoptList]);

    const handleDetailpopUp = (params, heading) => {
        setState((st) => ({ ...st, showDetailsPopup: !st.showDetailsPopup }));
        setValue((st) => ({ ...st, name: params.name || '', value: params.Total || '', heading: heading || '' }));
    };

    const inputChange = useCallback((e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    }, []);

    const [height, width] = useWindowSize();

    const [tempOptions, setTempOptions] = useState({
        customerIDValue: null,
        makeIDValue: null,
        modelIDValue: null,
        vehicleTypeIDValue: null,
        vehicleCategoryIDValue: null,
        ownershipTypeIDValue: null,
        dateFilterIDValue: null,
        vehicleAgeIDValue: null,
        costTypeIDValue: null,
        warehouseIDValue: null
    });

    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.jobID}/${val?.vehicleID}`,
            search: isEngineer ? '?tab_testing_tabs=1' : '',
            state: 'jobs'
        });
    };

    let getURl = () => {
        let queryParams = [
            state.startDate && `startDate=${state.startDate}`,
            state.endDate && `endDate=${state.endDate}`,
            tempOptions.customerIDValue?.id && `custId=${tempOptions.customerIDValue.id}`,
            tempOptions.serviceAreaIDValue?.id && `ServiceAgentID=${tempOptions.serviceAreaIDValue.id}`,
            tempOptions.engineerIDValue?.id && `TechnicianUserID=${tempOptions.engineerIDValue.id}`,
            state.partType && `PartType=${state.partType}`,
            tempOptions.warehouseIDValue?.id && `Warehouse=${tempOptions.warehouseIDValue.id}`,
            state.jobPart && `JobPartType=${state.jobPart}`,
            state.payment && `PaymentType=${state.payment}`,
            state.partDesc && `partDesc=${state.partDesc}`,
            state.costTypeCode && `CostType=${state.costTypeCode}`
        ];

        // Filter out undefined or falsey values and join with `&`
        queryParams = queryParams.filter(Boolean).join('&');

        // Construct the final URL
        const url = `VehicleJobItems/VehicleJobs_PartUsage?${queryParams}`;
        return url;
        // let url = `VehicleJobItems/VehicleJobs_PartUsage?startDate=${state.startDate}&endDate=${state.endDate}&custId=${tempOptions.customerIDValue?.id?"custId"}&ServiceAgentID=${tempOptions.serviceAreaIDValue?.id}&TechnicianUserID=${tempOptions.engineerIDValue?.id}&PartType=${state.partType}&Warehouse=${tempOptions.warehouseIDValue?.id}&JobPartType=${state.jobPart}&PaymentType=${state.payment}&partDesc=${state.partDesc}&costTypeCode=${state.costTypeCode}`;
        // setState((st) => ({ ...st, url }));
    };

    const singleSelectChange = (nm) => (ev, val) => {
        if (nm === 'serviceAreaIDValue' && val?.id) PullEngineerList(val?.id);
        if (val) {
            setTempOptions((st) => {
                let newSt = { ...st, [nm]: val };
                if (nm === 'customerIDValue') {
                    newSt.depotsIDValue = null;
                }
                return newSt;
            });
        } else {
            setTempOptions((st) => ({ ...st, [nm]: null }));
        }
    };

    let PullEngineerList = async (serviceAreaID) => {
        if (serviceAreaID) {
            let res = await getJobEnggById(serviceAreaID);
            if (res.success) {
                setState((d) => ({ ...d, filterTechnician: res?.data?.list }));
            }
        }
    };

    useEffect(async () => {
        let isCustSelected;
        let res = await PartUsage_ScreenDropDown();
        setState((st) => ({
            ...st,
            customerList: res.data.customers || [],
            depotsList: res.data.depots || [],
            technician: res.data.technician || [],
            serviceAgents: res.data.serviceAgents || [],
            partTypes: res.data.partTypes || [],
            warehouse: res.data.warehouse || [],
            paymentTypes: res.data.paymentTypes || [],
            costTypes: res.data.costType || [],
            dateFilters: [
                {
                    id: 'S',
                    name: 'Signed Off'
                },
                {
                    id: 'I',
                    name: 'Invoiced'
                },
                {
                    id: 'R',
                    name: 'Reviewed'
                },
                {
                    id: 'B',
                    name: 'Booking Date '
                },
                {
                    id: 'A',
                    name: 'All'
                }
            ]
        }));

        if (props.customerID && props.fromTab) {
            isCustSelected = res.data.customers.find((p) => props.customerID == p.id);
        } else if (isCustomer) {
            isCustSelected = res.data.customers[0];
        }
        setTempOptions((st) => ({ ...st, customerIDValue: isCustSelected }));
    }, []);

    useEffect(() => {
        let fetchData = async () => {
            let res = await getPartUsage(
                state.startDate,
                state.endDate,
                tempOptions.customerIDValue?.id, //cust
                tempOptions.serviceAreaIDValue?.id, //sa
                tempOptions.engineerIDValue?.id, //tech
                state.partType, //part
                tempOptions.warehouseIDValue?.id, //warehoues
                state.jobPart, //job part
                state.payment, //payment
                state.partDesc, //desc
                state.costTypeCode //costtppe
            );

            if (res.success) {
                let deoptList = [...res?.data?.depot].map((m, i) => ({
                    name: m.depot || 'N/A',
                    Total: m.jobTotal,
                    HourTotal: m.labourHour
                }));
                let vmrsDescriptionList = [...res?.data?.vmrsDescription].map((m, i) => ({
                    name: m.vmrsDescription || 'N/A',
                    Total: m.jobTotal,
                    HourTotal: m.labourHours
                }));

                let jobTypeList = [...res?.data?.area]?.map((m) => ({
                    name: m.area || 'N/A',
                    Total: m.jobTotal,
                    HourTotal: m.labourHour
                }));

                let monthYearList = [...res?.data?.monthYear].map((m) => ({
                    name: m.monthYear || 'N/A',
                    Total: m.jobTotal,
                    HourTotal: m.labourHour
                }));
                setState((st) => ({
                    ...st,
                    deoptList: deoptList,
                    jobTypeList: jobTypeList,
                    monthYearList: monthYearList,
                    vmrsDescriptionList: vmrsDescriptionList,
                    url: getURl(),
                    checkCondition: jobTypeList.length > 0 ? true : false
                }));

                // getURl();
            }
        };

        return fetchData();
    }, [
        state.startDate,
        state.endDate,
        tempOptions.customerIDValue,
        tempOptions.serviceAreaIDValue,
        tempOptions.engineerIDValue,
        state.partType,
        tempOptions.warehouseIDValue,
        state.jobPart,
        state.payment,
        state.partDesc,
        state.costTypeCode
    ]);

    const convertData = (res) => {
        setState((st) => ({ ...st, VorList: res.data.list }));
        return {
            records: res.data.list,
            total: res.data.total
        };
    };
    const handleGraph = (res) => {
        setState((st) => ({ ...st, graphData: res }));
    };

    const fetchPartsData = async (val) => {
        if (val.length > 2) {
            const res = await GetAllPartsByPartNumber(null, val);
            setState((st) => ({ ...st, allPartsDataList: res.data.partDetails || [] }));
            handleClick();
        }
    };

    const handlePart = (e) => {
        const { name, value } = e.target;
        fetchPartsData(value);
        setState((st) => ({ ...st, [name]: value, partDesc: '' }));
    };

    const editActionBtn = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div
                onClick={() => {
                    handleEditClick(row);
                }}
                style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}
            >
                {row.reg}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const updatedColumns = useMemo(() => {
        const tempCols = [...columns];
        let actionCol = tempCols.find((element) => element.key === 'reg');
        if (actionCol) {
            actionCol.formatter = editActionBtn;
        }
        return tempCols;
    }, []);

    const patchSelectedRowWrapper = (row) => {
        setState((st) => ({ ...st, partDesc: row.pd, testDesc: row.pd }));
        handleClose();
    };

    return (
        <div className={`${props.fromTab ? '' : 'screen'}`}>
            {props.fromTab ? '' : <BreadCrumbs crumbs={Crumbs} />}

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel required shrink>
                        Service Area
                    </InputLabel>
                    <SingleSelect
                        options={state.serviceAgents}
                        value={tempOptions.serviceAreaIDValue}
                        onChange={singleSelectChange('serviceAreaIDValue')}
                        disabled={props.customerID}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel required shrink>
                        Engineer
                    </InputLabel>
                    <SingleSelect
                        options={state.filterTechnician}
                        value={tempOptions.engineerIDValue}
                        onChange={singleSelectChange('engineerIDValue')}
                        disabled={props.customerID}
                    />
                </Grid>

                {!isCustomer && (
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <InputLabel required shrink>
                            Customer
                        </InputLabel>
                        <SingleSelect
                            options={state.customerList}
                            value={tempOptions.customerIDValue}
                            onChange={singleSelectChange('customerIDValue')}
                            disabled={props.customerID}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel shrink>Part Type</InputLabel>
                    <SelectBox List={state.partTypes || []} name="partType" onChange={inputChange} value={state.partType} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel shrink>Warehouse</InputLabel>
                    <SingleSelect
                        options={state.warehouse}
                        value={tempOptions.warehouseIDValue}
                        onChange={singleSelectChange('warehouseIDValue')}
                        disabled={props.customerID}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel shrink>Job Part Type</InputLabel>
                    <SelectBox List={JobTypeList || []} onChange={inputChange} name="jobPart" value={state.jobPart} />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel shrink>Payment Type</InputLabel>
                    <SelectBox List={state.paymentTypes || []} onChange={inputChange} name="payment" value={state.payment} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel shrink>Cost Type</InputLabel>
                    <SelectBox List={state.costTypes || []} onChange={inputChange} name="costTypeCode" value={state.costTypeCode} />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel shrink>Start Date</InputLabel>
                    <DatePicker value={state.startDate} onChange={inputChange} name="startDate" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel shrink>End Date</InputLabel>
                    <DatePicker value={state.endDate} onChange={inputChange} name="endDate" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <InputLabel shrink>Part Description</InputLabel>
                    <SimpleField value={state.testDesc} onChange={handlePart} name="testDesc" />
                    {anchorEl ? (
                        <DialogComp title={'Part Details'} onClose={handleClose}>
                            {' '}
                            <Grid item xs={12}>
                                <TableContainer className="custom-scroll" style={{ maxHeight: '420px', borderRadius: '0px' }}>
                                    <Table style={{ minWidth: 550 }} aria-label="customized table" stickyHeader>
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell>Part No.</StyledTableCell>
                                                <StyledTableCell>Description</StyledTableCell>
                                                <StyledTableCell width="20%">Cost Price</StyledTableCell>
                                                <StyledTableCell width="20%">Selling Price</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {state?.allPartsDataList.length > 0 ? (
                                                state?.allPartsDataList.map((row, i) => {
                                                    return (
                                                        <StyledTableRow key={row.partID} onClick={() => patchSelectedRowWrapper(row)}>
                                                            <StyledTableCell>{row.pn}</StyledTableCell>
                                                            <StyledTableCell>{row.pd}</StyledTableCell>
                                                            <StyledTableCell>{row.cp}</StyledTableCell>
                                                            <StyledTableCell>{row.sp}</StyledTableCell>
                                                        </StyledTableRow>
                                                    );
                                                })
                                            ) : (
                                                <StyledTableRow>
                                                    <StyledTableCell colSpan={5} height={'100%'}>
                                                        <Alert severity="info">No Record Found</Alert>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </DialogComp>
                    ) : null}
                </Grid>
                <>
                    <Grid item xs={12} md={4} sm={6} lg={3}></Grid>
                    {isCustomer && <Grid item xs={12} md={4} sm={6} lg={3}></Grid>}
                    {!state.checkCondition ? (
                        <Grid item xs={12}>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <h4>No Records Found</h4>
                            </div>
                        </Grid>
                    ) : (
                        <Grid item container spacing={2}>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                                <div style={{ position: 'relative' }}>
                                    <Typography variant="button" style={{ fontWeight: 600 }} color="secondary">
                                        {' '}
                                        Month Year
                                    </Typography>
                                    <Grid container spacing={2} className="pieChart" style={{ marginTop: 0 }}>
                                        <Grid item xs={6}>
                                            <div style={{ height: 230 }}>
                                                {state.monthYearList.length > 0 ? (
                                                    <ResponsiveContainer>
                                                        <PieChart>
                                                            <Pie
                                                                data={state.monthYearList}
                                                                cx="50%"
                                                                cy="50%"
                                                                paddingAngle={3}
                                                                outerRadius={70}
                                                                innerRadius={40}
                                                                // paddingAngle={5}
                                                                // fill="#8884d8"
                                                                dataKey={'Total'}
                                                                // label
                                                            >
                                                                <Tooltip />
                                                                {state.monthYearList.map((entry, index) => (
                                                                    <>
                                                                        <Cell
                                                                            key={`cell-${index}`}
                                                                            fill={COLORS[index % COLORS.length]}
                                                                            style={{ cursor: 'pointer' }}
                                                                            // onClick={() => {
                                                                            //     handleDetailpopUp(entry, 'Month Year');
                                                                            // }}
                                                                        />
                                                                    </>
                                                                ))}
                                                            </Pie>
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                ) : (
                                                    <Grid item container justifyContent="center" alignContent="center">
                                                        <div style={{ width: '100%', textAlign: 'center' }}>
                                                            <h4>No Records Found</h4>
                                                        </div>
                                                    </Grid>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {' '}
                                            <div style={{ position: 'absolute', top: 44, maxHeight: '190px', overflow: 'auto' }} className="custom-scroll">
                                                {state.monthYearList.map((m, i) => {
                                                    let color = COLORS[i];
                                                    let newSt = state.graphData === 1 ? m.HourTotal : m.Total;
                                                    return (
                                                        <div style={{ padding: 0 }}>
                                                            <div style={{ fontSize: 13, display: 'flex', justifyContent: 'flex-start' }}>
                                                                {' '}
                                                                <div style={{ background: color, height: 10, width: 10, marginTop: 4 }}></div>
                                                                <div style={{ fontWeight: 700 }}>&nbsp;{m.name}</div> &nbsp;
                                                                <div style={{ fontSize: 12, marginTop: 2 }}>
                                                                    (
                                                                    <b>
                                                                        {state.graphData === 1
                                                                            ? formatters.ToFixedDigit(newSt)
                                                                            : formatters.CurrencyNumberWithPound(newSt)}
                                                                        {/* {formatters.curr} */}
                                                                        {/* &nbsp;(
                                                                    {m.Total ? (
                                                                        <b>£{m.Total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')})</b>
                                                                    ) : (
                                                                        <>£0.00</>
                                                                    )} */}
                                                                    </b>
                                                                    )
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={9}>
                                <Typography variant="button" style={{ fontWeight: 600 }} color="secondary">
                                    VMRS Description
                                </Typography>
                                <div className="barchart">
                                    {chartData.vmrsList.length > 0 ? (
                                        <ResponsiveContainer>
                                            <BarChart
                                                data={chartData.vmrsList}
                                                margin={{
                                                    top: 20,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 10
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis
                                                    dataKey="name"
                                                    textAnchor="end"
                                                    sclaeToFit="true"
                                                    verticalAnchor="start"
                                                    allowDataOverflow={false}
                                                    interval={0}
                                                    // dy={-5}
                                                    angle={'-20'}
                                                    style={{
                                                        fontSize: 6,
                                                        fontWeight: 700
                                                    }}
                                                />
                                                <YAxis style={{ fontSize: 12 }} />
                                                <Tooltip
                                                    contentStyle={{ background: 'rgba(0,0,0,0.8)', color: 'white', fontSize: 12, fontWeight: 700 }}
                                                    position={{ x: 30, y: 20 }}
                                                />
                                                {/* <Legend /> */}

                                                <Bar dataKey={'Total'} fill={'White'} barSize={40} height={10}>
                                                    {/* <LabelList dataKey={'Total'} content={renderCustomizedLabel} /> */}
                                                    {chartData.vmrsList.map((entry, index) => (
                                                        <Cell
                                                            key={`cell-${index}`}
                                                            fill={barColors[index]}
                                                            // onClick={() => {
                                                            //     handleDetailpopUp(entry, 'VMRS');
                                                            // }}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    ))}
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    ) : (
                                        <Grid item container justifyContent="space-evenly" alignContent="center">
                                            <div style={{ width: '100%', textAlign: 'center' }}>
                                                <h4>No Records Found</h4>
                                            </div>
                                        </Grid>
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                                <div style={{ position: 'relative' }}>
                                    <Typography variant="button" style={{ fontWeight: 600 }} color="secondary">
                                        Area
                                    </Typography>
                                    <Grid container spacing={2} className="pieChart" style={{ marginTop: 0 }}>
                                        <Grid item xs={6}>
                                            <div style={{ height: 230 }}>
                                                {state.jobTypeList.length > 0 ? (
                                                    <ResponsiveContainer>
                                                        <PieChart>
                                                            <Pie
                                                                data={state.jobTypeList}
                                                                cx="50%"
                                                                cy="50%"
                                                                paddingAngle={3}
                                                                outerRadius={70}
                                                                innerRadius={40}
                                                                fill="#8884d8"
                                                                // dataKey="Total"
                                                                dataKey={'Total'}
                                                                // label
                                                            >
                                                                {state.jobTypeList.map((entry, index) => (
                                                                    <>
                                                                        <Cell
                                                                            key={`cell-${index}`}
                                                                            fill={COLORS[index % COLORS.length]}
                                                                            style={{ cursor: 'pointer' }}
                                                                            // onClick={() => {
                                                                            //     handleDetailpopUp(entry, ' Job Type');
                                                                            // }}
                                                                        />
                                                                    </>
                                                                ))}
                                                            </Pie>
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                ) : (
                                                    <Grid item container justifyContent="space-evenly" alignContent="center">
                                                        <div style={{ width: '100%', textAlign: 'center' }}>
                                                            <h4>No Records Found</h4>
                                                        </div>
                                                    </Grid>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div
                                                style={{ position: 'absolute', right: 2, top: 44, maxHeight: '190px', overflow: 'auto' }}
                                                className="custom-scroll"
                                            >
                                                {state.jobTypeList.map((m, i) => {
                                                    let color = COLORS[i];
                                                    let newSt = state.graphData === 1 ? m.HourTotal : m.Total;
                                                    return (
                                                        <div style={{ padding: 0 }}>
                                                            <div style={{ fontSize: 13, display: 'flex', justifyContent: 'flex-start' }}>
                                                                {' '}
                                                                <div style={{ background: color, height: 10, width: 10, marginTop: 4 }}></div>
                                                                <div style={{ fontWeight: 700 }}>&nbsp;{m.name}</div>
                                                                <div style={{ fontSize: 11, marginTop: 2 }}>
                                                                    &nbsp; ({' '}
                                                                    {state.graphData === 1
                                                                        ? formatters.ToFixedDigit(newSt)
                                                                        : formatters.CurrencyNumberWithPound(newSt)}
                                                                    )
                                                                    {/* {m.Total ? (
                                                                        <b>£{m.Total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')})</b>
                                                                    ) : (
                                                                        <>£0.00</>
                                                                    )}
                                                                    ) */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={9}>
                                <div>
                                    <Typography variant="button" className="barHeading" style={{ fontWeight: 600 }} color="secondary">
                                        Depot
                                    </Typography>
                                    <div className="barchart">
                                        {chartData.deoptList.length > 0 ? (
                                            <ResponsiveContainer>
                                                <BarChart
                                                    data={chartData.deoptList}
                                                    margin={{
                                                        top: 20,
                                                        right: 30,
                                                        left: 20,
                                                        bottom: 15
                                                    }}
                                                >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis
                                                        dataKey="name"
                                                        textAnchor="end"
                                                        sclaeToFit="true"
                                                        verticalAnchor="start"
                                                        allowDataOverflow={false}
                                                        interval={0}
                                                        // dy={-5}
                                                        angle={'-20'}
                                                        style={{
                                                            fontSize: 6,
                                                            fontWeight: 700
                                                        }}
                                                    />
                                                    <YAxis style={{ fontSize: 12 }} />
                                                    <Tooltip
                                                        contentStyle={{ background: 'rgba(0,0,0,0.8)', color: 'white', fontSize: 12, fontWeight: 700 }}
                                                        // position={{ x: 30, y: 20 }}
                                                    />
                                                    {/* <Legend /> */}

                                                    <Bar dataKey={'Total'} fill={'White'} barSize={40} height={10}>
                                                        {/* <LabelList dataKey={'Total'} content={renderCustomizedLabel} /> */}
                                                        {chartData.deoptList.map((entry, index) => (
                                                            <Cell
                                                                key={`cell-${index}`}
                                                                fill={barColors[index]}
                                                                // onClick={() => {
                                                                //     handleDetailpopUp(entry, ' Depots');
                                                                // }}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        ))}
                                                    </Bar>
                                                </BarChart>
                                            </ResponsiveContainer>
                                        ) : (
                                            <Grid item container justifyContent="space-evenly" alignContent="center">
                                                <div style={{ width: '100%', textAlign: 'center' }}>
                                                    <h4>No Records Found</h4>
                                                </div>
                                            </Grid>
                                        )}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <GijgoGrid
                                    dataConverter={(res) => convertData(res)}
                                    columns={updatedColumns}
                                    getUrl={state.url}
                                    // showDownload={true}
                                    // downloadBtnName="Download xlsx"
                                    // downloadCustomUrl={() =>
                                    //     GetJobReportingExcelFile({
                                    //         fromDate: state.startDate,
                                    //         toDate: state.endDate,
                                    //         customerID: tempOptions.customerIDValue?.id || '',
                                    //         fileExt: 'xlsx'
                                    //     })
                                    // }
                                />
                            </Grid>
                        </Grid>
                    )}
                </>
                {/* ) : (
                    <Grid item xs={12}>
                        {!isCustomer ? (
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <h4>Select customer to view report data</h4>
                            </div>
                        ) : (
                            <div style={{ width: '100%' }}>
                                <div
                                    style={{
                                        height: 'calc(100vh - 150px)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <CircularProgress />
                                </div>
                            </div>
                        )}
                    </Grid>
                )} */}
            </Grid>
            {state.showDetailsPopup ? (
                <DialogComp maxWidth="sm" title={`${value.heading} Details`} onClose={handleDetailpopUp}>
                    <div style={{ padding: 20 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <b style={{ width: '50%', textAlign: 'left' }}>Name</b> :<b style={{ width: '50%', textAlign: 'Right' }}>{value.name}</b>
                            </Grid>
                            <Grid item xs={12}>
                                <b style={{ width: '50%', textAlign: 'left' }}>Total </b>:<b style={{ width: '50%', textAlign: 'Right' }}>{value.value}</b>
                            </Grid>
                        </Grid>
                    </div>
                </DialogComp>
            ) : null}
        </div>
    );
};

export default PartUsageScreen;
