import {
    Button,
    CircularProgress,
    Grid,
    IconButton,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@material-ui/core';
import { CloudUpload, Delete, Edit, Check } from '@material-ui/icons';
import { useEffect, useMemo, useState } from 'react';
import formatters from '../Grid/Inputformatter';
import { InputText } from '../../Controls';
import DataGrid from '../Grid';

import { CustomIconButton, PrimaryButton, SecondaryButton } from '../Inputs/Buttons/ButtonModal';
import {
    getFilesForConfig,
    uploadFileUsignUploaderConfig,
    updateName,
    deleteUploadedFile,
    downloadUploadedFile,
    FileUploaderSendEmail
} from '../../Services/FileUploaderService';
import { getConfigIdFromName } from './FileUploaderConfig';
import { useToast } from '../../Hooks/useToast';
import { useLoginInfo } from '../../Hooks/useLoginInfo';
import DialogComp from '../../Dialog/DialogComp';
import { AppStorage } from '../../Basic/storage-service';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import SingleDocumentViewer from './DocumentViewer';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CustomeEditor from '../CkEditor';
import EmailIcon from '@material-ui/icons/Email';

const StyledTableRow = withStyles((theme) => ({
    root: {
        // '&:hover': {
        //     backgroundColor: 'grey',
        //     color: 'white'
        // },
        cursor: 'pointer'
    }
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        // padding: ' 5px',
        fontSize: 18,
        fontWeight: 600,
        backgroundColor: '#bdbdbd82'
    },
    body: {
        fontSize: 14
        // padding: ' 5px'
        // fontSize: 11,
    }
}))(TableCell);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
        border: '1px solid lightgrey'
    }
    // container: {
    //     maxHeight: 440
    // }
});

// const basicColumns = [
//     {
//         key: 'FileName',
//         name: 'Name',
//         flex: 2
//     },
//     // {
//     //     key: 'FileExt',
//     //     name: 'Type',
//     //     width: 80
//     // },
//     {
//         key: 'FileSize',
//         name: 'Size',
//         width: 90,
//         renderCell: (params) => <div style={{ display: 'flex', alignItems: 'center' }}>{params.value}</div>
//     }
//     // {
//     //     key: 'DownloadCount',
//     //     name: 'Downloads',
//     //     width: 100
//     // },
//     // {
//     //     key: 'UploadedBy',
//     //     name: 'Uploaded By',
//     //     width: 200
//     // },
//     // {
//     //     key: 'UploadedAt',
//     //     name: 'Uploaded At',
//     //     width: 200,
//     //     formatter: formatters.Date_DDMMMYYYY_HHMM
//     // }
// ];

const UploaderModal = ({ recordId, configName, useToast: ut, useLoginInfo: uli, gridRelaod, onClose, fileUploaderModalClose, docIDPassed }) => {
    const classes = useStyles();
    let checkhaveAccess = AppStorage.getAllowAcces();
    let AccessConfig = {
        ScheduleDocuments: checkhaveAccess?.CanUploadScheduleDocuments,
        JobScreen: checkhaveAccess?.CanUploadJobDocuments,
        CustomerScreen: checkhaveAccess?.CanUploadCustomerDocuments
    };

    let getAccessValue = (screenName) => {
        if (['ScheduleDocuments', 'JobScreen', 'CustomerScreen'].includes(screenName)) {
            return AccessConfig[`${screenName}`];
        } else {
            return true;
        }
    };
    let useToastRef = useToast();
    let useLoginInfoRef = useLoginInfo();
    let { showToastSuccess, showToastError } = ut || useToastRef;

    let { isReadOnlyUser } = uli || useLoginInfoRef;
    const [state, setState] = useState({
        fileName: '',
        selectedFile: undefined,
        loading: true,
        uploading: false,
        inEdit: false,
        inDelete: false,
        fileToAction: undefined,
        currentFiles: [],
        fileUploaderModalClose: fileUploaderModalClose || false,
        slectedFileName: '',
        slectedFileExt: ''
    });
    const getFiles = (isReload) => {
        getFilesForConfig(getConfigIdFromName(configName), recordId, docIDPassed).then((res) => {
            if (res.success) {
                setState((st) => ({ ...st, loading: false, currentFiles: res.data }));
                isReload && gridRelaod && gridRelaod();
            }
        });
    };
    useEffect(() => {
        getFiles();
    }, []);

    const onFileSelect = (e) => {
        const { files } = e.target;
        if (files.length) {
            setState((st) => ({ ...st, fileName: files[0].name, selectedFile: files[0] }));
        }
    };

    const uploadSelectedFile = async () => {
        if (state.fileName && state.selectedFile) {
            setState((st) => ({ ...st, uploading: true }));
            const formData = new FormData();
            formData.append(state.fileName, state.selectedFile);
            let res = await uploadFileUsignUploaderConfig(getConfigIdFromName(configName), recordId, formData, docIDPassed);
            if (res.success) {
                showToastSuccess(res.message);
                setState((st) => ({ ...st, uploading: false, fileName: '', selectedFile: undefined }));
                !state.fileUploaderModalClose && getFiles(true);
                state.fileUploaderModalClose && onClose(true);
            } else {
                showToastError(res.message);
                setState((st) => ({ ...st, uploading: false }));
            }
        }
    };

    const showDeleteConfirm = (row) => (e) => {
        e.stopPropagation();
        setState((st) => ({
            ...st,
            inDelete: true,
            fileToAction: {
                uniqFileName: row.UniqueFileName,
                fileName: row.FileName
            }
        }));
    };

    const editName = (row) => (e) => {
        e.stopPropagation();
        setState((st) => ({
            ...st,
            inEdit: true,
            fileToAction: {
                uniqFileName: row.UniqueFileName,
                fileName: row.FileName,
                fileExt: row.fileExt
            }
        }));
    };
    const emailHandler = (row) => (e) => {
        e.stopPropagation();
        setState((st) => ({
            ...st,
            openEmailModal: true,
            fileToAction: {
                uniqFileName: row.UniqueFileName,
                fileName: row.FileName,
                fileExt: row.FileExt
            }
        }));
    };

    const hideModal = (type) => () => {
        setState((st) => ({ ...st, [type]: false, fileToAction: undefined, imageList: '', slectedFileName: '', slectedFileExt: '' }));
    };

    // const actionButtons = (params) => {
    //     return (
    //         <div>
    //             {getAccessValue(`${configName}`) ? <CustomIconButton icon={Edit} iconFontSize={18} onClick={editName(params.row)} /> : ''}
    //             <CustomIconButton icon={GetAppIcon} iconFontSize={18} onClick={downloadFile(params.row)} />
    //             {checkhaveAccess?.CanDeleteDocuments && <CustomIconButton icon={Delete} iconFontSize={18} onClick={showDeleteConfirm(params.row)} />}
    //         </div>
    //     );
    // };

    const modifyCurrentFileName = (e) => {
        const { value } = e.target;
        setState((st) => ({ ...st, fileToAction: { ...st.fileToAction, fileName: value } }));
    };

    const updateFileToServer = async () => {
        let res = await updateName(getConfigIdFromName(configName), recordId, state.fileToAction.uniqFileName, state.fileToAction.fileName, docIDPassed);
        if (res.success) {
            // showToastSuccess(res.message);
            setState((st) => ({ ...st, inEdit: false, fileToAction: undefined }));
            getFiles(true);
        } else {
            showToastError(res.message);
        }
    };

    const confirmDelete = async () => {
        let res = await deleteUploadedFile(getConfigIdFromName(configName), recordId, state.fileToAction.uniqFileName);
        if (res.success) {
            // showToastSuccess(res.message);
            setState((st) => ({ ...st, inDelete: false, fileToAction: undefined }));
            getFiles(true);
        } else {
            showToastError(res.message);
        }
    };

    const downloadFile = (row) => async (e) => {
        let success = await downloadUploadedFile(
            getConfigIdFromName(configName),
            recordId,
            row.UniqueFileName,
            `${row.FileName}${row.FileExt}`,
            docIDPassed,
            true,
            row.FileExt
        );
        if (success) {
            getFiles(true);
        } else {
            showToastError(`Something went wrong, can't download file.`);
        }
    };

    const ViewDownloadFile = (row) => async (e) => {
        setState((st) => ({ ...st, showDocument: true }));
        let success = await downloadUploadedFile(
            getConfigIdFromName(configName),
            recordId,
            row.UniqueFileName,
            `${row.FileName}${row.FileExt}`,
            docIDPassed,
            false,
            row.FileExt
        );
        if (success) {
            setState((st) => ({ ...st, imageList: success, slectedFileExt: row.FileExt, slectedFileName: row.FileName }));
        } else {
            showToastError(`Something went wrong, can't download file.`);
        }
    };

    // const gridColumns = useMemo(() => {
    //     const cols = [...basicColumns];
    //     if (!isReadOnlyUser) {
    //         cols.push({
    //             name: '',
    //             width: 90,
    //             formatter: actionButtons
    //         });
    //     }
    //     const fn = cols.find((c) => c.key === 'FileName');
    //     if (fn) {
    //         fn.formatter = (params) => {
    //             return (
    //                 <div style={{ width: '100%', padding: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
    //                     <div>
    //                         <a href="javascript:0" onClick={ViewDownloadFile(params.row)} style={{}}>
    //                             {params.row.FileName}
    //                         </a>
    //                     </div>
    //                     <div
    //                         style={{
    //                             fontSize: '12px',
    //                             color: 'grey',
    //                             textAlign: 'right',
    //                             verticalAlign: ''
    //                         }}
    //                     >
    //                         {params.row.UploadedBy} @ {moment(params.row.UploadedAt).format('LLL')}
    //                     </div>
    //                 </div>
    //             );
    //         };
    //     }
    //     return cols;
    // }, [isReadOnlyUser]);

    return (
        <div style={{ width: '80vw' }}>
            {!isReadOnlyUser && (
                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        <InputText disabled value={state.fileName || 'No file selected'} label="File to Upload" />
                    </Grid>
                    <Grid item>
                        <Button color="secondary" variant="contained" component="label" disabled={!getAccessValue(`${configName}`)}>
                            Select File
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={onFileSelect}
                                accept="image/*, application/pdf, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button disabled={!state.selectedFile || state.uploading} onClick={uploadSelectedFile} color="secondary" variant="outlined">
                            {state.uploading ? <CircularProgress size={24} color="secondary" /> : <CloudUpload />}
                        </Button>
                    </Grid>
                </Grid>
            )}
            <br />
            {/* <DataGrid loadingData={state.loading} rows={state.currentFiles} columns={gridColumns} /> */}

            <TableContainer style={{ height: 'calc(100vh - 190px)' }}>
                <Table className={classes.table} size="small" stickyHeader>
                    <TableHead>
                        <TableRow className="header">
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell width="20%" align="right"></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.currentFiles.map((q) => {
                            return (
                                <StyledTableRow>
                                    <StyledTableCell>
                                        <div>
                                            <a href="javascript:0" onClick={ViewDownloadFile(q)} style={{}}>
                                                {q.FileName}
                                            </a>
                                        </div>
                                        <div
                                            style={{
                                                fontSize: '12px',
                                                color: 'grey',
                                                // textAlign: 'right',
                                                verticalAlign: ''
                                            }}
                                        >
                                            {q.UploadedBy} @ {moment(q.UploadedAt).format('LLL')} {`(${q.FileSize})`}
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {getAccessValue(`${configName}`) ? <CustomIconButton icon={Edit} iconFontSize={18} onClick={editName(q)} /> : ''}
                                        <CustomIconButton icon={GetAppIcon} iconFontSize={18} onClick={downloadFile(q)} />
                                        {checkhaveAccess?.CanDeleteDocuments && (
                                            <CustomIconButton icon={Delete} iconFontSize={18} onClick={showDeleteConfirm(q)} />
                                        )}
                                        <CustomIconButton icon={EmailIcon} iconFontSize={18} onClick={emailHandler(q)} />
                                    </StyledTableCell>
                                </StyledTableRow>
                            );
                        })}

                        {state.currentFiles?.length === 0 && (
                            <TableRow>
                                <TableCell colSpan="4">{'No record found.'}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {state.inEdit && (
                <DialogComp title="Rename" onClose={hideModal('inEdit')}>
                    <InputText label="New Name" value={state.fileToAction.fileName} onChange={modifyCurrentFileName} autoFocus={true}></InputText>
                    <SecondaryButton className="mt-20" onClick={updateFileToServer}>
                        <Check /> Save
                    </SecondaryButton>
                </DialogComp>
            )}
            {state.inDelete && (
                <DialogComp title="Confirm Delete" onClose={hideModal('inDelete')}>
                    <Typography variant="h5" color="secondary">
                        Are you sure you want to delete?
                    </Typography>
                    <PrimaryButton className="mt-20" onClick={hideModal('inDelete')}>
                        No, Close
                    </PrimaryButton>
                    <SecondaryButton className="mt-20 mb-20" onClick={confirmDelete}>
                        <Delete /> Yes, Delete
                    </SecondaryButton>
                </DialogComp>
            )}
            {state.showDocument && (
                <DialogComp title={`Document - ${state.slectedFileName}`} onClose={hideModal('showDocument')} maxWidth="xl" fullWidth fullScreen>
                    <SingleDocumentViewer imageList={state.imageList || []} slectedFileExt={state.slectedFileExt} />
                </DialogComp>
            )}
            {state.openEmailModal && (
                <MailCommonComponent
                    onClose={hideModal('openEmailModal')}
                    fileToAction={state.fileToAction}
                    showToastSuccess={showToastSuccess}
                    showToastError={showToastError}
                />
            )}
        </div>
    );
};

export default UploaderModal;

const MailCommonComponent = (props) => {
    let [state, setState] = useState({
        toEmail: '',
        subject: '',
        body: '',
        uniqueFileName: '',
        fileName: '',
        fileExt: ''
    });

    const [btnLoader, setBtnLoader] = useState(false);
    const handleCkeditorstate = (name) => (event, editor) => {
        const data = editor.getData();
        setState((st) => ({ ...st, [name]: data }));
    };

    const handleChange = (e) => {
        const { name, checked, value } = e.target;

        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const submitHandler = async () => {
        let data = {
            toEmail: state.toEmail,
            subject: state.subject,
            body: state.body,
            uniqueFileName: props.fileToAction.uniqFileName,
            fileName: props.fileToAction.fileName,
            fileExt: props.fileToAction.fileExt
        };
        try {
            setBtnLoader(true);
            let res = await FileUploaderSendEmail(data);
            if (res.success) {
                props.showToastSuccess && props.showToastSuccess(res.message);
                setBtnLoader(false);
                props.onClose(true);
            } else {
                props.showToastError && props.showToastError(res.message);
                setBtnLoader(false);
            }
        } catch (error) {
            setBtnLoader(false);
            props.showToastError(`An error occurred: ${error.message}`);
        }
    };

    return (
        <DialogComp title={`Email - ${props.fileToAction.fileName}`} onClose={() => props.onClose(false)} fullWidth maxWidth="md">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputLabel shrink>Email</InputLabel>
                    <InputText placeholder="Email" onChange={handleChange} name="toEmail" value={state.toEmail} autoFocus={true} />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel shrink>Email Subject</InputLabel>
                    <InputText placeholder="Email Subject" onChange={handleChange} name="subject" value={state.subject} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <InputLabel shrink>Email Template</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomeEditor
                                data={state.body}
                                onChange={handleCkeditorstate('body')}
                                placeholder="Add description here"
                                name="body"
                                style={{ height: 200 }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <SecondaryButton fullWidth onClick={submitHandler} isBtnLoader={btnLoader} disabled={!state.toEmail && btnLoader}>
                        Submit
                    </SecondaryButton>
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs></Grid>
            </Grid>
        </DialogComp>
    );
};
