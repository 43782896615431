import { postWithAuth, getWithAuth, putWithAuth } from '../../Basic/HttpService';

export const getjobByType = async (
    start,
    end,
    Id,
    serviceID,
    enggID,
    DepotID,
    makeID,
    modelID,
    vehicleTypeID,
    vehicleCategoryID,
    ownershipTypeID,
    dateFilterID,
    vehicleAgeID,
    costType
) => {
    let res = await getWithAuth(
        `customers/GetCustomerJobSpend?custid=${Id || ''}&startDate=${start}&endDate=${end}&ServiceAgentID=${serviceID}&TechnicianUserID=${enggID}
        &DepotID=${DepotID}
        &make=${makeID}
        &model=${modelID}
        &vehicleType=${vehicleTypeID}
        &vehicleCategory=${vehicleCategoryID}
        &ownershipType=${ownershipTypeID}
        &dateFilter=${dateFilterID}
        &vehicleAge=${vehicleAgeID}&CostTypeCode=${costType}
        `
    );
    // let res = await getWithAuth(`customers/GetCustomerJobSpend?startDate=2020-03-01&endDate=2022-06-28&custId=2`);
    return res;
};

export const getPartUsage = async (
    startDate,
    endDate,
    custId,
    ServiceAgentID,
    TechnicianUserID,
    partType,
    warehouse,
    JobPartType,
    paymentType,
    partDesc,
    costTypeCode
) => {
    const url =
        `VehicleJobItems/Widget_GetPartsSpend?` +
        [
            startDate && `startDate=${startDate}`,
            endDate && `endDate=${endDate}`,
            custId && `custId=${custId}`,
            ServiceAgentID && `ServiceAgentID=${ServiceAgentID}`,
            TechnicianUserID && `TechnicianUserID=${TechnicianUserID}`,
            partType && `PartType=${partType}`,
            warehouse && `Warehouse=${warehouse}`,
            JobPartType && `JobPartType=${JobPartType}`,
            paymentType && `PaymentType=${paymentType}`,
            partDesc && `partDesc=${partDesc}`,
            costTypeCode && `CostType=${costTypeCode}`
        ]
            .filter(Boolean) // Remove undefined or falsey values
            .join('&'); // Join valid parameters with "&"

    let res = await getWithAuth(url);

    return res;
};
