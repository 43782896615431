import { postWithAuth, getWithAuth, putWithAuth } from '../../Basic/HttpService';

export const getPartsDetails = async (id) => {
    let res = await getWithAuth(`AdminParts/Parts_GetDetails/${id}`);
    return res;
};

export const getWareHouse_DropDowns = async (id) => {
    let res = await getWithAuth(`AdminParts/WareHouse_DropDowns`);
    return res;
};

export const postParts = async (data) => {
    let res = await postWithAuth(`AdminParts/Part_AddUpdate`, data);
    return res;
};
export const getWarehouseParts = async (id, InStock) => {
    let res = await getWithAuth(`AdminParts/GetWarehouseParts?id=${id}&InStock=${InStock}`);
    return res;
};

export const PartToBeOrdered = async (data) => {
    let res = await postWithAuth(`StandardLabourLines/PartToBeOrdered`, data);
    return res;
};

export const PartRequest_SendRequest = async (data) => {
    let res = await postWithAuth(`AdminParts/PartRequest_SendRequest`, data);
    return res;
};

export const PartStockAdjustment_SendRequest = async (data) => {
    let res = await postWithAuth(`AdminParts/PartStockAdjustment_SendRequest`, data);
    return res;
};

export const PartPurchased_Request = async (PartID, Quantity, PartDescription, PartNumber, data) => {
    let res = await postWithAuth(`AdminParts/PartPurchased_Request/${PartID}/${Quantity}/${PartDescription || ''}/${PartNumber || ''}`, data);
    return res;
};

export const getPartStockAdjustments_GetNotes = async (id) => {
    let res = await getWithAuth(`AdminParts/PartStockAdjustments_GetHistory${id ? `?PartID=${id}` : ''}`);
    return res;
};

export const getPartPurchasedRequest_GetHistory = async (id) => {
    let res = await getWithAuth(`AdminParts/PartPurchasedRequest_GetHistory${id ? `?PartID=${id}` : ''}`);
    return res;
};

export const getPartRequests_GetNotes = async (id) => {
    let res = await getWithAuth(`AdminParts/PartRequests_GetHistory${id ? `?PartID=${id}` : ''}`);
    return res;
};

export const getPartReceived_GetHistory = async (id) => {
    let res = await getWithAuth(`AdminParts/PartReceived_GetHistory${id ? `?PartID=${id}` : ''}`);
    return res;
};
