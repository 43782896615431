import React, { useEffect, useState, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, FormHelperText, Typography, Divider } from '@material-ui/core';
import {
    SecondaryButton,
    SelectBox,
    formatters,
    MandatoryField,
    SingleSelect,
    SimpleField,
    DatePicker,
    SecondaryCheckbox
} from '../../../../../../Core/Controls';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import '../../../../../commonStyle.scss';
import {
    GetSalesInvoice,
    postVehicleJobsCompleteJob_NoRecharge,
    SalesInvoice_ChangePerformaToInvoice,
    SalesInvoiceAddHeaderLabourPart
} from '../../../../../../Core/Services/InternalUser/JOB/jobItemsService';
import { useLoginInfo } from '../../../../../../Core/Hooks/useLoginInfo';
import moment from 'moment';
import { some } from 'lodash';
//import './jobstyle.scss';
import { useWindowSize } from '../../../../../../Core/Hooks/useWindowSize';
import SalesLines from './sellerLines';
import RetorqueReasons from '../../../../../VOR/RetorqueReasonsScreen/RetorqueReasons';
function AddJobItemModal(props) {
    const [width, height] = useWindowSize();
    const [state, setState] = useState({
        invoiceLines: [],
        vatRate: [],
        scheduleDocs: [],
        vehicleDetails: [],
        partsDetails: [],
        purchaseInvoiceDate: moment().format('YYYY-MM-DD'),
        purchaseInvoiceDateDue: '',
        purchaseNominalCode: '',
        errors: {},
        invoiceItemVATRateID: { id: 2, name: '20.0% S', vatRatePercent: 20 }

        // vatRate: null
    });

    useEffect(() => {
        if (state.invoiceLines.length > 0) props.showPerformaInvoice(true);
    }, [state.invoiceLines]);
    useEffect(() => {
        props.showOpenLine(state.vehicleDetails[0]);
    }, [state.vehicleDetails]);

    const { userRoles } = useLoginInfo();

    const isServiceAgent = userRoles.includes('servicearea');

    const [btnLoader, setBtnLoader] = useState(false);

    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        let res1 = await GetSalesInvoice(props.scheduleJobID);
        if (res1.success) {
            props.setAPIScheduleDocEmail(res1.data.scheduleDocs[0] || {}, res1.data.scheduleDocs.length > 0);
            setState((st) => ({
                ...st,
                vatRate: res1.data.vatRate,
                scheduleDocs: res1.data.scheduleDocs,
                vehicleDetails: res1.data?.vehicleDetails || [],
                invoiceLines: res1.data.invoiceLines.map((m) => ({ ...m, invoiceItemVATRateID: null, invoiceItemVAT: null, isSelected: true })) || [],
                partsDetails: res1.data.partsDetails.map((m) => ({ ...m, invoiceItemVATRateID: null, invoiceItemVAT: null, isSelected: true })),
                iSDropDownUpdate: true
            }));
            props.stopLoader();
        }
    }, []);

    const inputChange = (e) => {
        const { name, value, type } = e.target;
        if (name === 'purchaseInvoiceDate') {
            let dueDate = moment(value).add(state.vehicleDetails[0]?.paymentDays, 'd');
            setState((st) => ({ ...st, [name]: value, purchaseInvoiceDateDue: moment(dueDate).format('YYYY-MM-DD') }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    };
    const percentage = (num, per) => {
        return (num / 100) * per;
    };
    const getFixedDigit = (val) => {
        return val ? val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0;
    };
    const onInvoiceItemChange = (ev, val) => {
        if (val) {
            let invoiceLines = state.invoiceLines.map((x) => ({
                ...x,
                invoiceItemVAT: getFixedDigit(percentage(x.labourExVAT, val.vatRatePercent)),
                invoiceItemVATRateID: val
            }));
            let changedArray = state.partsDetails.map((x) => ({
                ...x,
                invoiceItemVAT: getFixedDigit(percentage(x.total, val.vatRatePercent)),
                invoiceItemVATRateID: val
            }));
            setState((state) => {
                const nst = { ...state, invoiceItemVATRateID: val, partsDetails: changedArray, invoiceLines: invoiceLines };
                return nst;
            });
        }
    };

    useEffect(() => {
        if (state.invoiceLines.length > 0) {
            let invoiceLines = state.invoiceLines.map((x) => ({
                ...x,
                invoiceItemVAT: getFixedDigit(percentage(x.labourExVAT, state.invoiceItemVATRateID.vatRatePercent)),
                invoiceItemVATRateID: state.invoiceItemVATRateID
            }));
            setState((state) => {
                const nst = { ...state, invoiceLines: invoiceLines };
                return nst;
            });
        }
        if (state.partsDetails.length > 0) {
            let changedArray = state.partsDetails.map((x) => ({
                ...x,
                invoiceItemVAT: getFixedDigit(percentage(x.total, state.invoiceItemVATRateID.vatRatePercent)),
                invoiceItemVATRateID: state.invoiceItemVATRateID
            }));
            setState((state) => {
                const nst = { ...state, partsDetails: changedArray };
                return nst;
            });
        }
    }, [state.iSDropDownUpdate, state.invoiceItemVATRateID]);

    const invoiceLinesCheckField = (num, i) => (e, val) => {
        const { name, checked, type, value } = e.target;
        let changedArray = [...state.invoiceLines];
        let objIndex = state.invoiceLines.findIndex((x) => x.itemID == i);
        if (type?.toLowerCase() === 'text' || type.toLowerCase() === 'number') {
            changedArray[objIndex][name] = value;
            if (name == 'labourHours' || name == 'labourRate' || name == 'labourDiscount') {
                let data = changedArray[objIndex];
                const total1 = (data['labourHours'] * data['labourRate'] * (100 - data['labourDiscount'] || 0)) / 100;
                data['labourExVAT'] = getFixedDigit(total1);
            }
        } else if (val) {
            changedArray[objIndex]['invoiceItemVATRateID'] = val;
            changedArray[objIndex]['invoiceItemVAT'] = getFixedDigit(percentage(num, val.vatRatePercent));
        }
        setState((state) => {
            const nst = { ...state, invoiceLines: changedArray };
            return nst;
        });
    };

    const invoiceLinesCheckBox = (i) => (e) => {
        const { name, checked } = e.target;
        let changedArray = [...state.invoiceLines];
        let objIndex = state.invoiceLines.findIndex((x) => x.itemID == i);
        changedArray[objIndex][name] = checked;
        setState((state) => {
            const nst = { ...state, invoiceLines: changedArray };
            return nst;
        });
    };
    const PartSelectChange = (num, id) => (ev, val) => {
        let changedArray = [...state.partsDetails];
        let objIndex = state.partsDetails.findIndex((x) => x.partID == id);
        if (val) {
            let calPercentage = percentage(num, val.vatRatePercent);
            changedArray[objIndex]['invoiceItemVATRateID'] = val;
            changedArray[objIndex]['invoiceItemVAT'] = getFixedDigit(calPercentage);
            setState((state) => {
                const nst = { ...state, partsDetails: changedArray };
                return nst;
            });
        }
    };

    const handleFields = (i) => (e) => {
        const { name, value, checked, type } = e.target;
        let changedArray = [...state.partsDetails];
        let objIndex = state.partsDetails.findIndex((x) => x.partID == i);
        if (type.toLowerCase() === 'text' || type.toLowerCase() === 'number') {
            changedArray[objIndex][name] = value;
            if (name == 'qty' || name == 'unitPrice' || name == 'discount') {
                let data = changedArray[objIndex];
                const total1 = (data['qty'] * data['unitPrice'] * (100 - data['discount'] || 0)) / 100;
                data['total'] = getFixedDigit(total1);
            }
        } else {
            changedArray[objIndex][name] = checked;
        }

        setState((state) => {
            const nst = { ...state, partsDetails: changedArray };
            return nst;
        });
    };

    // let validation = () => {
    //     let isvalid = true;
    //     if (!props.salesInvoiceStatus) {
    //         showToastError("")
    //         isvalid = true;
    //     }
    //     return isvalid;
    // };

    const handelSubmit = async () => {
        setBtnLoader(true);
        // if (validation()) {
        let salesLabourItems = state.invoiceLines
            .filter((m) => m.isSelected)
            .map((m) => ({
                jobItemID: m.itemID,
                labourVATRateID: m.invoiceItemVATRateID?.id,
                labourHours: m.labourHours,
                labourRate: m.labourRate,
                labourDescription: m.labourDescription,
                labourDiscount: m.labourDiscount,
                labourVATPercent: m.invoiceItemVATRateID?.vatRatePercent,
                labourVATAmount: m.invoiceItemVAT,
                labourCost: m.labourExVAT
            }));
        let salesPartsItems = state.partsDetails
            .filter((m) => m.isSelected)
            .map((m) => ({
                jobItemID: m.jobItemID,
                partNumber: m.partNumber,
                partDescription: m.partDescription,
                partQuantity: m.qty,
                partUnitPrice: m.unitPrice,
                partDiscount: m.discount,
                partVATRateID: m.invoiceItemVATRateID?.id,
                partVATPercent: m.invoiceItemVATRateID?.vatRatePercent,
                partVATAmount: m.invoiceItemVAT
            }));
        let formData = {
            salesLabourItems: salesLabourItems,
            salesPartsItems: salesPartsItems,
            // salesInvoiceNumber: state.purchaseInvoiceNumber,
            salesInvoiceDate: state.purchaseInvoiceDate,
            salesInvoiceDateDue: state.purchaseInvoiceDateDue,
            salesInvoiceJobID: props.scheduleJobID,
            jobApprovedOrderNo: props.jobApprovedOrderNo,
            salesInvoiceStatus: props.salesInvoiceStatus,
            sendFiles: state.scheduleDocs.map((q) => ({
                uniqueFileName: q.uniqueFileName,
                fileExt: q.fileExt,
                fileName: q.fileName,
                scheduleDocID: q.scheduleDocID
            })),
            jobCustomerID: props.customerID,
            sendEmailTo: props.sendEmailTo,
            emailEventDoc: props.emailEventDoc
        };

        let res = {};
        if (!props.isShowPerformaInvoice) {
            console.log('NO Recharge lines');
            res = await postVehicleJobsCompleteJob_NoRecharge({ jobID: props.scheduleJobID });
        } else if (props.salesInvoiceStatus === 'P' || !props.isProformaCreated) {
            console.log('proforma or create invoice without proforma ');
            res = await SalesInvoiceAddHeaderLabourPart(formData);
        } else if (props.salesInvoiceStatus === 'I') {
            console.log('sales invoice');
            res = await SalesInvoice_ChangePerformaToInvoice(formData);
        } else {
            showToastError('Something went wrong');
        }
        if (res.success) {
            setBtnLoader(false);
            showToastSuccess(res.data.message);
            if (props.handleSubmitValue) props.handleSubmitValue();
        } else {
            showToastError(res.message);
        }
        setBtnLoader(false);
        // }
    };

    console.log(props.mismatchedLines, 'state.mismatchedLines');
    return (
        // <div style={{ height: '100%' }}>

        <div style={{ height: '100%' }}>
            {!props.loader && (
                <>
                    {!props.hideDetailBar && (
                        <Grid container spacing={0} className="mt_10">
                            <Grid item xs={12} lg={12}>
                                <Grid item container xs={12} style={{ backgroundColor: '#f5f9f9', padding: '0 5px' }}>
                                    <Grid item xs={6} sm={3}>
                                        <FormHelperText shrink>Reg Number</FormHelperText>
                                        <Typography variant="subtitle2">{state.vehicleDetails[0]?.regNumber || 'N/A'}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <FormHelperText shrink>Order No</FormHelperText>
                                        <Typography variant="subtitle2">{state.vehicleDetails[0]?.orderRef || 'N/A'}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <FormHelperText shrink>Customer</FormHelperText>
                                        <Typography variant="subtitle2">{state.vehicleDetails[0]?.customer || 'N/A'}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <FormHelperText shrink>Booked Date</FormHelperText>
                                        <Typography variant="subtitle2">{formatters.DateOnlyFormatter(state.vehicleDetails[0]?.bookedDate)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={1}>
                        {!props.hideInputs && (
                            <>
                                <Grid item xs={12} sm={3} lg={3} className="mandatory-fields">
                                    <InputLabel shrink required>
                                        Invoice Date
                                    </InputLabel>
                                    <DatePicker
                                        placeholder="Invoice Date"
                                        value={state.purchaseInvoiceDate}
                                        onChange={inputChange}
                                        name="purchaseInvoiceDate"
                                    />
                                    <FormHelperText error>{state.errors.purchaseInvoiceDate}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={3} lg={3} className="mandatory-fields">
                                    <InputLabel shrink required>
                                        Due Date
                                    </InputLabel>
                                    <DatePicker
                                        placeholder="Due Date"
                                        value={state.purchaseInvoiceDateDue}
                                        onChange={inputChange}
                                        name="purchaseInvoiceDateDue"
                                    />
                                    <FormHelperText error>{state.errors.purchaseInvoiceDateDue}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} lg={2}>
                                    <InputLabel shrink>{'\u{2800}'}</InputLabel>
                                    <SecondaryButton onClick={handelSubmit} isBtnLoader={btnLoader}>
                                        Submit
                                    </SecondaryButton>
                                </Grid>
                            </>
                        )}

                        {props.disabled && (
                            <Grid item xs={12}>
                                <InputLabel shrink>{'\u{2800}'}</InputLabel>
                                {state.vehicleDetails[0]?.openLines || props.mismatchedLines ? null : (
                                    <SecondaryButton onClick={handelSubmit} isBtnLoader={btnLoader} disabled={btnLoader}>
                                        {props.salesInvoiceStatus == 'P' && !state.vehicleDetails[0].isProforma
                                            ? 'Create proforma'
                                            : props.salesInvoiceStatus == 'I'
                                            ? 'Create Invoice'
                                            : props.salesInvoiceStatus == 'P' && !state.invoiceLines.length > 0
                                            ? 'Submit and Close Job'
                                            : 'Submit'}
                                    </SecondaryButton>
                                )}
                            </Grid>
                        )}

                        <Grid item xs={12} style={{ height: `calc(100vh - 500px)` }} className="custom-scroll">
                            <SalesLines
                                invoiceLinesCheckBox={invoiceLinesCheckBox}
                                invoiceLinesCheckField={invoiceLinesCheckField}
                                PartSelectChange={PartSelectChange}
                                handleFields={handleFields}
                                invoiceLines={state.invoiceLines}
                                partsDetails={state.partsDetails}
                                vatRate={state.vatRate}
                                onInvoiceItemChange={onInvoiceItemChange}
                                invoiceItemVATRateID={state.invoiceItemVATRateID}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
            {/* <div style={{ paddingBottom: 5 }}>
                    
                </div> */}
        </div>
        // </div>
    );
}

export default AddJobItemModal;
