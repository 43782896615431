import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { getEngineerDashboard } from '../../Core/Services/Dashboard';
import moment from 'moment';

const LazyComponent = lazy(() => import('./dashboardUserInterface'));

const MainDashboard = (props) => (
    <Suspense fallback={<div>please Wait...</div>}>
        <LazyComponent {...props} />
    </Suspense>
);

const TechDashboard = () => {
    const [dashboardData, setDashboardData] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state

    const singleCardData = [
        {
            name: 'Not Started ',
            value: dashboardData.jobsNotStarted,
            // background: 'linear-gradient(110deg, #fc4a1a 0%,#eb856a 100%)',
            path: '/serviceagent/OJ',
            // params: { vehicleJobStatus: 'Not Started' },
            search: `?vehicleJobStatus=Not Started`,
            valueColor: '#0096FF',
            xl: 1
        },
        { name: 'On Hire ', value: dashboardData.onHireService, path: '/Contracts_Hire_Fleet', params: { status: 'Vehicle Onsite' }, xl: 1 },
        { name: 'Off Hire ', value: dashboardData.offHireService, path: '/Contracts_Hire_Fleet', params: { status: 'Off Hire' }, xl: 1 },
        {
            name: 'Breakdown',
            value: dashboardData.breakdowns,
            // background: 'linear-gradient(110deg, #F00000 0%,#f27272 100%)',
            path: '/maintenance/breakdowns/:all',
            valueColor: 'red',
            xl: 1
        },
        {
            name: 'VOR',
            value: dashboardData.vor,
            //  background: 'linear-gradient(110deg, #fc4a1a 0%,#eb856a 100%)',
            path: '/controller/vor',
            valueColor: 'red',
            xl: 1
        }

        // {
        //     innerComponent: [
        //         { name: 'On Hire ', value: dashboardData.onHireService, path: '/Contracts_Hire_Fleet', params: { status: 'Vehicle Onsite' } },
        //         { name: 'Off Hire ', value: dashboardData.offHireService, path: '/Contracts_Hire_Fleet', params: { status: 'Off Hire' } }
        //     ],

        //     lg: 3,
        //     isInnerComp: true
        // },
        // {
        //     innerComponent: [
        //         {
        //             name: 'Breakdown',
        //             value: dashboardData.breakdowns,
        //             // background: 'linear-gradient(110deg, #F00000 0%,#f27272 100%)',
        //             path: '/maintenance/breakdowns/:all',
        //             valueColor: 'red'
        //         },
        //         {
        //             name: 'VOR',
        //             value: dashboardData.vor,
        //             //  background: 'linear-gradient(110deg, #fc4a1a 0%,#eb856a 100%)',
        //             path: '/controller/vor',
        //             valueColor: 'red'
        //         }
        //     ],
        //     lg: 3,
        //     isInnerComp: true
        // }
    ];

    const singleGrpupCardData = [
        {
            title: 'Overview',
            objList: [
                { name: 'All jobs', value: dashboardData.jobsTotal, path: '/serviceagent/OJ', params: { vehicleJobStatus: '' } },

                {
                    name: 'Breakdown',
                    value: dashboardData.breakdowns,
                    // background: 'linear-gradient(110deg, #F00000 0%,#f27272 100%)',
                    path: '/maintenance/breakdowns/:all',
                    valueColor: 'red',
                    params: {},
                    xl: 1
                },
                {
                    name: 'VOR',
                    value: dashboardData.vor,
                    //  background: 'linear-gradient(110deg, #fc4a1a 0%,#eb856a 100%)',
                    path: '/controller/vor',
                    valueColor: 'red',
                    params: {},
                    xl: 1
                },

                {
                    name: 'Onhires',
                    value: dashboardData.onHireService,
                    path: '/schedule/confirmedbookings',
                    search: `?fromDash=true&scheduleEventName=On hire`,
                    params: {},
                    xl: 1
                },
                {
                    name: 'Offhires',
                    value: dashboardData.offHireService,
                    path: '/schedule/confirmedbookings',
                    search: `?fromDash=true&scheduleEventName=Off Hire`,
                    params: {},
                    xl: 1
                }
            ],
            itemSize: {
                lg: 4,
                md: 4,
                sm: 4,
                xs: 'auto'
            }
        }
    ];

    const jobCard = [
        {
            title: 'JOBS',
            headBackground: '#FA7070',
            // background: 'linear-gradient(110deg,#1D976C 40%,#93F9B9 100%)',

            // path: '/serviceagent/OJ',
            items: [
                {
                    label: 'Not Started ',
                    value: dashboardData.jobsNotStarted,
                    // background: 'linear-gradient(110deg, #fc4a1a 0%,#eb856a 100%)',
                    path: '/serviceagent/OJ',
                    // params: { vehicleJobStatus: 'Not Started' },
                    search: `?vehicleJobStatus=Not Started`,
                    valueColor: '#0096FF',
                    params: {},
                    xl: 1
                },
                {
                    label: 'Awaiting Parts',
                    value: dashboardData.jobsAwaitingParts,
                    path: '/serviceagent/OJ',
                    // params: { vehicleJobStatus: 'Awaiting Parts' },
                    search: `?showAllRecords=3&vehicleJobStatus=Awaiting Parts`,
                    params: {}
                },
                {
                    label: 'Paused',
                    value: dashboardData.jobsPaused,
                    path: '/serviceagent/OJ',
                    // params: { vehicleJobStatus: 'Paused' },
                    search: `?showAllRecords=3&vehicleJobStatus=Paused`,
                    params: {}
                },
                {
                    label: 'Work in progress',
                    value: dashboardData.jobsWIP,
                    path: '/serviceagent/OJ',
                    // params: { vehicleJobStatus: 'Work In Progress' },
                    search: `?showAllRecords=3&vehicleJobStatus=Work In Progress`,
                    params: {}
                }
            ],
            cardSize: {
                lg: 6,
                md: 12,
                sm: 12,
                xs: 12
            },
            itemSize: {
                lg: 6,
                md: 3,
                sm: 3,
                xs: 3
            }
        }
    ];
    const groupCardData = [
        // {
        //     title: 'JOBS',
        //     headBackground: '#FA7070',
        //     // background: 'linear-gradient(110deg,#1D976C 40%,#93F9B9 100%)',

        //     // path: '/serviceagent/OJ',
        //     items: [
        //         { label: 'All jobs', value: dashboardData.jobsTotal, path: '/serviceagent/OJ', params: { vehicleJobStatus: '' } },
        //         {
        //             label: 'Awaiting Parts',
        //             value: dashboardData.jobsAwaitingParts,
        //             path: '/serviceagent/OJ',
        //             // params: { vehicleJobStatus: 'Awaiting Parts' },
        //             search: `?showAllRecords=3&vehicleJobStatus=Awaiting Parts`
        //         },
        //         {
        //             label: 'Paused',
        //             value: dashboardData.jobsPaused,
        //             path: '/serviceagent/OJ',
        //             // params: { vehicleJobStatus: 'Paused' },
        //             search: `?showAllRecords=3&vehicleJobStatus=Paused`
        //         },
        //         {
        //             label: 'Work in progress',
        //             value: dashboardData.jobsWIP,
        //             path: '/serviceagent/OJ',
        //             // params: { vehicleJobStatus: 'Work In Progress' },
        //             search: `?showAllRecords=3&vehicleJobStatus=Work In Progress`
        //         }
        //     ],
        //     cardSize: {
        //         lg: 3,
        //         md: 12,
        //         sm: 12,
        //         xs: 12
        //     },
        //     itemSize: {
        //         lg: 6,
        //         md: 3,
        //         sm: 3,
        //         xs: 3
        //     }
        // },
        {
            title: 'EVENTS',
            // path: '/schedule/confirmedbookings',
            items: [
                {
                    label: 'Overdue',
                    value: dashboardData.scheduleOverdue,
                    path: `/schedule/confirmedbookings`,
                    search: `?fromDash=true&end=${moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')}`,
                    valueColor: 'red',
                    params: {}
                },
                {
                    label: 'This Week',
                    value: dashboardData.scheduleThisWeek,
                    path: `/schedule/confirmedbookings`,
                    params: {},
                    search: `?fromDash=true&start=${moment().startOf('isoWeek').format('YYYY-MM-DD')}&end=${moment().endOf('isoWeek').format('YYYY-MM-DD')}`
                },
                {
                    label: 'Next Week',
                    value: dashboardData.scheduleNextWeek,
                    path: `/schedule/confirmedbookings`,
                    params: {},
                    search: `?fromDash=true&start=${moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')}&end=${moment()
                        .add(1, 'weeks')
                        .endOf('week')
                        .format('YYYY-MM-DD')}`
                },
                {
                    label: 'Future',
                    value: dashboardData.scheduleFuture,
                    path: `/schedule/confirmedbookings`,
                    params: {},
                    search: `?fromDash=true&start=${moment().add(2, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')}`
                }
            ],
            cardSize: {
                lg: 3,
                md: 12,
                sm: 12,
                xs: 12
            },
            itemSize: {
                lg: 6,
                md: 3,
                sm: 3,
                xs: 3
            }
        },
        {
            title: 'Allocations',
            // background: 'linear-gradient(110deg,#1D976C 40%,#93F9B9 100%)',
            // path: '/Contracts_Hire_Fleet',
            // itemSize: {
            //     lg: 12,
            //     md: 12,
            //     sm: 12,
            //     xs: 6
            // },

            items: [
                { label: 'Hire Vehicles', value: dashboardData.hireActive, path: '/Contracts_Hire_Fleet', params: {} },
                { label: 'Contract Maintenance', value: dashboardData.contractMaintenanceActive, path: '/Contracts_Maintenance', params: {} }
            ],
            cardSize: {
                lg: 3,
                md: 12,
                sm: 12,
                xs: 12
            },
            itemSize: {
                lg: 12,
                md: 6,
                sm: 3,
                xs: 6
            }
        },
        {
            title: 'WAREHOUSE',
            path: '/parts',
            items: [
                { label: 'Parts', value: dashboardData.warehouseQty },
                { label: 'On Order', value: dashboardData.warehouseOnOrder },
                { label: 'In Transit', value: dashboardData.warehouseInTransit },
                { label: 'Back Order', value: dashboardData.warehouseBackOrder }
            ],
            itemSize: {
                lg: 6,
                md: 3,
                sm: 3,
                xs: 3
            },
            cardSize: {
                lg: 3,
                md: 12,
                sm: 12,
                xs: 12
            }
        }
    ];

    const pullDashboardData = useCallback(async () => {
        try {
            setLoading(true); // Start loading
            const res = await getEngineerDashboard();

            if (res.success) {
                setDashboardData(res.data.dashboard[0]);
            } else {
                console.warn('Unexpected response:', res);
            }
        } catch (error) {
            console.error('API Error:', error.response.data);
        } finally {
            setLoading(false); // Stop loading once data is fetched
        }
    }, []);

    useEffect(() => {
        pullDashboardData();
    }, [pullDashboardData]);

    return (
        <div style={{ padding: 10 }}>
            <MainDashboard
                // singleCardData={singleCardData}
                jobCard={jobCard}
                groupCardData={groupCardData}
                loading={loading}
                pullDashboardData={pullDashboardData}
                singleServiceGrpupCardData={singleGrpupCardData}
            />
        </div>
    );
};

export default TechDashboard;
