import InputText from './index';
import { InputLabel, FormHelperText, Typography, withStyles } from '@material-ui/core';
import './inputStyle.scss';

const GlobalCss = withStyles((theme) => ({
    '@global': {
        '@media (max-width: 1024px)': {
            ' .MuiOutlinedInput-inputMarginDense': {
                // paddingTop: '5px',
                padding: '5px'
            }
        }
    }
}))(() => null);
export function MandatoryField(props) {
    const { inputLabel, isDisabled, isAvoidValidation, ...rest } = props;
    return isDisabled ? (
        <div className="input-style">
            <InputLabel shrink className="input_label">
                {inputLabel}
            </InputLabel>
            <div className="input_value">{props.value === null ? 'N/A' : props.value}</div>
        </div>
    ) : (
        <>
            <GlobalCss />
            <InputLabel required shrink error={!isAvoidValidation}>
                {inputLabel}
            </InputLabel>

            <InputText {...rest} />
            <FormHelperText error>{rest.errors}</FormHelperText>
        </>
    );
}

function SimpleField(props) {
    const { inputLabel, isDisabled, ...rest } = props;
    return isDisabled ? (
        <div className="input-style">
            <InputLabel shrink className="input_label">
                {inputLabel}
            </InputLabel>
            <div className="input_value">{props.value === null ? 'N/A' : props.value}</div>
        </div>
    ) : (
        <>
            <GlobalCss />
            <InputLabel shrink>{inputLabel}</InputLabel>
            <InputText {...rest} />
        </>
    );
}
export default SimpleField;
