import { TextField, Tooltip, FormHelperText, Typography, InputLabel, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useLoginInfo } from '../../Hooks/useLoginInfo';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import './Buttons/ButtonModal.scss';
import { makeStyles } from '@material-ui/core';
import { useWindowSize } from '../../Hooks/useWindowSize';

const useStyles = makeStyles((theme) => ({
    chipBtnContainer: {
        height: 34,
        width: (props) => props.width || 'auto',
        justifyContent: 'flex-start',
        backgroundColor: (props) => `${props.backgroundColour || '#e0e0e0'}`,
        color: (props) => props.color || 'black',
        '&:hover, &:focus': {
            backgroundColor: (props) => props.backgroundColour || '#bdbdbd'
        },
        '@media (max-width: 1280px)': {
            height: 24,
            width: '100% !important'
        },
        '& .MuiAvatar-colorDefault': {
            backgroundColor: (props) => props.backgroundColour || '#bdbdbd',
            filter: 'brightness(90%)'
        },
        '& .MuiChip-label': {
            fontSize: '14px',
            '@media (max-width: 1280px)': {
                fontSize: '12px'
            }
        },
        '& .MuiChip-avatar ': {
            width: 24,
            height: 24,
            backgroundColor: (props) => (props.iconBg ? `${props.iconBg}` : ''),

            '@media (max-width: 1280px)': {
                width: 20,
                height: 20
            }
        }
    },
    icon: {
        fontSize: (props) => (props.winWidth > 1024 ? props.iconFontSize || 15 : 12),
        color: (props) => props.iconColor || 'black'
    }
}));

export const DatePicker = (props) => {
    const { forceEditable, value, onChange, isDisabled, inputLabel, isRequired, ...rest } = props;

    let [state, setState] = useState({ date: null });

    useEffect(() => {
        setState((st) => ({ ...st, date: props.value == 'Invalid date' ? '--' : props.value }));
    }, [props.value]);

    const handeDateChange = (e) => {
        const { value } = e.target;
        if (value < '2099-12-31') {
            setState((st) => ({ ...st, date: value }));
            props.onChange(e);
        }
    };

    let { isReadOnlyUser } = useLoginInfo();
    forceEditable && (isReadOnlyUser = false);
    return isDisabled ? (
        <div className="input-style">
            <InputLabel shrink className="input_label">
                {inputLabel}
            </InputLabel>
            <div className="input_value">{state.date || 'N/A'}</div>
        </div>
    ) : (
        <>
            {inputLabel && (
                <InputLabel required={isRequired || false} shrink>
                    {inputLabel}
                </InputLabel>
            )}
            <TextField
                id="date"
                size="small"
                variant="outlined"
                type="date"
                fullWidth
                disabled={isReadOnlyUser}
                inputProps={{
                    max: '2099-12-31'
                }}
                onChange={handeDateChange}
                value={state.date}
                {...rest}
                InputLabelProps={{
                    shrink: true
                }}
            />
        </>
    );
};

export const DateTimePicker = (props) => {
    const { forceEditable, value, onChange, ...rest } = props;
    let { isReadOnlyUser } = useLoginInfo();
    forceEditable && (isReadOnlyUser = false);
    let [state, setState] = useState({ date: null });

    useEffect(() => {
        setState((st) => ({ ...st, date: props.value }));
    }, [props.value]);

    const handeDateChange = (e) => {
        const { value } = e.target;
        if (value < '2099-12-31T00:00') {
            setState((st) => ({ ...st, date: value }));
            props.onChange(e);
        }
    };
    return (
        <TextField
            id="datetime-local"
            size="small"
            variant="outlined"
            type="datetime-local"
            fullWidth
            disabled={isReadOnlyUser}
            inputProps={{
                max: '2099-12-31T00:00'
            }}
            onChange={handeDateChange}
            value={state.date}
            {...rest}
            InputLabelProps={{
                shrink: true
            }}
        />
    );
};

export const CustomChip = (props) => {
    const classes = useStyles();
    const { forceEditable, ...rest } = props;
    let { isReadOnlyUser } = useLoginInfo();
    const Icon = props.icon;
    const iconFontSize = props.iconFontSize;
    forceEditable && (isReadOnlyUser = false);
    return (
        <Chip
            className={classes.chipBtnContainer}
            avatar={<Avatar>{Icon ? <Icon style={{ fontSize: iconFontSize || 20 }} /> : ''}</Avatar>}
            disabled={isReadOnlyUser}
            {...rest}
        />
    );
};
export const CustomButtonChip = (props) => {
    const [winWidth, heights] = useWindowSize();
    const { forceEditable, color, backgroundColour, iconColor, iconBg, width, ...rest } = props;
    let { isReadOnlyUser } = useLoginInfo();
    const Icon = props.icon;
    const iconFontSize = props.iconFontSize;
    forceEditable && (isReadOnlyUser = false);
    const [triggerRerender, setTriggerRerender] = useState(0);
    const classes = useStyles({ color, backgroundColour, width, iconFontSize, iconColor, iconBg, winWidth });

    useEffect(() => {
        setTriggerRerender((prev) => prev + 1);
    }, [color, backgroundColour, iconColor, iconBg]);

    return (
        <Chip
            key={triggerRerender}
            className={classes.chipBtnContainer}
            avatar={<Avatar>{Icon ? <Icon className={classes.icon} /> : ''}</Avatar>}
            disabled={isReadOnlyUser}
            {...rest}
        />
    );
};

export const ChipWithoutLable = (props) => {
    const { forceEditable, toolTipTitle, onClick, ...rest } = props;
    const [show, setShow] = useState(false);
    let { isReadOnlyUser } = useLoginInfo();
    const Icon = props.icon;
    const iconFontSize = props.iconFontSize;
    forceEditable && (isReadOnlyUser = false);
    return (
        <Tooltip title={toolTipTitle} arrow open={show} disableHoverListener onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
            <Chip
                avatar={<Avatar className="chip-without-lable">{Icon ? <Icon style={{ fontSize: iconFontSize || 20 }} /> : ''}</Avatar>}
                disabled={isReadOnlyUser}
                onClick={() => {
                    setShow(false);
                    if (onClick) return onClick();
                }}
                {...rest}
            />
        </Tooltip>
    );
};
