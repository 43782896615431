import { useEffect, useState } from 'react';
import { Badge, IconButton, Tooltip } from '@material-ui/core';
import { PermMedia } from '@material-ui/icons';
import DialogComp from '../../Dialog/DialogComp';
import UploaderModal from './UploaderModal';
import { CustomIconButton } from '../Inputs/Buttons/ButtonModal';
import { Link } from 'react-router-dom';
import '../Inputs/Buttons/ButtonModal.scss';
import { useLoginInfo } from '../../Hooks/useLoginInfo';
import { useToast } from '../../Hooks/useToast';
import { AppStorage } from '../../Basic/storage-service';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { CustomButtonChip, CustomChip } from '../Inputs/DatePicker';

const FileUploaderButton = ({
    fileCount,
    recordId,
    fileUploaderConfigName,
    ut,
    uli,
    gridRelaod,
    fileUploaderModalClose,
    docIDPassed,
    isDash,
    docName,
    width,
    ...props
}) => {
    const [state, setState] = useState({
        showUploader: false,
        recordId,
        fileCount: `${fileCount || 0}`,
        configName: fileUploaderConfigName
    });
    let checkhaveAccess = AppStorage.getAllowAcces();
    useEffect(() => {
        if (fileCount) {
            setState((st) => ({
                fileCount: fileCount || 0
            }));
        }
    }, [fileCount]);

    const hideFileUploader = (res) => {
        setState((st) => ({ ...st, showUploader: false }));
        res && gridRelaod();
    };

    const showFileUploader = () => {
        setState((st) => ({ ...st, showUploader: true }));
    };

    const MySvgIcon = (rest) => (
        <div style={{ height: rest.height || 5, width: rest.width || 5, cursor: 'pointer' }} {...rest}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                width="100%"
                height="100%"
                viewBox="0 0 256 256"
                xmlSpace="preserve"
            >
                <defs></defs>
                <g
                    style={{
                        stroke: 'none',
                        strokeWidth: 0,
                        strokeDasharray: 'none',
                        strokeLinecap: 'butt',
                        strokeLinejoin: 'miter',
                        strokeMiterlimit: 10,
                        fill: 'none',
                        fillRule: 'nonzero',
                        opacity: 1
                    }}
                    transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                >
                    <path
                        d="M 73.538 35.162 l -52.548 1.952 c -1.739 0 -2.753 0.651 -3.232 2.323 L 6.85 76.754 c -0.451 1.586 -2.613 2.328 -4.117 2.328 h 0 C 1.23 79.082 0 77.852 0 76.349 l 0 -10.458 V 23.046 v -2.047 v -6.273 c 0 -2.103 1.705 -3.808 3.808 -3.808 h 27.056 c 1.01 0 1.978 0.401 2.692 1.115 l 7.85 7.85 c 0.714 0.714 1.683 1.115 2.692 1.115 H 69.73 c 2.103 0 3.808 1.705 3.808 3.808 v 1.301 C 73.538 26.106 73.538 35.162 73.538 35.162 z"
                        style={{
                            stroke: 'none',
                            strokeWidth: 1,
                            strokeDasharray: 'none',
                            strokeLinecap: 'butt',
                            strokeLinejoin: 'miter',
                            strokeMiterlimit: 10,
                            fill: rest.color ? 'rgb(224,173,49)' : 'rgba(0,0,0,0.4)',
                            fillRule: 'nonzero',
                            opacity: 1
                        }}
                        transform="matrix(1 0 0 1 0 0)"
                        strokeLinecap="round"
                    />
                    <path
                        d="M 2.733 79.082 L 2.733 79.082 c 1.503 0 2.282 -1.147 2.733 -2.733 l 10.996 -38.362 c 0.479 -1.672 2.008 -2.824 3.748 -2.824 h 67.379 c 1.609 0 2.765 1.546 2.311 3.09 L 79.004 75.279 c -0.492 1.751 -1.571 3.818 -3.803 3.803 C 75.201 79.082 2.733 79.082 2.733 79.082 z"
                        style={{
                            stroke: 'none',
                            strokeWidth: 1,
                            strokeDasharray: 'none',
                            strokeLinecap: 'butt',
                            strokeLinejoin: 'miter',
                            strokeMiterlimit: 10,
                            fill: rest.color ? 'rgb(255,200,67)' : 'rgba(0,0,0,0.6)',
                            fillRule: 'nonzero',
                            opacity: 1
                        }}
                        transform="matrix(1 0 0 1 0 0)"
                        strokeLinecap="round"
                    />
                </g>
            </svg>
        </div>
    );
    const MySvgIconButton = (rest) => (
        <div style={{ height: rest.height || 10, cursor: 'pointer', textAlign: 'center' }} {...rest}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                width="200%"
                height="150%"
                viewBox="250 50 256 256"
                xmlSpace="preserve"
                style={{ marginLeft: 5 }}
            >
                <defs></defs>
                <g
                    style={{
                        stroke: 'none',
                        strokeWidth: 0,
                        strokeDasharray: 'none',
                        strokeLinecap: 'butt',
                        strokeLinejoin: 'miter',
                        strokeMiterlimit: 10,
                        fill: 'none',
                        fillRule: 'nonzero',
                        opacity: 1
                    }}
                    transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                >
                    <path
                        d="M 73.538 35.162 l -52.548 1.952 c -1.739 0 -2.753 0.651 -3.232 2.323 L 6.85 76.754 c -0.451 1.586 -2.613 2.328 -4.117 2.328 h 0 C 1.23 79.082 0 77.852 0 76.349 l 0 -10.458 V 23.046 v -2.047 v -6.273 c 0 -2.103 1.705 -3.808 3.808 -3.808 h 27.056 c 1.01 0 1.978 0.401 2.692 1.115 l 7.85 7.85 c 0.714 0.714 1.683 1.115 2.692 1.115 H 69.73 c 2.103 0 3.808 1.705 3.808 3.808 v 1.301 C 73.538 26.106 73.538 35.162 73.538 35.162 z"
                        style={{
                            stroke: 'none',
                            strokeWidth: 1,
                            strokeDasharray: 'none',
                            strokeLinecap: 'butt',
                            strokeLinejoin: 'miter',
                            strokeMiterlimit: 10,
                            fill: rest.color ? 'rgb(224,173,49)' : 'rgba(0,0,0,0.4)',
                            fillRule: 'nonzero',
                            opacity: 1
                        }}
                        transform="matrix(1 0 0 1 0 0)"
                        strokeLinecap="round"
                    />
                    <path
                        d="M 2.733 79.082 L 2.733 79.082 c 1.503 0 2.282 -1.147 2.733 -2.733 l 10.996 -38.362 c 0.479 -1.672 2.008 -2.824 3.748 -2.824 h 67.379 c 1.609 0 2.765 1.546 2.311 3.09 L 79.004 75.279 c -0.492 1.751 -1.571 3.818 -3.803 3.803 C 75.201 79.082 2.733 79.082 2.733 79.082 z"
                        style={{
                            stroke: 'none',
                            strokeWidth: 1,
                            strokeDasharray: 'none',
                            strokeLinecap: 'butt',
                            strokeLinejoin: 'miter',
                            strokeMiterlimit: 10,
                            fill: rest.color ? 'rgb(255,200,67)' : 'rgba(0,0,0,0.6)',
                            fillRule: 'nonzero',
                            opacity: 1
                        }}
                        transform="matrix(1 0 0 1 0 0)"
                        strokeLinecap="round"
                    />
                </g>
            </svg>
        </div>
    );

    return (
        <>
            {/* <CustomIconButton icon={MySvgIcon} onClick={showFileUploader}>
                <Badge style={{ marginTop: -10 }} className="mx-10" badgeContent={state.fileCount} />
            </CustomIconButton> */}
            {checkhaveAccess?.CanViewDocuments && (
                <>
                    {isDash ? (
                        <Badge badgeContent={state.fileCount} className="file-upload-badge" color="secondary" max={999}>
                            <CustomButtonChip
                                label={docName ? docName : 'Photo/upload'}
                                icon={MySvgIconButton}
                                iconFontSize={10}
                                onClick={showFileUploader}
                                width={width}
                                // {...props}
                            />
                        </Badge>
                    ) : state.fileCount > 0 ? (
                        <Badge className="file-upload-badge" badgeContent={state.fileCount} color="secondary" showZero={false}>
                            <MySvgIcon onClick={showFileUploader} width={25} height={20} color={state.fileCount > 0} />
                        </Badge>
                    ) : (
                        <MySvgIcon onClick={showFileUploader} width={25} height={20} color={state.fileCount > 0} />
                    )}
                </>
            )}
            {state.showUploader && (
                <DialogComp title="Document Manager" onClose={() => hideFileUploader(false)} maxWidth="xl">
                    <UploaderModal
                        recordId={recordId}
                        configName={fileUploaderConfigName}
                        useToast={ut}
                        useLoginInfo={uli}
                        gridRelaod={gridRelaod}
                        fileUploaderModalClose={fileUploaderModalClose || false}
                        onClose={hideFileUploader}
                        docIDPassed={docIDPassed}
                    />
                </DialogComp>
            )}
        </>
    );
};

export default FileUploaderButton;
