import React, { useState, useEffect, useMemo } from 'react';
import { deleteLabourpartsJobsItem, getAllJobItems } from '../../../../../../Core/Services/InternalUser/JOB/jobItemsService';
import {
    getJobDetailsById,
    getJobRequiredDocuments,
    postJobMileage,
    setBookingDate
} from '../../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import DataGrid from '../../../../../../Core/Controls/Grid';
import { AddButton, CustomChip, DatePicker, EditButton, formatters, SimpleField } from '../../../../../../Core/Controls';
import AddLabourModal from './addJobItem';
import '../../../../../commonStyle.scss';
import DialogComp from '../../../../../../Core/Dialog/DialogComp';
import Grid from '@material-ui/core/Grid';
import { some } from 'lodash';
import SellerInvoiceModal from '../../JOBItems/JobInvoiceLines/sellerInvoice';
import PurchaseInvoice from '../../JOBItems/JobInvoiceLines/purchaseInvoice';
import JobItemHeaderDates from './../../JOBItems/jobItemHeaderDates';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import JobQueriesScreen from '../../jobQueriesScreen';
import AddIcon from '@material-ui/icons/Add';
import UpdateIcon from '@material-ui/icons/Update';
import _ from 'lodash';
import UpcomingEvents from '../../../Vehicles/ScheduleVehicle/upcomingEvents';
import { CustomIconButton, DeleteButton, SecondaryButton } from '../../../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { AppStorage } from '../../../../../../Core/Basic/storage-service';
import BuildIcon from '@material-ui/icons/Build';
import VehicleDailyChecks from '../../../Vehicles/VehicleDailyChecks/vehicleDailyChecks';
import EditVehicleDailyChecks from '../../../Vehicles/VehicleDailyChecks/editVehicleDailyCheck';
import CompleteScheduleModal from '../../../../Schedule/completeSchedule';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, withStyles } from '@material-ui/core';
import moment from 'moment';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import LabourLineTableComp from './labourLineTable';
import GrainIcon from '@material-ui/icons/Grain';
import AddVor from '../../../VOR/AddEditVor';
import SearchIcon from '@material-ui/icons/Search';
import SetJobMileageScreen from './setJobMileage';
import { useLoginInfo } from '../../../../../../Core/Hooks/useLoginInfo';
import ConfirmationModal from '../../../../../../Core/Modal/ConfirmationModal';
import DoneIcon from '@material-ui/icons/Done';
import { CustomButtonChip } from '../../../../../../Core/Controls/Inputs/DatePicker';
import { useWindowSize } from '../../../../../../Core/Hooks/useWindowSize';

const alertBoxColorCode = {
    AA: 'warning',
    EP: 'warning',
    JP: 'warning',
    AU: 'error',
    JQ: 'error',
    FR: 'error',
    JD: 'error',
    JR: 'error',
    CA: 'success',
    JC: 'success',
    JA: 'success'
};

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: '#f5f5f5',
            color: 'white'
        },
        cursor: 'pointer'
    }
}))(TableRow);

const StyledRow = withStyles((theme) => ({
    head: {
        fontSize: 12,
        fontWeight: 700,
        padding: 5,
        backgroundColor: 'lightgrey',
        lineHeight: '0.5rem'
    }
}))(TableCell);
const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 12,
        fontWeight: 700,
        padding: 5,
        backgroundColor: 'lightgrey'
    },
    body: {
        fontSize: 12,
        padding: 5,
        fontWeight: 500
    }
}))(TableCell);

const base_columns = [
    {
        key: 'jobItemDescription',
        name: 'Description'
    },
    {
        key: 'status',
        name: 'Status',
        width: 100
    },
    {
        key: 'hours',
        name: 'Hours',
        width: 60
    },
    {
        key: 'costType',
        name: 'Cost type',
        width: 80
    },

    {
        key: 'chargeable',
        name: 'Chargeable',
        width: '7%',
        align: 'right'
    },
    {
        key: 'nonchargeable',
        name: 'Econ',
        width: '10%',
        align: 'right'
    },
    {
        key: 'action',
        name: '',
        width: '1%'
    }
];

function InternalJobItem(props) {
    const { jobDetails, setJobDetails } = props;
    let checkhaveAccess = AppStorage.getAllowAcces();
    const { showToastSuccess, showToastError } = useToast();
    const [width, height] = useWindowSize();

    let checkisMobile = width < 560;
    let checkisTab = width < 1680;
    const [state, setState] = useState({
        jobItemsList: [],
        loader: true,
        open: false,
        jobStatusID: '',
        showInvoice: false,
        refresherFlag: new Date(),
        errors: {},
        showOk: true,
        showSellerBtn: true,
        showSellerInvoice: false,
        showCreate: false,
        allowUpdate: false,
        selectedJobItemID: null,
        isGridRefresh: null,
        tableCol: [...base_columns]
    });
    const { userRoles } = useLoginInfo();

    useEffect(() => {
        setState((st) => ({ ...st, allowUpdate: jobDetails.isEditableFields }));
    }, [jobDetails.isEditableFields]);

    const isEngineer = userRoles.includes('engineer');

    useEffect(async () => {
        pullJobItems();
    }, [state.refresherFlag, jobDetails.jobStatusID]);

    const pullJobItems = async () => {
        let res = await getAllJobItems(props.scheduleJobID);
        let res2 = res.data.vehicleJobItems;

        setState((state) => {
            const nst = { ...state };
            nst.jobItemsList = res2;
            nst.loader = false;
            nst.isGridRefresh = new Date();
            nst.checkApprovedJob = some(res.data.vehicleJobItems, (r) => r.jobItemStatusID == 'A');

            return nst;
        });
    };

    useEffect(() => {
        let col = [];
        if (isEngineer) {
            col = base_columns.filter((col) => col.key !== 'chargeable' && col.key !== 'nonchargeable');
        } else {
            col = [...base_columns];
        }

        setState((st) => ({
            ...st,
            tableCol: col
        }));
    }, [isEngineer]);

    const showVorModal = (res) => {
        setJobDetails((st) => ({
            ...st,
            [res]: true
        }));
    };

    const closeVORModal = (res) => {
        setJobDetails((st) => ({
            ...st,
            showVORPopup: false,
            showSaModal: false
        }));
        res && refreshScreen(true);
    };

    const btnHandler = (params) => {
        if (params.jobItemID) {
            setState((state) => ({
                ...state,
                open: true,
                jobItemID: params.jobItemID,
                vehicleID: params.vehicleID,
                vehicleRegNumber: params.vehicleRegNumber,
                allowUpdate: params.allowUpdate === 'Y' ? true : false,
                isCopied: params.copied === 'Y' ? true : false
            }));
        } else {
            setState((state) => ({
                ...state,
                open: true
            }));
        }
    };

    const addLaburLineHandler = (params) => {
        setState((state) => ({ ...state, openLaburLine: true }));
    };
    const addJobMileageModal = (params) => {
        setState((state) => ({ ...state, showJobMileageModal: true }));
    };
    const closeLaburLineHandler = (res) => {
        setState((state) => ({ ...state, openLaburLine: false }));
        res && refreshScreen();
    };

    const createInvoiceHandler = (name) => {
        setState((state) => ({ ...state, [name]: true }));
    };

    const handleOpen = (row) => {
        setState((st) => ({ ...st, showCancelConfirmModal: !st.showCancelConfirmModal, selectedJobItemID: row.jobItemID }));
    };

    const closeCheck = () => {
        setState((st) => ({
            ...st,
            showChecks: false,
            showEditChecks: false,
            complete: false
        }));
        props.pullJobDetail();
        pullJobItems();
    };
    const handleDeletePart = async () => {
        let res = await deleteLabourpartsJobsItem(state.selectedJobItemID);
        if (res.success) {
            showToastSuccess('Job item removed successfully.');
            pullJobItems();
            setState((st) => ({ ...st, showCancelConfirmModal: false, selectedJobItemID: null }));
        } else {
            showToastError(res.message);
        }

        // pullApiData();
    };

    const handleCheck = (ev, val) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.selectedRowData = ev;
            if (val === 'Ad') {
                newSt.showChecks = true;
            }
            if (val === 'Ed') {
                newSt.showEditChecks = true;
            }
            if (val === 'complete') {
                newSt.complete = true;
            }
            return newSt;
        });
    };

    const ActionButtons = (data) => {
        return (
            <div style={{ display: 'flex' }}>
                {/* <EditButton toolTipTitle="Edit Job Item" onClick={() => btnHandler(data.row)} /> */}

                {/* {data.row?.displayCompleteSchedule === 'Y' && (
                    <CustomIconButton
                        icon={CheckCircleIcon}
                        className="modal-btn-update btn_space"
                        color="primary"
                        onClick={() => {
                            handleCheck(data.row, 'complete');
                        }}
                        toolTipTitle="Complete schedule"
                    />
                )} */}
                {/* {data.row.allowUpdate === 'Y'
                    ? data.row?.displayAddInspectionCheck === 'Y' &&
                      checkhaveAccess?.CanAddInspections && (
                          <CustomIconButton
                              icon={BuildIcon}
                              color="primary"
                              onClick={() => {
                                  handleCheck(data.row, 'Ad');
                              }}
                              toolTipTitle="Add Inspection Check"
                          />
                      )
                    : null}

                {data.row.allowUpdate === 'Y'
                    ? data.row?.displayEditInspectionCheck === 'Y' && (
                          <CustomIconButton
                              icon={BuildIcon}
                              color="secondary"
                              onClick={() => {
                                  handleCheck(data.row, 'Ed');
                              }}
                              toolTipTitle="Edit Inspection Check"
                          />
                      )
                    : null} */}
                {checkhaveAccess?.CanDeleteJobLines
                    ? data.row.canDeleteLine === 'Y' && <DeleteButton onClick={() => handleOpen(data.row)} toolTipTitle="Delete Job Item" />
                    : null}
            </div>
        );
    };

    const valueChanged = (ev) => {
        let temp = ev.target;
        setJobDetails((st) => ({
            ...st,
            [temp.name]: temp.value
        }));
    };

    const handleMileage = async () => {
        let data = {
            jobID: props.scheduleJobID,
            jobMileage: jobDetails.jobMileage,
            jobMileageDate: jobDetails.jobMileageDate
        };
        let res = await postJobMileage(data);
        if (res.success) {
            props.pullJobDetail();
            showToastSuccess('Job Mileage and Job Date Added Successfully ');
        } else {
            showToastError(res.message);
        }
    };

    let checkStatus = jobDetails.isEditableFields;

    const closeModal = (res) => {
        setState((state) => ({
            ...state,
            open: false,
            jobItemID: null,
            showInvoice: false,
            showSellerInvoice: false,
            showUpComingEvents: false,
            showJobMileageModal: false
        }));
        res && refreshScreen();
    };

    const refreshScreen = () => {
        setState((st) => ({ ...st, refreshDependancy: new Date() }));
        props?.pullJobDetail();
        pullJobItems();
    };

    return (
        <div>
            {/* {state.showSellerInvoice && (
                <DialogComp onClose={() => closeModal(false)} title="Create Sales Invoice" maxWidth="lg" fullScreen>
                    <SellerInvoiceModal onClose={closeModal} scheduleJobID={props.scheduleJobID} />
                </DialogComp>
            )}
            {state.showInvoice && (
                <DialogComp onClose={() => closeModal(false)} title="Create Purchase Invoice" maxWidth="md">
                    <PurchaseInvoice onClose={closeModal} scheduleJobID={props.scheduleJobID} />
                </DialogComp>
            )} */}
            {state.open ? (
                <AddLabourModal
                    isCopied={state.isCopied}
                    // jobStatusID={props.jobStatusID}
                    onClose={closeModal}
                    vehicleID={state.vehicleID}
                    jobItemID={state.jobItemID}
                    JobStatus={jobDetails.jobStatusID}
                    vehicleRegNumber={state.vehicleRegNumber}
                    jobItemJobID={props.scheduleJobID}
                    islabourRateSet={jobDetails.islabourRateSet}
                    labourRate={jobDetails.labourRate}
                    markup={jobDetails.markup}
                    ServiceAreaID={jobDetails.jobServiceAgentID}
                    enggID={jobDetails.jobEngineerUserID}
                    masterWarehouseCode={jobDetails.masterWarehouseCode}
                    checkStatus={state.allowUpdate}
                />
            ) : state.showChecks ? (
                <VehicleDailyChecks
                    sheduleVechicleID={state.selectedRowData.vehicleID}
                    sheduleID={state.selectedRowData.scheduleID}
                    scheduleEventID={state.selectedRowData.scheduleEventID}
                    onClose={closeCheck}
                    displayCheck={state.selectedRowData.displayCheck}
                    frompage={false}
                    checkID={state.selectedRowData.vehicleInspectionID}
                />
            ) : state.showEditChecks ? (
                <EditVehicleDailyChecks
                    checkID={state.selectedRowData.vehicleInspectionID}
                    displayCheck={state.selectedRowData.displayCheck}
                    onClose={closeCheck}
                    frompage={false}
                />
            ) : (
                <div style={{ marginTop: 5 }}>
                    <Grid container item xs={12} spacing={1} alignItems="center">
                        {jobDetails.isEditableFields && (
                            <Grid item>
                                <CustomButtonChip
                                    label={`Add Job Line`}
                                    icon={AddIcon}
                                    toolTipTitle={`Add Job Line`}
                                    iconFontSize={checkisTab ? 12 : 16}
                                    onClick={btnHandler}
                                />
                            </Grid>
                        )}
                        {jobDetails.isEditableFields && (
                            <Grid item>
                                <CustomButtonChip
                                    label={`Add Standard Line`}
                                    icon={SearchIcon}
                                    toolTipTitle={`Add Standard Line`}
                                    iconFontSize={checkisTab ? 12 : 16}
                                    // width={200}
                                    // className="btn-m"
                                    onClick={addLaburLineHandler}
                                    // style={{ height: checkisTab ? 26 : 32, minWidth: '157px' }}
                                />
                            </Grid>
                        )}
                        {jobDetails.isEditableFields && !jobDetails.vorVehicleID && (
                            <Grid item>
                                <CustomButtonChip
                                    label={`${jobDetails.vorVehicleID ? 'Update' : 'Add'} VOR`}
                                    icon={AddIcon}
                                    toolTipTitle={`${jobDetails.vorVehicleID ? 'Update' : 'Add'} VOR`}
                                    iconFontSize={checkisTab ? 12 : 16}
                                    width={127}
                                    onClick={() => showVorModal('showVORPopup')}
                                />
                            </Grid>
                        )}
                        {jobDetails.isEditableFields && (
                            <Grid item>
                                <CustomButtonChip
                                    label={`Set Job Mileage`}
                                    icon={jobDetails.jobMileage ? DoneIcon : AddIcon}
                                    toolTipTitle={`Set Job Mileage`}
                                    iconFontSize={checkisTab ? 12 : 16}
                                    backgroundColour={jobDetails.jobMileage ? 'green' : ''}
                                    color={jobDetails.jobMileage ? 'white' : ''}
                                    iconBg={jobDetails.jobMileage ? 'white' : ''}
                                    iconColor={jobDetails.jobMileage ? 'green' : ''}
                                    onClick={addJobMileageModal}
                                />
                            </Grid>
                        )}

                        {/* <JobItemHeaderDates jobID={jobDetails.jobID} jobDetails={jobDetails} refreshScreen={refreshScreen} /> */}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
                            <TableContainer
                                style={{
                                    border: '1px solid rgba(0,0,0,0.2)',
                                    maxHeight: `calc(100vh - ${jobDetails.isEditableFields ? '421px' : '360px'})`
                                }}
                                className="custom-scroll"
                            >
                                <Table stickyHeader style={{ minWidth: 600 }}>
                                    <TableHead>
                                        <TableRow style={{ height: checkisMobile ? 20 : 40 }}>
                                            {state.tableCol.map((col) => (
                                                <StyledRow key={col.key} align={col.align} width={col.width}>
                                                    {col.name}
                                                </StyledRow>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* {state.jobItemsList
                                            .filter((row) => row.copied === 'N' && row.approved === 'N')
                                            .map((row, i) => (
                                                <StyledTableRow key={row.jobItemID} style={{ background: row.backColour, color: 'red' }}>
                                                    <StyledTableCell>
                                                        <div
                                                            style={{
                                                                cursor: 'pointer',
                                                                fontWeight: 600,
                                                                minWidth: 200,
                                                                color: row.textColour
                                                            }}
                                                            onClick={() => btnHandler(row)}
                                                        >
                                                            <div style={{ wordBreak: 'keep-all', fontSize: checkisMobile ? '10px' : '14px' }}>
                                                                {row.jobItemDescription}
                                                            </div>
                                                            <div style={{ fontSize: checkisMobile ? '9px' : '12px' }}>
                                                                {row.vmrs} ({row.engineer})
                                                            </div>
                                                            {row.updatedby && (
                                                                <div
                                                                    style={{ fontSize: checkisMobile ? '9px' : '12px', fontStyle: 'italic' }}
                                                                >{` (${row.updatedby})`}</div>
                                                            )}
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ color: row.textColour }}>{row.status || '-'}</StyledTableCell>
                                                    <StyledTableCell style={{ color: row.textColour }}>{row.hours}</StyledTableCell>
                                                    <StyledTableCell style={{ color: row.textColour }}>{row.costType || '-'}</StyledTableCell>
                                                    {!isEngineer && (
                                                        <>
                                                            <StyledTableCell style={{ color: row.textColour }}>
                                                                {formatters.NumberWithPoundTable(row.chargeable)}
                                                            </StyledTableCell>
                                                            <StyledTableCell style={{ color: row.textColour }}>
                                                                {formatters.NumberWithPoundTable(row.nonchargeable)}
                                                            </StyledTableCell>
                                                        </>
                                                    )}
                                                    <StyledTableCell style={{ color: row.textColour }}>
                                                        {checkhaveAccess?.CanDeleteJobLines
                                                            ? row.canDeleteLine === 'Y' && (
                                                                  <DeleteButton onClick={() => handleOpen(row)} toolTipTitle="Delete Job Item" />
                                                              )
                                                            : null}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))} */}
                                        {state.jobItemsList
                                            .filter((row) => row.copied === 'N')
                                            // .filter((row) => row.copied === 'N' && row.approved === 'Y')
                                            .map((row, i) => (
                                                <StyledTableRow key={row.jobItemID} style={{ background: row.backColour, color: 'red' }}>
                                                    <StyledTableCell>
                                                        <div
                                                            style={{
                                                                cursor: 'pointer',
                                                                fontWeight: 600,
                                                                minWidth: 200,
                                                                color: row.textColour
                                                            }}
                                                            onClick={() => btnHandler(row)}
                                                        >
                                                            <div style={{ wordBreak: 'keep-all', fontSize: checkisMobile ? '10px' : '14px' }}>
                                                                {row.jobItemDescription}
                                                            </div>
                                                            <div style={{ fontSize: checkisMobile ? '9px' : '12px' }}>
                                                                {row.vmrs} ({row.engineer})
                                                            </div>
                                                            {row.updatedby && (
                                                                <div
                                                                    style={{ fontSize: checkisMobile ? '9px' : '12px', fontStyle: 'italic' }}
                                                                >{` (${row.updatedby})`}</div>
                                                            )}
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ color: row.textColour }}>{row.status || '-'}</StyledTableCell>
                                                    <StyledTableCell style={{ color: row.textColour }}>{row.hours}</StyledTableCell>
                                                    <StyledTableCell style={{ color: row.textColour }}>{row.costType || '-'}</StyledTableCell>
                                                    {!isEngineer && (
                                                        <>
                                                            <StyledTableCell style={{ color: row.textColour }}>
                                                                {formatters.NumberWithPoundTable(row.chargeable)}
                                                            </StyledTableCell>
                                                            <StyledTableCell style={{ color: row.textColour }}>
                                                                {formatters.NumberWithPoundTable(row.nonchargeable)}
                                                            </StyledTableCell>
                                                        </>
                                                    )}
                                                    <StyledTableCell style={{ color: row.textColour }}>
                                                        {checkhaveAccess?.CanDeleteJobLines
                                                            ? row.canDeleteLine === 'Y' && (
                                                                  <DeleteButton onClick={() => handleOpen(row)} toolTipTitle="Delete Job Item" />
                                                              )
                                                            : null}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        {state.jobItemsList
                                            .filter((row) => row.copied === 'Y')
                                            .map((row, i) => (
                                                <StyledTableRow key={row.jobItemID} style={{ background: row.backColour }}>
                                                    <StyledTableCell>
                                                        <div
                                                            style={{
                                                                cursor: 'pointer',
                                                                fontWeight: 600,
                                                                minWidth: 200,
                                                                color: row.textColour
                                                            }}
                                                            onClick={() => btnHandler(row)}
                                                        >
                                                            <div style={{ wordBreak: 'keep-all', fontSize: checkisMobile ? '10px' : '14px' }}>
                                                                {row.jobItemDescription}
                                                            </div>
                                                            <div style={{ fontSize: checkisMobile ? '9px' : '12px' }}>
                                                                {row.vmrs} ({row.engineer})
                                                            </div>
                                                            {row.updatedby && (
                                                                <div
                                                                    style={{ fontSize: checkisMobile ? '9px' : '12px', fontStyle: 'italic' }}
                                                                >{` (${row.updatedby})`}</div>
                                                            )}
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ color: row.textColour }}>{row.status || '-'}</StyledTableCell>
                                                    <StyledTableCell style={{ color: row.textColour }}>{row.hours}</StyledTableCell>
                                                    <StyledTableCell style={{ color: row.textColour }}>{row.costType || '-'}</StyledTableCell>
                                                    {!isEngineer && (
                                                        <>
                                                            <StyledTableCell style={{ color: row.textColour }}>
                                                                {formatters.NumberWithPoundTable(row.chargeable)}
                                                            </StyledTableCell>
                                                            <StyledTableCell style={{ color: row.textColour }}>
                                                                {formatters.NumberWithPoundTable(row.nonchargeable)}
                                                            </StyledTableCell>
                                                        </>
                                                    )}
                                                    <StyledTableCell style={{ color: row.textColour }}>
                                                        {checkhaveAccess?.CanDeleteJobLines
                                                            ? row.canDeleteLine === 'Y' && (
                                                                  <DeleteButton onClick={() => handleOpen(row)} toolTipTitle="Delete Job Item" />
                                                              )
                                                            : null}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {/* <DataGrid
                                columns={columns}
                                rows={state.jobItemsList}
                                isRowHovered={true}
                                rowHeight={50}
                                loadingData={state.loader}
                                height={475}
                                // idFieldName="jobItemID" // as from row 'id' is the property name contains actual id of record
                                // fileUploaderConfigName="JobItem"
                                gridRefresh={state.isGridRefresh}
                            /> */}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={4} style={{ paddingRight: '10px' }}>
                            <JobQueriesScreen
                                hideAddQuery={true}
                                VehicleID={props.VehicleID}
                                JobID={props.scheduleJobID}
                                fromPage="job"
                                refreshDependancy={jobDetails.refreshDependancy}
                                isDisabled={checkStatus}
                                height={checkisTab ? '100%' : `calc(100vh - ${jobDetails.isEditableFields ? '461px' : '397px'})`}
                                // isHeaderNotShow={true}
                            />
                        </Grid>
                    </Grid>

                    {jobDetails.showVORPopup ? (
                        <DialogComp
                            title={`${jobDetails.vorVehicleID ? 'Update' : 'Add'} VOR`}
                            onClose={() => closeVORModal(false)}
                            maxWidth="lg"
                            overflow="auto"
                        >
                            <AddVor
                                VehicleID={jobDetails.jobVehicleID}
                                update={jobDetails.vorVehicleID}
                                vorVehicleID={jobDetails.vorVehicleID}
                                jobID={jobDetails.jobID}
                                onClose={closeVORModal}
                            />
                        </DialogComp>
                    ) : null}
                    {state.showUpComingEvents ? (
                        <DialogComp title={'Upcoming Events'} onClose={() => closeModal(false)} maxWidth="md" fullWidth>
                            <UpcomingEvents vehicleID={jobDetails.jobVehicleID} fromPage="jobItem" />
                        </DialogComp>
                    ) : null}
                    {state.showJobMileageModal ? (
                        <DialogComp title={'Set Job Mileage'} onClose={() => closeModal(false)} maxWidth="sm" fullWidth>
                            <SetJobMileageScreen
                                jobDetails={jobDetails}
                                fromPage="jobItem"
                                checkStatus={checkStatus}
                                onClose={closeModal}
                                scheduleJobID={props.scheduleJobID}
                            />
                        </DialogComp>
                    ) : null}
                    {state.openLaburLine ? (
                        <DialogComp title={'Select Labour Line'} onClose={() => closeLaburLineHandler(false)} maxWidth="md" fullWidth>
                            <LabourLineTableComp JobID={props.scheduleJobID} onClose={closeLaburLineHandler} refreshScreen={refreshScreen} />
                        </DialogComp>
                    ) : null}
                    {state.complete ? (
                        <DialogComp title={`Complete Schedule  (${jobDetails.jobRegNumber})`} maxWidth="sm" onClose={() => closeCheck(false)}>
                            <CompleteScheduleModal scheduleID={state.scheduleID} rowData={state.selectedRowData} onClose={closeCheck} />
                        </DialogComp>
                    ) : null}

                    {state.showCancelConfirmModal ? (
                        <DialogComp title={'Confirmation'} onClose={handleOpen} maxWidth="lg">
                            <ConfirmationModal
                                message={'Are you sure you want to delete this job item?'}
                                handleCancel={handleOpen}
                                handleSubmit={handleDeletePart}
                                isAlert={true}
                                alertType={'error'}
                            />
                        </DialogComp>
                    ) : null}
                </div>
            )}
        </div>
    );
}
export default InternalJobItem;
