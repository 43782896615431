import { FormHelperText, Grid, InputLabel } from '@material-ui/core';
import React, { useState, useEffect, useMemo } from 'react';
import { MandatoryField, PrimaryButton, SecondaryButton, SimpleField, SingleSelect } from '../../../../Core/Controls';
import { useToast } from '../../../../Core/Hooks/useToast';
import { DeleteJob, DeleteDefect, getVehicleDefectDeleteDropDown, getVehicleJobDeleteDropDown } from '../../../../Core/Services/InternalUser/JOB/jobService';
import '../../../commonStyle.scss';

const DeleteJobModal = (props) => {
    const [state, setState] = useState({ errors: {}, reasonList: [], reason: '', reasonID: '' });
    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);

    useEffect(async () => {
        pullApiData();
    }, []);

    const pullApiData = async () => {
        let res = {};
        if (props.fromPage == 'Defect') {
            res = await getVehicleDefectDeleteDropDown();
        }
        if (props.fromPage == 'Job') {
            res = await getVehicleJobDeleteDropDown();
        }

        setState((st) => ({
            ...st,
            reasonList: res.data.depots
        }));
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => ({ ...st, [nm]: val }));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };

    const validations = () => {
        const { reasonID, reason } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(reason)) {
            errors.reason = 'Reason is required';
            formIsValid = false;
        }
        if (!reasonID) {
            errors.reasonID = 'Please select reason';
            formIsValid = false;
        }

        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            try {
                setBtnLoader(true);
                let res = {};
                const data = {
                    ID: props.deleteID,
                    Reason: state.reason,
                    ReasonID: state.reasonID?.id
                };

                if (props.fromPage == 'Job') {
                    res = await DeleteJob(data);
                }
                if (props.fromPage == 'Defect') {
                    res = await DeleteDefect(data);
                }
                if (res.success) {
                    showToastSuccess('Record Deleted Sucessfully');
                    setBtnLoader(false);
                    props.onClose(true);
                } else {
                    showToastError(res.message);
                    setBtnLoader(false);
                }
            } catch (error) {
                setBtnLoader(false);
                showToastError(`An error occurred: ${error.message}`);
            }
        }
    };

    return (
        <Grid container>
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel required shrink>
                    Select Reason
                </InputLabel>
                <SingleSelect options={state.reasonList} value={state.reasonID} onChange={singleSelectChange('reasonID')} />
                <FormHelperText error>{state.errors.reasonID}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={12} className="mandatory-fields">
                <MandatoryField multiline rows={6} inputLabel="Reason" name="reason" value={state.reason} onChange={handleInputChange} />

                <FormHelperText error>{state.errors.reason}</FormHelperText>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6} isBtnLoader={btnLoader} className="submit_btn">
                    <PrimaryButton onClick={submitHandler}>Submit</PrimaryButton>
                </Grid>
                <Grid item xs={6} className="submit_btn">
                    <SecondaryButton forceEnabled={true} onClick={() => props.onClose(false)}>
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DeleteJobModal;
