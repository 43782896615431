import { Button, ButtonGroup, Checkbox, CircularProgress, FormControlLabel, FormHelperText, Grid, InputLabel, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
    DateTimePicker,
    formatters,
    MandatoryField,
    PrimaryButton,
    SecondaryButton,
    SelectBox,
    SimpleField,
    SingleSelect
} from '../../../../../../Core/Controls';
import { Alert } from '@material-ui/lab';
import { GreenCheckbox, PrimaryCheckbox } from '../../../../../../Core/Controls/Inputs/Checkbox';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import EmailIcon from '@material-ui/icons/Email';
import JobDetail from '../JobDetails';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import PurchaseInvoice from '../JobInvoiceLines/purchaseInvoice';
// ../../JOBItems/JobInvoiceLines/sellerInvoice
import SellerInvoiceModal from '../../JOBItems/JobInvoiceLines/sellerInvoice';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useHistory } from 'react-router-dom';

const JobAdminstration = ({ handleNext, jobDetails, isDisabled, jobStatus, customersList, isFactoryInspection, isProformaCreated, redirectToSalesInv }) => {
    const history = useHistory();
    const [state, setState] = useState({
        showMailBody: false,
        performaButtons: 2,
        performaLabel: 'Download',
        salesInvoiceStatus: jobStatus === 'AS' ? 'P' : null,
        jobApprovedOrderNo: '',
        jobasInvoiced: false,
        jobasproforma: jobStatus === 'AS' ? true : false,
        isDocuments: false,
        isShowPerformaInvoice: false,
        isButtonShow: false,
        jobCustomerID: jobDetails.customerID,
        emailEventDoc: false,
        openLines: '',
        showLoader: true,
        isProforma: false,
        isShowInvoice: false
    });

    const stopLoader = () => {
        setState((st) => ({ ...st, showLoader: false }));
    };

    const handleCheck = (e) => {
        const { name, checked } = e.target;
        setState((st) => {
            let NewSt = { ...st };
            if (name === 'jobasInvoiced') {
                NewSt.salesInvoiceStatus = 'I';
                NewSt.showMailBody = false;
            } else if (name === 'jobasproforma') {
                NewSt.salesInvoiceStatus = 'P';
                NewSt.showMailBody = false;
                NewSt.isProforma = false;

                // NewSt.isProforma = checked;
            }
            if (!checked) {
                NewSt.salesInvoiceStatus = null;
            }
            NewSt[name] = checked;

            return NewSt;
        });
    };

    const showPerformaInvoice = (bool) => {
        setState((st) => ({ ...st, isShowPerformaInvoice: bool }));
    };
    const showOpenLine = (params) => {
        if (params)
            setState((st) => ({
                ...st,
                openLines: params.openLines,
                isProforma: params.isProforma,
                isShowInvoice: params.isProforma,
                mismatchedLines: params.mismatchedLines,
                jobasproforma: params.isProforma
            }));
    };

    const inputChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type == 'checkbox') {
            setState((st) => ({ ...st, [name]: checked }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    };

    const handleSubmitValue = () => {
        handleNext();
        redirectToSalesInv();
    };

    const setAPIScheduleDocEmail = (obj, isDocuments) => {
        setState((st) => ({ ...st, sentBy: obj.sentBy, sendEmailTo: obj.email, isDocuments: isDocuments }));
    };

    return (
        <div style={{ padding: 10 }}>
            <Grid container spacing={2} alignContent="flex-start">
                {state.showLoader ? (
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', height: 500, alignItems: 'center' }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <>
                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={2}>
                                <FormHelperText shrink>Order Date</FormHelperText>
                                <Typography variant="button">{jobDetails?.jobOrderDate || 'N/A'}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <FormHelperText shrink>Job Reference</FormHelperText>
                                <Typography variant="button">{jobDetails?.jobAgentOrderNo || 'N/A'}</Typography>
                            </Grid>

                            <Grid item xs={2}>
                                <FormHelperText shrink>Reg Number</FormHelperText>
                                <Typography variant="button">{jobDetails?.jobRegNumber || 'N/A'}</Typography>
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: 'end' }}>
                                <FormHelperText style={{ textAlign: 'end' }}>Chargeable Total</FormHelperText>
                                <Typography variant="button">
                                    {/* £{jobDetails?.jobCustomerOrderTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} */}
                                    {formatters.CurrencyNumberWithPound(jobDetails?.jobCustomerOrderTotal)}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} style={{ textAlign: 'end' }}>
                                <FormHelperText style={{ textAlign: 'end' }}>Non Chargeable Total</FormHelperText>
                                <Typography variant="button">
                                    {/* £{jobDetails.nonChargable?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} */}
                                    {formatters.CurrencyNumberWithPound(jobDetails.nonChargable)}
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* //Top Inputs  Readonly  */}
                        {state.openLines ? (
                            <Grid item xs={12}>
                                <Alert severity="error">{state.openLines}</Alert>
                            </Grid>
                        ) : state.mismatchedLines ? (
                            <Grid item xs={12}>
                                <Alert severity="Warning">
                                    {state.mismatchedLines}
                                    <button
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            color: 'blue',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            fontWeight: 600,
                                            fontSize: 15
                                        }}
                                        onClick={() => setState((st) => ({ ...st, mismatchedLines: false }))}
                                    >
                                        continue
                                    </button>
                                </Alert>
                            </Grid>
                        ) : state.isShowPerformaInvoice ? (
                            <Grid container item xs={12} spacing={2} alignItems="center">
                                <Grid item>
                                    <FormControlLabel
                                        control={<GreenCheckbox onChange={handleCheck} name="jobasproforma" checked={state.jobasproforma} />}
                                        label={state.jobasproforma && state.isProforma ? 'Proforma Created' : 'Create Proforma'}
                                        // disabled={!isDisabled || jobStatus === 'AS'}
                                    />
                                </Grid>
                                {state.isShowInvoice && (
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <GreenCheckbox
                                                    onChange={handleCheck}
                                                    name="jobasInvoiced"
                                                    checked={state.salesInvoiceStatus == 'I'}
                                                    disabled={!isDisabled}
                                                />
                                            }
                                            label="Invoice Job"
                                        />
                                    </Grid>
                                )}
                                {state.jobasInvoiced && (
                                    <Grid item xs>
                                        <input
                                            style={{ minWidth: 250, padding: 5 }}
                                            placeholder="Order Acknowledgment Number"
                                            name="jobApprovedOrderNo"
                                            value={state.jobApprovedOrderNo}
                                            onChange={inputChange}
                                            disabled={!isDisabled}
                                            autoFocus
                                        />
                                    </Grid>
                                )}
                                {state.isDocuments && (
                                    <>
                                        {state.sentBy ? (
                                            <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                                                {state.sentBy}
                                            </Grid>
                                        ) : (
                                            <Grid item xs={12}>
                                                <InputLabel>
                                                    {/* <span style={{ fontSize: '12px' }}>
                                                    (<input type="checkbox" />
                                                    Email event document?)
                                                </span> */}
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                name="emailEventDoc"
                                                                onChange={inputChange}
                                                                checked={state.emailEventDoc}
                                                            />
                                                        }
                                                        label="Email event document?"
                                                    />
                                                </InputLabel>
                                                <SimpleField type="email" value={state.sendEmailTo} name="sendEmailTo" onChange={inputChange} />
                                            </Grid>
                                        )}
                                    </>
                                )}
                                <Grid item xs={12}>
                                    <InputLabel>Customer</InputLabel>
                                    <SelectBox
                                        List={customersList || []}
                                        name="jobCustomerID"
                                        value={state.jobCustomerID}
                                        style={{ marginTop: 10 }}
                                        onChange={inputChange}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    There are no chargeable lines. Click the submit button below will complete the job. Close the window if you don't want to
                                    close the job
                                </Alert>
                            </Grid>
                        )}
                    </>
                )}
                {/* //Top Labels Readonly  */}
                {/* //Invoice Table  */}
                <Grid item xs={12}>
                    <SellerInvoiceModal
                        scheduleJobID={jobDetails?.jobID}
                        hideDetailBar={true}
                        hideInputs={true}
                        salesInvoiceStatus={state.salesInvoiceStatus}
                        jobApprovedOrderNo={state.jobApprovedOrderNo}
                        sendEmailTo={state.sendEmailTo}
                        emailEventDoc={state.emailEventDoc}
                        setAPIScheduleDocEmail={setAPIScheduleDocEmail}
                        handleSubmitValue={handleSubmitValue}
                        disabled={isDisabled}
                        showPerformaInvoice={showPerformaInvoice}
                        isShowPerformaInvoice={state.isShowPerformaInvoice}
                        isSubmitButtonDisabled={jobStatus === 'AS' && !state.jobasInvoiced}
                        customerID={state.jobCustomerID}
                        isProforma={state.jobasInvoiced}
                        isProformaCreated={isProformaCreated}
                        showOpenLine={showOpenLine}
                        stopLoader={stopLoader}
                        loader={state.showLoader}
                        mismatchedLines={state.mismatchedLines}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default JobAdminstration;
