import { Button, Divider, FormHelperText, Grid, InputLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { InputText, SecondaryButton, SecondaryCheckbox, MandatoryField, YesButton, NoButton, SimpleField, TabView } from '../../../Core/Controls';
import { getPartsDetails, PartPurchased_Request, PartRequest_SendRequest, PartStockAdjustment_SendRequest } from '../../../Core/Services/Admin/partService';
import { useToast } from '../../../Core/Hooks/useToast';
import { useLoginInfo } from '../../../Core/Hooks/useLoginInfo';
import PartRequestHistory from './partHistory';
import { CloudUpload } from '@material-ui/icons';

const PartAddUpdate = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const { userRoles } = useLoginInfo();
    const isEngineer = userRoles.includes('engineer');
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        setTabIndex(props.screenID == 'R' ? 1 : props.screenID == 'A' ? 2 : 0);
    }, [props.screenID]);

    const [state, setState] = useState({
        partNumber: '',
        description: '',
        minimumUnitOfStock: null,
        totalStockQuantity: null,
        sellingPrice: null,
        unitOfPricePer: null,
        standardCost: null,
        quantity: null,
        newMinimumStockLevel: null,
        newTargetStockLevel: null,
        minimumStockLevel: null,
        isActive: true,
        partID: null,
        isReloadAPICall: null,
        fileName: '',
        selectedFile: ''
    });
    const [errors, setErrors] = useState({});
    const [partRequestBtnLoader, setpartRequestBtnLoader] = useState(false);
    const [stockLevelAdjustmentBtnLoader, setstockLevelAdjustmentBtnLoader] = useState(false);
    const [fileSubmitBtnLoader, setFileSubmitBtnLoader] = useState(false);
    const fetchPartDetails = async () => {
        try {
            if (props.partID) {
                let res = await getPartsDetails(props.partID);
                let temp = res.data?.table[0];

                setState((st) => ({
                    ...st,
                    partID: temp?.partID || st.partID,
                    partNumber: temp?.partNumber || st.partNumber,
                    description: temp?.description || st.description,
                    minimumUnitOfStock: temp?.minimumUnitOfStock || st.minimumUnitOfStock,
                    totalStockQuantity: temp?.totalStockQuantity || st.totalStockQuantity,
                    sellingPrice: temp?.sellingPrice || st.sellingPrice,
                    unitOfPricePer: temp?.unitOfPricePer || st.unitOfPricePer,
                    partTypeDescription: temp?.partTypeDescription || st.partTypeDescription,
                    standardCost: temp?.standardCost || st.standardCost,
                    minimumStockLevel: temp?.minimumStockLevel || st.minimumStockLevel,
                    isActive: temp?.isActive !== undefined ? temp.isActive : st.isActive
                }));
            }
        } catch (error) {
            console.error('Failed to fetch part details:', error);
        }
    };

    useEffect(() => {
        fetchPartDetails();
    }, [props.partID, state.isReloadAPICall]);

    const validate = () => {
        let { partNumber, description, totalStockQuantity, minimumUnitOfStock } = state;
        let noErrors = true;
        let err = {};
        if (!/[^\s]/.test(partNumber)) {
            err.partNumber = 'Part number is required';
            noErrors = false;
        }
        if (!/[^\s]/.test(description)) {
            err.description = 'Part description is required';
            noErrors = false;
        }

        if (minimumUnitOfStock > totalStockQuantity) {
            err.minimumUnitOfStock = 'Minimum unit of stock should be less than or equal to total stock quantity';
        }
        setErrors(err);
        return noErrors;
    };
    // const handleData = () => {
    //     if (validate()) {
    //         setBtnLoader(true);
    //         props.saveData(state);
    //         props.onClose();
    //         setBtnLoader(false);
    //     }
    // };
    const valueChanged = (ev) => {
        let temp = ev.target;
        setState((st) => ({
            ...st,
            [temp.name]: temp.type === 'checkbox' ? temp.checked : temp.value
        }));
        setErrors({});
    };
    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const validationForOderPart = () => {
        const { quantity } = state;
        let faxRegEx = /^\+?[0-9]{11,}$/;
        let formIsValid = true;
        let errors = {};
        if (!quantity) {
            errors.quantity = 'Quantity is required';
            formIsValid = false;
        }

        setErrors((st) => ({ ...st, ...errors }));
        return formIsValid;
    };

    const OrderExtraParts = async () => {
        if (validationForOderPart()) {
            setpartRequestBtnLoader(true);
            let data = {
                partID: state.partID,
                quantity: state.quantity
            };
            let res = await PartRequest_SendRequest(data);
            if (res.success) {
                showToastSuccess(res?.data?.message);
                setState((st) => {
                    const nst = { ...st, isReloadAPICall: new Date() };
                    return nst;
                });
                props.onClose(true);
            } else {
                res.message && showToastSuccess(res.message);
                if (res.errors)
                    for (const [key, value] of Object.entries(res.errors)) {
                        showToastError(`${key}: ${value}`);
                    }
            }
            setpartRequestBtnLoader(false);
        }
    };

    const validationForPartStockAdjustment = () => {
        const { newMinimumStockLevel, newTargetStockLevel } = state;
        let formIsValid = true;
        let errors = {};
        if (!newMinimumStockLevel) {
            errors.newMinimumStockLevel = 'Minimum Stock is required';
            formIsValid = false;
        }
        if (!newTargetStockLevel) {
            errors.newTargetStockLevel = 'Target Stock is required';
            formIsValid = false;
        }
        setErrors((st) => ({ ...st, ...errors }));
        return formIsValid;
    };

    const PartStockAdjustment = async () => {
        if (validationForPartStockAdjustment()) {
            setstockLevelAdjustmentBtnLoader(true);
            let data = {
                partID: state.partID,
                newMinimumStockLevel: state.newMinimumStockLevel,
                newTargetStockLevel: state.newTargetStockLevel
            };
            let res = await PartStockAdjustment_SendRequest(data);
            if (res.success) {
                showToastSuccess(res?.data?.message);
                setState((st) => {
                    const nst = { ...st, isReloadAPICall: new Date() };
                    return nst;
                });
                props.onClose(true);
            } else {
                // showToastError(res.message);
                res.message && showToastSuccess(res.message);
                if (res.errors)
                    for (const [key, value] of Object.entries(res.errors)) {
                        showToastError(`${key}: ${value}`);
                    }
            }
            setstockLevelAdjustmentBtnLoader(false);
        }
    };

    const onFileSelect = (e) => {
        const { files } = e.target;
        if (files.length) {
            setState((st) => ({ ...st, fileName: files[0].name, selectedFile: files[0] }));
        }
    };

    const handleFileSubmit = async () => {
        if (!state.purchasedQuantity) {
            setErrors((st) => ({ ...st, purchasedQuantity: 'Purchased quantity is required' }));
            return;
        }
        if (!state.fileName) {
            setErrors((st) => ({ ...st, selectedFile: 'Please select file' }));
            return;
        }

        if (state.fileName && state.selectedFile) {
            setFileSubmitBtnLoader(true);
            const data = new FormData();
            data.append(state.fileName, state.selectedFile);
            let res = await PartPurchased_Request(state.partID, state.purchasedQuantity, state.description, state.partNumber, data);

            if (res.success) {
                showToastSuccess(res?.data?.message);
                setState((st) => {
                    const nst = { ...st, isReloadAPICall: new Date() };
                    return nst;
                });
                props.onClose(true);
            } else {
                res.message && showToastSuccess(res.message);
                if (res.errors)
                    for (const [key, value] of Object.entries(res.errors)) {
                        showToastError(`${key}: ${value}`);
                    }
            }
            setFileSubmitBtnLoader(false);
        }
    };

    const tempTab = [
        {
            label: 'Part Order History',
            body: <PartRequestHistory screenId={'O'} PartID={props.partID} height={400} isReloadAPICall={state.isReloadAPICall} />
        },
        {
            label: 'Parts Purchased',
            body: <PartRequestHistory screenId={'P'} PartID={props.partID} height={400} isReloadAPICall={state.isReloadAPICall} />
        },
        {
            label: 'Part Received',
            body: <PartRequestHistory screenId={'R'} PartID={props.partID} height={400} isReloadAPICall={state.isReloadAPICall} />
        },
        {
            label: 'Stock Level Adjustment',
            body: <PartRequestHistory screenId={'A'} PartID={props.partID} height={400} isReloadAPICall={state.isReloadAPICall} />
        }
    ];

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={7}>
                    <Grid xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} className="mandatory-fields">
                                <MandatoryField
                                    inputLabel="Part Description"
                                    name="description"
                                    placeholder="Part Description"
                                    value={state.description || ''}
                                    onChange={valueChanged}
                                    errors={errors.description}
                                    isDisabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <SimpleField
                                    inputLabel="Stock Quantity"
                                    name="totalStockQuantity"
                                    placeholder="Stock Quantity"
                                    value={state.totalStockQuantity || ''}
                                    onChange={valueChanged}
                                    type="number"
                                    isDisabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <SimpleField
                                    inputLabel="Minimum Stock Level"
                                    name="minimumStockLevel"
                                    placeholder="Minimum Stock Level"
                                    value={state.minimumStockLevel || ''}
                                    onChange={valueChanged}
                                    type="number"
                                    isDisabled={true}
                                />
                                <FormHelperText error>{errors.minimumUnitOfStock}</FormHelperText>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <SimpleField
                                    inputLabel="Traget Stock Level"
                                    name="targetStockLevel"
                                    placeholder="Traget Stock Level"
                                    value={state.targetStockLevel || ''}
                                    onChange={valueChanged}
                                    type="number"
                                    isDisabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <SimpleField
                                    inputLabel="Retail Price"
                                    name="sellingPrice"
                                    placeholder="Retail Price"
                                    value={state.sellingPrice || ''}
                                    onChange={valueChanged}
                                    type="number"
                                    isDisabled={true}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <SimpleField
                                    inputLabel="Cost Price"
                                    name="standardCost"
                                    placeholder="Cost Price"
                                    value={state.standardCost || ''}
                                    onChange={valueChanged}
                                    type="number"
                                    isDisabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <SimpleField
                                    inputLabel="Part Type"
                                    name="partTypeDescription"
                                    placeholder="Part Type"
                                    value={state.partTypeDescription || ''}
                                    onChange={valueChanged}
                                    type="number"
                                    isDisabled={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="submit_btn">
                        {isEngineer && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Divider sx={{ borderBottomWidth: 2, borderColor: 'black' }} />
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container alignItems="flex-end" justify="space-between" spacing={1}>
                                        <Grid item xs={12}>
                                            <div
                                                style={{
                                                    background: 'none',
                                                    fontSize: 20,
                                                    fontWeight: 600,
                                                    color: '#E32636',
                                                    textTransform: 'uppercase'
                                                }}
                                                color="secondary"
                                            >
                                                Order Extra Parts
                                            </div>

                                            {/* <hr /> */}
                                        </Grid>

                                        <Grid item xs={12} sm={'auto'} md={'auto'}>
                                            <MandatoryField
                                                inputLabel="Quantity"
                                                name="quantity"
                                                placeholder="Quantity"
                                                value={state.quantity || ''}
                                                onChange={valueChanged}
                                                type="number"
                                                errors={errors.quantity}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={'5'} md={'5'} lg={4}>
                                            <SecondaryButton isBtnLoader={partRequestBtnLoader} onClick={OrderExtraParts}>
                                                Email Part Request
                                            </SecondaryButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container alignItems="flex-end" justify="space-between" spacing={1}>
                                        <Grid item xs={12}>
                                            <div
                                                style={{
                                                    background: 'none',
                                                    fontSize: 20,
                                                    fontWeight: 600,
                                                    color: '#E32636',
                                                    textTransform: 'uppercase'
                                                }}
                                                color="secondary"
                                            >
                                                Purchased Parts
                                            </div>

                                            {/* <hr /> */}
                                        </Grid>

                                        <Grid item xs={12} sm={'auto'} md={'auto'}>
                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item>
                                                    <MandatoryField
                                                        inputLabel="Quantity"
                                                        name="purchasedQuantity"
                                                        placeholder="Quantity"
                                                        value={state.purchasedQuantity || ''}
                                                        onChange={valueChanged}
                                                        type="number"
                                                        errors={errors.purchasedQuantity}
                                                    />
                                                    <FormHelperText error>{errors.selectedFile}</FormHelperText>
                                                </Grid>
                                                <Grid item>
                                                    <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
                                                        <CloudUpload color="action" fontSize="large" />
                                                        <input
                                                            id="file-upload"
                                                            type="file"
                                                            style={{ display: 'none' }}
                                                            onChange={onFileSelect}
                                                            accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                        />
                                                    </label>
                                                </Grid>

                                                {/* <Grid item>
                                                    <Button color="secondary" variant="outlined" component="label">
                                                        <CloudUpload>
                                                            <input
                                                                type="file"
                                                                style={{ display: 'none' }}
                                                                onChange={onFileSelect}
                                                                accept="application/pdf, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                            />
                                                        </CloudUpload>
                                                    </Button>
                                                </Grid> */}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={'5'} md={'5'} lg={4}>
                                            <SecondaryButton isBtnLoader={fileSubmitBtnLoader} onClick={handleFileSubmit}>
                                                Email Purchased Parts
                                            </SecondaryButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container alignItems="flex-end" justify="space-between" spacing={1}>
                                        <Grid item xs={12}>
                                            <div
                                                style={{
                                                    background: 'none',
                                                    fontSize: 20,
                                                    fontWeight: 600,
                                                    color: '#E32636',
                                                    textTransform: 'uppercase'
                                                }}
                                                color="secondary"
                                            >
                                                Request Change To Preset Stock level
                                            </div>

                                            {/* <hr /> */}
                                        </Grid>

                                        <Grid item xs={12} sm={'auto'} md={'auto'}>
                                            <MandatoryField
                                                inputLabel="Minimum Stock"
                                                name="newMinimumStockLevel"
                                                placeholder="Minimum Stock"
                                                value={state.newMinimumStockLevel || ''}
                                                onChange={valueChanged}
                                                type="number"
                                                errors={errors.newMinimumStockLevel}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={'auto'} md={'auto'}>
                                            <MandatoryField
                                                inputLabel="Target Stock"
                                                name="newTargetStockLevel"
                                                placeholder="Target Stock"
                                                value={state.newTargetStockLevel || ''}
                                                onChange={valueChanged}
                                                type="number"
                                                errors={errors.newTargetStockLevel}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={'5'} md={'5'} lg={4}>
                                            <SecondaryButton isBtnLoader={stockLevelAdjustmentBtnLoader} onClick={PartStockAdjustment}>
                                                Email Stock Level Adjustment
                                            </SecondaryButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                    <TabView selectedIndex={tabIndex} tabList={tempTab} id="PartsHistory_tabs" />
                </Grid>
            </Grid>
        </>
    );
};
export default PartAddUpdate;
