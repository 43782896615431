import * as Components from '../../../../Components';
import { TypeOfAuth } from '../menu-auth-type';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import BuildIcon from '@material-ui/icons/Build';
import GavelIcon from '@material-ui/icons/Gavel';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import PersonIcon from '@material-ui/icons/Person';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import WorkIcon from '@material-ui/icons/Work';
import ExploreRoundedIcon from '@material-ui/icons/ExploreRounded';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import InfoIcon from '@material-ui/icons/Info';

export default [
    // {
    //     label: 'Dashboard',
    //     path: '/',
    //     exact: true,
    //     component: Components.InternalDashboard,
    //     authType: TypeOfAuth.Both,
    //     icon: <DashboardIcon />
    // },
    {
        label: 'Customers',
        path: '/controller/customer',
        exact: true,
        icon: <PermContactCalendarIcon />,
        component: Components.CustomerScreen,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Depots',
        path: '/controller/Depots',
        exact: true,
        component: Components.VehicleDepots,
        authType: TypeOfAuth.Auth,
        icon: <LocalShippingIcon />
    },
    {
        label: 'Depots',
        path: '/controller/Depots/:id',
        exact: true,
        hidden: true,
        component: Components.EditDepot,
        authType: TypeOfAuth.Auth,
        icon: <LocalShippingIcon />
    },
    {
        label: 'Drivers',
        component: Components.DriverScreen,
        path: '/controller/driver',
        authType: TypeOfAuth.Auth,
        icon: <PersonIcon />
    },

    {
        label: 'Edit Customer',
        path: '/controller/editcustomer/:id/:customer',
        hidden: true,
        exact: true,
        component: Components.EditCustomer,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Driver',
        component: Components.EditDriver,
        path: '/controller/editdriver/:id/:name',
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'Service Agents',
        authType: TypeOfAuth.Auth,
        icon: <BuildIcon />,
        path: '/serviceagent',
        exact: true,
        component: Components.ServiceAgent,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Vehicles',
        authType: TypeOfAuth.Both,
        icon: <DriveEtaIcon />,
        path: '/controller/Vehicles/:all',
        exact: true,
        component: Components.VehicleScreen
    },
    {
        label: 'Tax',
        authType: TypeOfAuth.Both,
        // icon: <DriveEtaIcon />,
        path: '/controller/Vehicles/T',
        exact: true,
        component: Components.VehicleScreen
    },
    {
        label: 'Insurance',
        authType: TypeOfAuth.Both,
        // icon: <DriveEtaIcon />,
        path: '/controller/Vehicles/I',
        exact: true,
        component: Components.VehicleScreen
    },
    {
        label: 'Reports',
        authType: TypeOfAuth.Both,
        icon: <AssignmentIcon />,
        children: []
    },
    {
        label: 'Reports List',
        path: '/reports/reportlist/',
        exact: true,
        component: Components.ReportsListScreen,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Purchase - All',
        path: '/Purchase_All',
        exact: true,
        component: () => {
            return <Components.InvoiceScreen statusCode="" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Purchase - Unpaid',
        path: '/purchaseunpaid',
        exact: true,
        component: () => {
            return <Components.InvoiceScreen statusCode="U" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Purchase - Paid',
        path: '/purchasepaid',
        exact: true,
        component: () => {
            return <Components.InvoiceScreen statusCode="P" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Purchase - Hold',
        path: '/Purchase_Hold',
        exact: true,
        component: () => {
            return <Components.InvoiceScreen statusCode="H" />;
        },
        authType: TypeOfAuth.Auth
    },

    {
        label: 'Reports List',
        path: '/reports/reportlist/:name/:id',
        exact: true,
        hidden: true,
        component: Components.FilteredReportsListScreen,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Daily Checks',
        authType: TypeOfAuth.Both,
        icon: <AssignmentTurnedInIcon />,
        children: [],
        path: '/DailyChecksParentUrl'
    },
    {
        label: 'All Checks',
        path: '/internalDailyChecks/all',
        exact: true,
        component: () => {
            return <Components.DailyCheckInternalUser statusCode="" resultCode="" fromPage="All Checks" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Unreviewed',
        path: '/internalDailyChecks/unreviwed',
        exact: true,
        component: () => {
            return <Components.DailyCheckInternalUser statusCode="1" resultCode="" fromPage="Unreviewed" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Failed',
        path: '/internalDailyChecks/failed',
        exact: true,
        component: () => {
            return <Components.DailyCheckInternalUser statusCode="" resultCode="F" fromPage="Failed" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Passed',
        path: '/internalDailyChecks/passed',
        exact: true,
        component: () => {
            return <Components.DailyCheckInternalUser statusCode="" resultCode="P" fromPage="Passed" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Monitor',
        path: '/internalDailyChecks/monitor',
        exact: true,
        component: () => {
            return <Components.DailyCheckInternalUser statusCode="" resultCode="M" fromPage="Monitor" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Estimates',
        authType: TypeOfAuth.Both,
        icon: <ExploreRoundedIcon />,
        children: []
    },
    {
        label: 'Received',
        path: '/serviceagent/EP',
        exact: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="EP" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Estimate Revised',
        path: '/serviceagent/ER',
        exact: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="ER" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Jobs Unassigned',
        path: '/serviceagent/AU',
        exact: true,
        hidden: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="AU" />;
        },
        authType: TypeOfAuth.Auth
    },
    // {
    //     label: 'Jobs Open',
    //     path: '/serviceagent/JP',
    //     exact: true,
    //     component: () => {
    //         return <Components.InternalUserJobsScreen screenCode="JP" />;
    //     },
    //     authType: TypeOfAuth.Auth
    // },
    {
        label: 'Jobs In Query',
        path: '/serviceagent/QJ',
        exact: true,
        hidden: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="QJ" />;
        },
        authType: TypeOfAuth.Auth
    },

    {
        label: 'Jobs',
        authType: TypeOfAuth.Both,
        icon: <WorkIcon />,
        children: [],
        path: '/JobParentUrl'
    },
    {
        label: 'All Jobs',
        path: '/controller/Jobs',
        exact: true,
        component: Components.JobScreen,
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'Job Reminders',
        path: '/controller/jobreminder',
        exact: true,
        component: Components.JobReminder,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Open Jobs',
        path: '/serviceagent/OJ',
        exact: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="OJ" />;
        },
        authType: TypeOfAuth.Auth
    },
    // {
    //     label: 'All Jobs',
    //     path: '/serviceAgent/All',
    //     exact: true,
    //     component: Components.JobScreen,
    //     authType: TypeOfAuth.Auth,
    //     hidden: true
    // },
    {
        label: 'Awaiting Approvals',
        path: '/serviceagent/AR',
        exact: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="AR" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Estimates Awaiting Approvals',
        path: '/serviceagent/EC',
        exact: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="EC" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Estimate Queried',
        path: '/serviceagent/EQ',
        exact: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="EQ" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Estimate Accepted',
        path: '/serviceagent/EA',
        exact: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="EA" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Customer Query',
        path: '/serviceagent/CQ',
        exact: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="CQ" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Agent Query',
        path: '/serviceagent/AQ',
        exact: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="AQ" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Fleet247 Query || Awating Review',
        path: '/serviceagent/FR',
        exact: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="FR" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Approved',
        path: '/serviceagent/CA',
        exact: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="CA" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Factory Inspection',
        path: '/serviceagent/FI',
        exact: true,
        component: () => {
            return <Components.InternalUserJobsScreen screenCode="FI" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Maintenance',
        authType: TypeOfAuth.Both,
        icon: <GavelIcon />,
        children: []
    },
    {
        label: 'Invoices',
        authType: TypeOfAuth.Both,
        icon: <GavelIcon />,
        children: [],
        path: '/InvoicesParentUrl'
    },
    {
        label: 'Breakdowns',
        path: '/maintenance/breakdowns/:all',
        exact: true,
        component: Components.BreakDownDashboard,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'VOR',
        component: Components.VorScreen,
        path: '/controller/vor',
        authType: TypeOfAuth.Auth,
        exact: true
    },
    {
        label: 'Defects',
        path: '/maintenance/defects',
        exact: true,
        component: Components.DefectsScreen,
        authType: TypeOfAuth.Auth
    },
    // {
    //     label: 'Invoice',
    //     path: '/maintenance/invoice',
    //     exact: true,
    //     component: Components.InvoiceScreen,
    //     authType: TypeOfAuth.Auth
    // }
    // {
    //     label: 'Vehicle Checks',
    //     path: '/maintenance/vehiclecheck',
    //     exact: true,
    //     component: Components.VehicleCheckDaily,
    //     authType: TypeOfAuth.Auth
    // },
    {
        label: 'Schedules',
        icon: <ScheduleIcon />,
        authType: TypeOfAuth.Auth,
        children: [],
        path: '/SchedulesParentUrl'
    },
    {
        label: 'All Schedules',
        path: '/maintenance/schedules/:all',
        exact: true,
        component: Components.MaintenanceSchedules,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Schedules calendar',
        path: '/maintenance/schedulescalendar',
        exact: true,
        component: Components.ScheduleDashboard,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Service',
        path: '/maintenance/schedules/S',
        exact: true,
        component: Components.MaintenanceSchedules,
        authType: TypeOfAuth.Auth
    },

    {
        label: 'MOTs',
        path: '/maintenance/schedules/M',
        exact: true,
        component: Components.MaintenanceSchedules,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Inspection',
        path: '/maintenance/schedules/I',
        exact: true,
        component: Components.MaintenanceSchedules,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Other',
        path: '/maintenance/schedules/O',
        exact: true,
        component: Components.MaintenanceSchedules,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Bookings Requsted',
        path: '/schedule/bookingsrequsted',
        exact: true,
        authType: TypeOfAuth.Auth,
        component: () => {
            return <Components.InternalUserSchedule status="1" />;
        }
    },
    {
        label: 'Accepted by Agents',
        path: '/schedule/acceptedbyagents',
        exact: true,
        authType: TypeOfAuth.Auth,
        component: () => {
            return <Components.InternalUserSchedule status="2" />;
        }
    },
    {
        label: 'Bookings Rejected',
        path: '/schedule/bookingsrejected',
        exact: true,
        authType: TypeOfAuth.Auth,
        component: () => {
            return <Components.InternalUserSchedule status="3" />;
        }
    },
    {
        label: 'Confirmed Bookings',
        path: '/schedule/confirmedbookings',
        exact: true,
        authType: TypeOfAuth.Auth,
        component: () => {
            return <Components.InternalUserSchedule status="5" />;
        }
    },
    {
        label: 'Outstanding Documents',
        path: '/schedule/outstandingdocuments',
        exact: true,
        component: Components.OutStandingDocuments,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Schedule Planner',
        path: '/schedule/scheduleplanner',
        exact: true,
        component: Components.SchedulePlannerScreen,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'ServiceAgentDashBoard',
        path: '/controller/editServiceAgent/:id',
        exact: true,
        component: Components.EditServiceAgent,
        hidden: true,
        authType: TypeOfAuth.Auth
    },

    {
        label: 'VehicleDashBoard',
        path: '/controller/editVehicle/:id/:name?/:serviceAgentid?',
        exact: true,
        component: Components.EditVehicle,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Customers',
        path: '/controller/Addcustomer',
        exact: true,
        hidden: true,
        component: Components.AddEditCustomer,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Vehicle Job Book Detail',
        path: '/controller/jobbookeddetail/',
        component: Components.JobBookDetail,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Service Agent Map',
        path: '/controller/getagentLocation/:location?',
        hidden: true,
        authType: TypeOfAuth.Auth,
        component: Components.GetAgentLocation
    },
    {
        label: 'BookJob',
        path: '/controller/jobTabScreen/:id/:vehicleID/:tabIndex?',
        exact: true,
        component: Components.jobTabScreen,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Service Agent  DashBoard',
        path: '/controller/editServiceAgent/:id',
        exact: true,
        component: Components.EditServiceAgent,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Unassigned Jobs',
        path: '/controller/unassigned-jobs',
        exact: true,
        hidden: true,
        component: Components.UnassignedJob,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Message',
        path: '/message',
        exact: true,
        hidden: true,
        component: Components.InternalUserChatting,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'ContractField',
        path: '/contractField',
        exact: true,
        hidden: true,
        component: Components.ContractField,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Enquires',
        authType: TypeOfAuth.Both,
        icon: <InfoIcon />,
        children: []
    },
    // {
    //     label: 'New Enquiries',
    //     path: '/Enquiries',
    //     exact: true,
    //     component: Components.EnquiriesScreen,
    //     authType: TypeOfAuth.Auth
    // },

    {
        label: 'New Enquires',
        path: '/enquiries/new_enquiry',
        exact: true,
        component: () => {
            return <Components.EnquiriesScreen id={1} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Live Enquires',
        path: '/enquiries/live_enquiry',
        exact: true,
        component: () => {
            return <Components.EnquiriesScreen id={2} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Lost Enquires',
        path: '/enquiries/lost_enquiry',
        exact: true,
        component: () => {
            return <Components.EnquiriesScreen id={4} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Successful Enquires',
        path: '/enquiries/successful_enquiry',
        exact: true,
        component: () => {
            return <Components.EnquiriesScreen id={3} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Edit Enquiry',
        path: '/Enquiries/edit/:id?',
        exact: true,
        hidden: true,
        component: Components.EnquiryEditModal,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Add Enquiry',
        path: '/Enquiries/add/:id?',
        exact: true,
        hidden: true,
        component: Components.AddEnquiries,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'EditBreakDown',
        path: '/maintenance/breakdowns/Edit/:BreakDownID/:VehicleID',
        exact: true,
        hidden: true,
        component: Components.EditBreakdownLink,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'EditDefect',
        path: '/maintenance/defect/edit/:DefectID/:VehicleID',
        exact: true,
        hidden: true,
        component: Components.DefectEditDetails,
        authType: TypeOfAuth.Auth
    },
    { label: 'Job Tracking', path: '/jobtracking', exact: true, component: Components.JobTracking, authType: TypeOfAuth.Auth },
    {
        label: 'Breakdown Roadside',
        path: '/jobtracking/BR',
        hidden: true,
        exact: true,
        component: () => {
            return <Components.JobTracking id={'BR'} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Defect',
        path: '/jobtracking/DD',
        hidden: true,
        exact: true,
        component: () => {
            return <Components.JobTracking id={'DD'} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Breakdown Onsite',
        path: '/jobtracking/BO',
        hidden: true,
        exact: true,
        component: () => {
            return <Components.JobTracking id={'BO'} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Inspections',
        path: '/inspection',
        exact: true,
        component: () => {
            return <Components.VehicleInspection status={1} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Repair Required',
        path: '/inspection_repair_required',
        exact: true,
        component: () => {
            return <Components.VehicleInspection status={1} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Awating Sign Off',
        path: '/Inspection_Awaiting_Sign_Off',
        exact: true,
        component: () => {
            return <Components.VehicleInspection status={3} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Inspection Completed',
        path: '/inspection_completed',
        exact: true,
        component: () => {
            return <Components.VehicleInspection status={5} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Repair In Progress',
        path: '/in_progress',
        exact: true,
        component: () => {
            return <Components.VehicleInspection status={2} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Signed Off',
        path: '/signed_off',
        exact: true,
        component: () => {
            return <Components.VehicleInspection status={4} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'All Inspections',
        path: '/All_Inspections',
        exact: true,
        component: () => {
            return <Components.VehicleInspection />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Vor Reporting',
        path: '/controller/VorReporting/:id?',
        exact: true,
        hidden: true,
        component: Components.VorReportingModal,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Company Details',
        path: '/company_details',
        exact: true,
        hidden: true,
        component: Components.CompanyDetail,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Breakdown Reporting',
        path: '/maintenance/breakdown/reporting/:id?',
        exact: true,
        hidden: true,
        component: Components.BreakdownDefectReporting,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Defect Reporting',
        path: '/maintenance/defect/reporting/:id?',
        exact: true,
        hidden: true,
        component: Components.BreakdownDefectReporting,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Job Reporting',
        path: '/job/job_Reporting',
        exact: true,
        hidden: true,
        component: Components.JobReportScreen,
        authType: TypeOfAuth.Auth
    },

    {
        label: 'Deleted Record',
        path: '',
        exact: true,
        component: Components.DeletedJobs,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Deleted Jobs',
        path: '/Deleted_jobs',
        exact: true,
        component: Components.DeletedJobs,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Deleted Defects',
        path: '/Deleted_Defects',
        exact: true,
        component: Components.DeletedDefect,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Sales - All',
        path: '/Sales_All',
        exact: true,
        component: () => {
            return <Components.SalesInvoiceScreen statusCode="" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Sales - Unpaid',
        path: '/salesunpaid',
        exact: true,
        component: () => {
            return <Components.SalesInvoiceScreen statusCode="C" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Sales - Paid',
        path: '/salespaid',
        exact: true,
        component: () => {
            return <Components.SalesInvoiceScreen statusCode="P" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Sales - Hold',
        path: '/Sales_Hold',
        exact: true,
        component: () => {
            return <Components.SalesInvoiceScreen statusCode="H" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Sales - Paid',
        path: '/update_sales_invoice/:id?',
        exact: true,
        component: Components.UpdateSalesInvoice,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Contracts',
        icon: <ScheduleIcon />,
        authType: TypeOfAuth.Auth,
        children: [],
        path: '/ContractsParentUrl'
    },
    {
        label: 'Contracts',
        path: '/contractList',
        exact: true,
        component: Components.ContractList,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Address',
        path: '/address',
        exact: true,
        component: Components.AddressScreen,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Contracts Hire Fleet',
        path: '/Contracts_Hire_Fleet',
        exact: true,
        component: Components.HireFleetScreen,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Recalls',
        path: '/RecallsByStatus',
        exact: true,
        component: Components.DashboardRecallsList,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Contracts Maintenance',
        path: '/Contracts_Maintenance',
        exact: true,
        component: Components.ContractMaintenanceScreen,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Contracts Dashboard',
        path: '/contractList/editcontractList/:contractID?',
        exact: true,
        component: Components.ContractEditScreen,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Contract Status',
        path: '/ContractStatus/:id?',
        exact: true,
        component: Components.ContractStatusTabScreen,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Parts',
        path: '/parts',
        exact: true,
        component: Components.Parts,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Part Recived',
        path: '/PartReceived',
        exact: true,
        component: Components.PartsReceived,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Part Request History',
        path: '/PartRequestHistory',
        exact: true,
        component: Components.PartsOrderHistory,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Part Stock Adjustments History',
        path: '/PartStockAdjustmentsHistory',
        exact: true,
        component: Components.PartsAdjustmentHistory,
        authType: TypeOfAuth.Auth
    },
    // {
    //     label: 'Holiday',
    //     path: '/HolidayLeave',
    //     exact: true,
    //     component: () => {
    //         return <Components.HolidayShiftCalender />;
    //     },
    //     authType: TypeOfAuth.Both
    // },
    {
        lablel: 'EventSignedOff',
        path: '/EventsSignedOff',
        exact: true,
        component: Components.EventSignedOff,
        authType: TypeOfAuth.Auth
    },
    {
        path: '/HolidayLeave',
        exact: true,
        authType: TypeOfAuth.Both,
        component: Components.ServiceAreaHolidays,
        authType: TypeOfAuth.Auth
    },
    {
        lablel: 'Purchased Parts',
        path: '/purchasedparts',
        exact: true,
        component: Components.PurchasePartsGrid,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Part Usage',
        path: '/part/partUsage',
        exact: true,
        hidden: true,
        component: Components.PartUsageScreen,
        authType: TypeOfAuth.Auth
    }
];
