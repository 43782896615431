import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, Typography } from '@material-ui/core';
import { getCustomerById } from '../../../../Core/Services/InternalUser/ControllerService/customerService';
import { FromLoader } from '../../../../Core/Loader/formLoader';
import CircularProgress from '@material-ui/core/CircularProgress';
import { formatters } from '../../../../Core/Controls';
import { Alert } from '@material-ui/lab';

function CustomerDetails(props) {
    const [state, setState] = useState({
        customerShortName: null,
        customerAddress1: null,
        customerAddress2: null,
        customerCounty: null,
        customerCity: null,
        customerPostCode: null,
        customerTelephone: null,
        customerEmail: null,
        loader: false
    });

    useEffect(async () => {
        if (props.customerID) {
            let res = await getCustomerById(props.customerID);
            const data = res.data.list[0];
            setState((st) => ({
                ...st,
                customerShortName: data.customerShortName,
                customerCompanyName: data.customerCompanyName,
                customerAddress1: data.customerAddress1,
                customerAddress2: data.customerAddress2,
                customerCity: data.customerCity,
                customerCounty: data.customerCounty,
                customerPostCode: data.customerPostCode,
                customerTelephone: data.customerTelephone,
                customerEmail: data.customerEmail,
                loader: true,
                customerIsOnStop: data.customerIsOnStop,
                customerOperationsManagerName: data.customerOperationsManagerName,
                customerOperationsManagerPhone: data.customerOperationsManagerPhone,
                customerOperationsManagerEmail: data.customerOperationsManagerEmail,
                customerFinanceDirectorName: data.customerFinanceDirectorName,
                customerFinanceDirectorPhone: data.customerFinanceDirectorPhone,
                customerFinanceDirectorEmail: data.customerFinanceDirectorEmail
            }));
        }
    }, []);

    return state.loader ? (
        <Grid container direction="row" spacing={2} style={{ minWidth: '550px', minHeight: '300px' }}>
            {state.customerIsOnStop && (
                <Grid xs={12} item>
                    <Alert variant="filled" severity="error" style={{ padding: 0 }}>
                        This customer is on stop.
                    </Alert>
                </Grid>
            )}
            <Grid xs={12} item className="typo">
                <FormHelperText>Customer Name</FormHelperText>
                <Typography>{state.customerCompanyName}</Typography>
            </Grid>
            <Grid xs={12} item className="typo">
                <FormHelperText>Address</FormHelperText>
                <Typography>
                    {formatters.addressFormatter(
                        state.customerAddress1,
                        state.customerAddress2,
                        state.customerCity,
                        state.customerCounty,
                        state.customerPostCode
                    )}
                </Typography>
            </Grid>

            <Grid xs={12} item className="typo">
                <FormHelperText>Operations Manager</FormHelperText>
                <Typography>
                    {state.customerOperationsManagerName}
                    {state.customerOperationsManagerPhone || state.customerOperationsManagerEmail ? (
                        <>
                            {' (' /* Added a space before the opening bracket */}
                            {state.customerOperationsManagerPhone ? formatters.phoneNumberFormatter(state.customerOperationsManagerPhone) : ''}
                            {state.customerOperationsManagerPhone && state.customerOperationsManagerEmail ? ' / ' : ''}
                            {state.customerOperationsManagerEmail ? formatters.mailFormatter(state.customerOperationsManagerEmail.trim().toLowerCase()) : ''}
                            {')'}
                        </>
                    ) : (
                        ''
                    )}
                </Typography>
            </Grid>

            <Grid xs={12} item className="typo">
                <FormHelperText>Finance Director</FormHelperText>
                <Typography>
                    {state.customerFinanceDirectorName}
                    {state.customerFinanceDirectorPhone || state.customerFinanceDirectorEmail ? (
                        <>
                            {' (' /* Added a space before the opening bracket */}
                            {state.customerFinanceDirectorPhone ? formatters.phoneNumberFormatter(state.customerFinanceDirectorPhone) : ''}
                            {state.customerFinanceDirectorPhone && state.customerFinanceDirectorEmail ? ' / ' : ''}
                            {state.customerFinanceDirectorEmail ? formatters.mailFormatter(state.customerFinanceDirectorEmail.trim().toLowerCase()) : ''}
                            {')'}
                        </>
                    ) : (
                        ''
                    )}
                </Typography>
            </Grid>

            {/* <Grid item xs={12} className="typo">
                <FormHelperText>Operations Manager Phone</FormHelperText>
                <Typography variant="button">{formatters.phoneNumberFormatter(state.customerOperationsManagerPhone)}</Typography>
            </Grid>
            */}
        </Grid>
    ) : (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '500px', height: '300px' }}>
            <CircularProgress style={{ margin: 'auto' }} />
        </div>
    );
}

export default CustomerDetails;
