import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import DoneIcon from '@material-ui/icons/Done';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { useHistory } from 'react-router-dom';

import DialogComp from '../../../../Core/Dialog/DialogComp';
import ServiceAgentArrivedDate from './serviceAgentArrivedDate';
import Signoff from './signOff';
import AdministratorSignOff from './administratorSignOff';
import InfoIcon from '@material-ui/icons/Info';
import { CustomChip, formatters } from '../../../../Core/Controls';
import { ChipWithoutLable } from '../../../../Core/Controls/Inputs/DatePicker'; //../Inputs/DatePicker';;
import WorkIcon from '@material-ui/icons/Work';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteJobModal from '../../Controllers/JOBS/deleteJobModal';

import './defect.scss';
import { usePortalSettings } from '../../../../Core/Hooks/usePortalSettings';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import { ImportantDevices } from '@material-ui/icons';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { useWindowSize } from '../../../../Core/Hooks/useWindowSize';
import ServiceAgentDetails from '../../Controllers/DetailsModal/serviceAgent';
// import { useWindowSize } from '../../../../Core/Hooks/useWindowSize';
import { AppStorage } from '../../../../Core/Basic/storage-service';

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 15
    },
    active: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
        }
    },
    completed: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
        }
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1
    }
})(StepConnector);

// const useColorlibStepIconStyles = makeStyles({
//     root: {
//         backgroundColor: '#ccc',
//         zIndex: 1,
//         color: '#fff',
//         width: 25,
//         height: 25,
//         display: 'flex',
//         borderRadius: '50%',
//         justifyContent: 'center',
//         alignItems: 'center',
//         ['@media (max-width:780px)']: {
//             width: (active) => (active ? 35 : 25),
//             height: (active) => (active ? 35 : 25),
//             marginTop: (active) => (active ? '0px' : '4px')
//         }
//     },
//     active: {
//         backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
//         boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
//     },
//     completed: {
//         backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)'
//     },
//     icons: {
//         fontSize: '1.0rem',
//         ['@media (max-width:780px)']: {
//             fontSize: (active) => (active ? '1.0rem' : '0.5rem')
//         }
//     }
// });

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: 'black',
        width: 35,
        height: 35,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        '@media(max-width: 1024px)': {
            width: 22,
            height: 22 // Adjust height for devices between 821px and 1024px
        }
    },
    active: {
        backgroundImage: 'linear-gradient( 136deg, #0f9b0f  30%, #0b4d0b 80%, #031a03 100%)',
        // backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        color: '#fff'
    },
    completed: {
        // backgroundImage: 'linear-gradient( 136deg, #0f9b0f  30%, #0b4d0b 80%, #031a03 100%)',
        backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',

        color: '#fff'
    },
    icons: {
        fontSize: '1.0rem',
        ['@media (max-width:780px)']: {
            fontSize: (active) => (active ? '1.0rem' : '0.5rem')
        }
    }
});

const ColorlibStepIcon = (props) => {
    const { active, completed, stepCount } = props;
    const classes = useColorlibStepIconStyles(active);

    const icons = [
        <ReportProblemIcon className={classes.icons} />,
        <GroupAddIcon className={classes.icons} />,
        <AutorenewIcon className={classes.icons} />,
        //<VideoLabelIcon className={classes.icons} />,
        <DoneIcon className={classes.icons} />,
        <VerifiedUserIcon className={classes.icons} />
    ];

    if (stepCount === 6) {
        icons.splice(3, 0, <VideoLabelIcon className={classes.icons} />);
    }
    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed
            })}
        >
            {icons[props.icon - 1]}
        </div>
    );
};

const ColorlibStepIconWrapper = (stepCount) => {
    return (props) => <ColorlibStepIcon stepCount={stepCount} {...props} />;
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

// let codeCheck = {
//     JR: { 5: 1, 6: 1 },
//     AA: { 5: 1, 6: 1 },
//     AR: { 5: 2, 6: 2 },
//     WC: { 5: 3, 6: 4 }, //{ 5: 2, 6: 2 },Work in Progress to WC (Clicking on Problem Fixed Button Moved To (Technician Signed Off)wc)
//     WP: { 5: 2, 6: 3 },
//     VR: { 5: 3, 6: 4 },
//     AS: { 5: 3, 6: 4 },
//     TS: { 5: 4, 6: 5 },
//     JS: { 5: 5, 6: 6 }
// };

let codeCheck = {
    DR: { 4: 0, 5: 0 },
    AA: { 4: 1, 5: 1 },
    AR: { 4: 2, 5: 2 },
    DP: { 4: 2, 5: 3 },
    // WC: { 4: 2, 5: 3, },
    VR: { 4: 3, 5: 3, 6: 3 },
    DC: { 4: 3, 5: 4, 6: 4 },
    // DC: { 4: 2, 5: 4, 6: 4 },// it because of The focus should be set to sign off
    DS: { 4: 4, 5: 5, 6: 6 },
    AS: { 4: 4, 5: 4, 6: 4 }
    // DD: { 4: 2, 5: 1 }
};

export default function CustomizedSteppers(props) {
    const classes = useStyles();
    const portalSettings = usePortalSettings();
    const { isLoggedIn, displayName, lastLogin, userRoles } = useLoginInfo();
    const history = useHistory();
    let checkhaveAccess = AppStorage.getAllowAcces();

    // const isAdmin = userRoles.includes('admin');
    const isServiceagent = userRoles.includes('servicearea');
    const isInternalUser = userRoles.includes('internal');
    const isCustomer = userRoles.includes('customer');
    const isDriver = userRoles.includes('driver');
    const isVehiclRecovery = props.data.recoveryRequired;
    const [activeStep, setActiveStep] = React.useState(1);
    const [step, setStep] = React.useState({ administratorShow: false, show: false, serviceDate: false, steps: [], isY: true });
    const [windowWidth, windowHeight] = useWindowSize();

    const showAgentDetailHandler = () => {
        setStep((st) => ({
            ...st,
            showSaModal: true
        }));
    };

    useEffect(async () => {
        let TempStep = [
            {
                title: 'Reported',
                value: formatters.DateTimeFormatter(props.data.defectDateCreated),
                createdByUserName: props.data.userCreatedBy,
                // isBtn: isInternalUser || isServiceagent || false,
                isOnclick: false
            },
            {
                title: 'Assign Service Area',
                AgentName: props.data.agent,
                value: formatters.DateTimeFormatter(props.data.defectDateAgentArrived || props.data.defectDateAgentAssigned),
                createdByUserName: props.data.userArrivedBy || props.data.userAssignedBy,
                // isBtn: isInternalUser || isServiceagent || false,
                otherFunctions: props.data.agent && formatters.DateTimeFormatter(props.data.defectDateAgentArrived) ? () => showAgentDetailHandler() : '',
                isOnclick: props.data.defectJobStatusCode === 'AA' || props.data.defectJobStatusCode === 'DR'
            },
            {
                title: props.data.defectDateWorkCompleted ? 'Work Completed' : 'Work In Progress',
                value: formatters.DateTimeFormatter(props.data.defectDateWorkCompleted || props.data.defectDateWorkInProgress),
                createdByUserName: props.data.defectDateWorkCompleted ? props.data.userCompletedBy : props.data.userWorkInProgressBy,
                // isBtn: isInternalUser || isServiceagent || false,
                isOnclick: props.data.defectJobStatusCode === 'AR' || props.data.defectJobStatusCode === 'AS' || props.data.defectJobStatusCode === 'DP'
            },
            // {
            //     title: 'Engineer Sign Off',
            //     value: formatters.DateTimeFormatter(props.data.defectDateTechnicianSignOff),
            //     isBtn: isInternalUser || isServiceagent || false,
            //     alwaysShow: true
            // },
            {
                title: 'Job Sign-Off',
                createdByUserName: props.data.userSignedOffBy,
                value: formatters.DateTimeFormatter(props.data.defectDateSignOff),
                // isBtn: isInternalUser || false,
                // alwaysShow: true
                isOnclick: props.data.defectJobStatusCode === 'DC'
                // || props.data.defectJobStatusCode === 'DS'
            }
        ];

        // if (isServiceagent) {
        //     let temp = [...TempStep];
        //     let index = temp.findIndex((i) => i.title === 'Job Sign-Off');
        //     TempStep.splice(index, 1);
        // }
        if (isVehiclRecovery) {
            TempStep.splice(3, 0, {
                title: props.data.defectDateVehicleRecovered ? 'Vehicle Recovered' : 'Vehicle Recovery',
                createdByUserName: props.data.userRecoveredBy,
                value: formatters.DateTimeFormatter(props.data.defectDateVehicleRecovered),
                // isBtn: isInternalUser || isCustomer || isServiceagent || false,
                isOnclick: props.data.defectJobStatusCode === 'DP'
            });
        }
        if (
            isVehiclRecovery &&
            (props.data.defectJobStatusCode === 'VR' ||
                props.data.defectJobStatusCode === 'AS' ||
                props.data.defectJobStatusCode === 'DC' ||
                props.data.defectJobStatusCode === 'DS')
        ) {
            TempStep.splice(4, 0, {
                title: `At Service Agent ${props.data.defectDateRecoveryWorkCompleted ? '(Completed)' : '(WIP)'}`,
                createdByUserName: props.data.userRecoveryWIPBy,
                value: formatters.DateTimeFormatter(props.data.defectDateRecoveryWorkInProgress),
                // isBtn: isInternalUser || isCustomer || isServiceagent || false,
                isOnclick: props.data.defectJobStatusCode === 'VR' || props.data.defectJobStatusCode === 'AS'
            });
        }
        let objIndex = codeCheck[`${props.data.defectJobStatusCode}`][`${TempStep.length}`];
        let technicianSignstepIndex = TempStep.findIndex((q) => q.title == 'Engineer Sign Off');
        let showStepTech = objIndex == technicianSignstepIndex;
        let jobSignstepIndex = TempStep.findIndex((q) => q.title == 'Job Sign-Off');
        let showStepJob = props.data.defectJobStatusCode === 'AS' || props.data.defectJobStatusCode === 'DC';

        console.log(objIndex, 'objIndex');
        // if (isServiceagent) {
        //     showStepJob = false;
        // }
        setActiveStep(objIndex);
        setStep((st) => ({ ...st, steps: TempStep, totalSteps: TempStep.length, showStepTech: showStepTech, showStepJob: showStepJob }));
    }, [props.data, props.userData]);

    const closeServiceAgentModal = (res) => {
        setStep((st) => ({
            ...st,
            show: false,
            administratorShow: false,
            serviceDate: false,
            showSaModal: false,
            showJobDeleteModal: false
        }));
        res && props.onStepperSubmit();
    };

    const popUpHandler = (label) => {
        if (label === 'Assign Service Area' || label === 'Work In Progress' || label === 'Vehicle Recovery' || label === 'At Service Agent (WIP)') {
            setStep((st) => ({
                ...st,
                serviceDate: true,
                fromPage:
                    label === 'Assign Service Area'
                        ? 'serviceAgent'
                        : label === 'Work In Progress'
                        ? 'workInProgress'
                        : label === 'At Service Agent (WIP)'
                        ? 'workInProgress'
                        : 'vehicleArrived'
            }));
        }
        // if (label === 'Sign Off') {
        //     setStep((st) => ({
        //         ...st,
        //         show: true
        //     }));
        // }
        if (label === 'Job Sign-Off') {
            setStep((st) => ({
                ...st,
                administratorShow: true
            }));
        }
    };
    const sATitle = props.data.defectDateAgentAssigned ? 'Set Arrived Date' : 'Assign Service Area';
    const ServiceAgentArrivalTimetitle =
        step.fromPage === 'serviceAgent'
            ? sATitle
            : step.fromPage === 'vehicleArrived'
            ? 'Vehicle Recovered Time '
            : step.fromPage === 'At Service Agent'
            ? 'At Service Agent'
            : 'Work In Progress';
    const title = 'Sign Off';
    const titleAdministrator = 'Job Sign-Off';

    const goJobDetails = () => {
        history.push({
            pathname: `/controller/jobTabScreen/${props.data.defectJobID}/${props.userData.vehicleID}`,
            search: '',
            state: 'jobs'
        });
    };

    const showDeleteJobModal = () => {
        setStep((st) => ({
            ...st,
            showJobDeleteModal: true
        }));
    };

    return (
        <div className={classes.root} style={{ position: 'relative' }}>
            <div
                style={{ position: 'absolute', top: windowWidth < 1641 ? 0 : 5, left: windowWidth < 1641 ? -4 : -12, zIndex: 10 }}
                // onClick={goJobDetails}
            >
                {/* {!isDriver && checkhaveAccess?.CanViewJobs ? (
                    windowWidth < 1641 ? (
                        <>
                            <ChipWithoutLable
                                icon={WorkIcon}
                                iconFontSize={16}
                                onClick={goJobDetails}
                                className="btn-m"
                                style={{}}
                                toolTipTitle={'Job Details'}
                            />
                        </>
                    ) : (
                        <>
                            <CustomChip icon={WorkIcon} iconFontSize={16} label="Job" onClick={goJobDetails} className="btn-m" style={{ fontWeight: 500 }} />
                        </>
                    )
                ) : null} */}
                {/* {checkhaveAccess?.CanUpdateJobs && (
                    <ChipWithoutLable
                        icon={DeleteIcon}
                        iconFontSize={16}
                        onClick={showDeleteJobModal}
                        className="btn-m"
                        toolTipTitle={'Delete Job'}
                        style={{ zIndex: 99999 }}
                    />
                )} */}
                {/* <CustomIconButton icon={WorkIcon} color="secondary" toolTipTitle="Job Details" onClick={goJobDetails} style={{ marginTop: '0px' }} /> */}
            </div>
            <Stepper
                className="stepTest"
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector active={activeStep} />}
                style={{
                    padding: '5px 0px 5px 0px',
                    margin: '0px -5px 0px -5px'
                }}
            >
                {step.steps.map((label, i) => {
                    // let isOnClick = (label.isBtn && activeStep === i) || label.alwaysShow;
                    let isOnClick = label.isOnclick || label.alwaysShow || false;
                    return (
                        <Tooltip disableFocusListener title={`${label.title} ${label.value ? label.value : ''}`} key={i}>
                            <Step>
                                <StepLabel
                                    className="Stepper-label"
                                    StepIconComponent={ColorlibStepIconWrapper(step.steps.length)}
                                    onClick={isOnClick ? () => popUpHandler(label.title) : label.otherFunctions ? () => label.otherFunctions() : ''}
                                    style={{ cursor: isOnClick ? 'pointer' : 'context-menu' }}
                                >
                                    {/* <StepLabel className="Stepper-label" StepIconComponent={(props) => <ColorlibStepIcon stepCount={step.steps.length} {...props} />}> */}
                                    <div className={`${activeStep === i ? '' : 'stepper-label-to-hide'}`}>
                                        <div>{label.AgentName ? `${label.AgentName}` : label.title}</div>
                                        <Typography variant="caption" display="block">
                                            {label.createdByUserName}
                                        </Typography>
                                        <Typography variant="caption" display="block">
                                            {label.value}
                                        </Typography>
                                    </div>
                                </StepLabel>
                            </Step>
                        </Tooltip>
                    );
                })}
            </Stepper>
            {step.serviceDate ? (
                <DialogComp title={ServiceAgentArrivalTimetitle} onClose={() => closeServiceAgentModal(false)} maxWidth="sm">
                    <ServiceAgentArrivedDate
                        defectID={props.userData.defectID}
                        jobData={props.data}
                        fromPage={step.fromPage}
                        onClose={closeServiceAgentModal}
                        userData={props.userData}
                        isDateMandatoey={props.fromPage}
                    />
                </DialogComp>
            ) : null}
            {step.show ? (
                <DialogComp title={title} onClose={() => closeServiceAgentModal(false)} overflow="auto" maxWidth="sm">
                    <Signoff
                        displayName={displayName}
                        defectID={props.userData.defectID}
                        jobData={props.data}
                        declaration={portalSettings.portalSettingTechnicianDeclaration}
                        onClose={closeServiceAgentModal}
                        showStepTech={step.showStepTech}
                    />
                </DialogComp>
            ) : null}
            {step.administratorShow ? (
                <DialogComp title={titleAdministrator} onClose={() => closeServiceAgentModal(false)} overflow="auto" maxWidth="sm">
                    <AdministratorSignOff
                        displayName={displayName}
                        defectID={props.userData.defectID}
                        jobData={props.data}
                        declaration={portalSettings.portalSettingSignOffManagerDeclaration}
                        onClose={closeServiceAgentModal}
                        showStepJob={step.showStepJob}
                    />
                </DialogComp>
            ) : null}
            {step.showSaModal ? (
                <DialogComp open={true} onClose={() => closeServiceAgentModal(false)} title={'Service Area Details'}>
                    <ServiceAgentDetails serviceAgentID={props.data.jobServiceAgentID} onFormSubmit={() => closeServiceAgentModal(false)} />
                </DialogComp>
            ) : null}
            {step.showJobDeleteModal ? (
                <DialogComp open={true} onClose={() => closeServiceAgentModal(false)} title={'Delete Job'} maxWidth="sm" fullWidth>
                    <DeleteJobModal deleteID={props.data.defectJobID} fromPage="Job" onClose={closeServiceAgentModal} />
                </DialogComp>
            ) : null}
        </div>
    );
}
